import axios from "axios";
const API_URL = process.env.VUE_APP_API_BASE_URL;
export function useSearchApi() {
  async function getSearch(id,mac) {
    try {
      const response = await axios.get(API_URL + `/find-mac-address/?fendy_user_id=${id}&macaddr=${mac} `);
      return response.data;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }
  
  return {
    getSearch
  };
}