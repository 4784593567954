<template>
  <div class="py-4 container-fluid" v-if="dashoard_data">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="OLT"
          :value="dashoard_data?.olt ?? 0"
          :percentage="{
            value: '+505%',
            color: 'success',
          }"
          :icon="{
            component: 'ni ni-paper-diploma',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="OLT PORT"
          :value="dashoard_data?.olt_Port ?? 0"
          :percentage="{
            value: '+3%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-money-coins',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="OLT 24+ DB"
          :value="dashoard_data?.olt_28db ?? 0"
          :percentage="{
            value: '-2%',
            color: 'text-danger',
          }"
          :icon="{
            component: 'ni ni-money-coins',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0">
        <mini-statistics-card
          title="OLT OFFLINE ONU"
          :value="dashoard_data?.olt_offline_onu ?? 0"
          :percentage="{
            value: '+5%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-world',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
    </div>

    <div class="mt-4 row" v-if="dashoard_data?.db_list">
      <!-- Port Count -->
      <DBList :dashoard_data="dashoard_data" />

          <!-- GPON -->
          <Gpon :port_count_data="port_count_data" v-if="port_count_data?.GPON?.length"  />
          <!-- EPON -->
          <Epon :port_count_data="port_count_data" v-if="port_count_data?.EPON?.length" />
          <PortSkalaton v-if="!port_count_data?.GPON?.length && !port_count_data?.EPON?.length" />

      <div class="col-6 text-end" v-if="showProfile && profileFormData">
        <div class="popup_bg"></div>
        <div id="myModal" class="modal_class">
          <div class="modal-content invoice p-4 shadow-lg rounded">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h5 class="m-0">Please Update Your Profile</h5>
            </div>

            <div class="row mb-3">
              <div class="col-12 col-sm-6 mb-3">
                <input
                  id="ispName"
                  v-model="profileFormData.isp_name"
                  type="text"
                  placeholder="Enter ISP Name"
                  class="form-control"
                />
              </div>
              <div class="col-12 col-sm-6 mb-3">
                <input
                  id="number"
                  v-model="profileFormData.number"
                  type="text"
                  placeholder="Enter Number"
                  class="form-control"
                />
              </div>
              <div class="col-12 mb-3">
                <input
                  id="address"
                  v-model="profileFormData.address"
                  type="text"
                  placeholder="Enter Location"
                  class="form-control"
                />
              </div>
              <div class="col-12 col-sm-6 mb-3">
                <input
                  id="otp"
                  v-model="otp"
                  type="text"
                  placeholder="Enter OTP"
                  class="form-control"
                />
              </div>
              <div class="col-12 col-sm-6  d-flex align-items-end">
                <button class="btn btn-outline-success w-100" @click="sendOtp">
                  Send OTP
                </button>
              </div>
              
            <p class="text-sm" v-if="timeRemaining < 120 && timeRemaining!= null && timeRemaining != 0">OTP Expeire In : {{ formattedTime }}</p>
            </div>


            <div class="text-center">
              <button class="btn btn-success w-100" @click="updateProfileData">
                Submit <span
                          v-if="loading"
                          class="spinner-border text-black spinner-border-sm mx-3"
                        ></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-lg-3">
        <!-- line chart -->
        <div class="card invoice-card" v-if="chartData" style="height: 420px; overflow-y: auto; overflow-x: hidden;">
          <SwitchList />
        </div>
      </div>
      <!-- <div class="col-lg-6 mb-lg-3">
        <div class="card invoice-card" v-if="chartData" style="height: 400px">
          <gradient-line-chart
            id="chart-line"
            description="
      <p>Live Log</p>"
            :chart="chartData"
          />
        </div>
      </div> -->
    </div>
    <div class="row" data-bs-theme="dark">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4 mt-4">
        <projects-card :dashoard_olt_info_data="dashoard_olt_info_data" />
      </div>
      <div id="invoice" class="col-lg-4 col-md-6">
        <InvoiceCard v-if="profileFormData?.fendy_user_id"  :fendy_user_id="profileFormData?.fendy_user_id"/>
      </div>
    </div>

    <!-- hello -->
  </div>
</template>
<script>
import PortSkalaton from "@/components/PortSkalaton.vue";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import InvoiceCard from "./components/InvoiceCard.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import ProjectsCard from "./components/ProjectsCard.vue";
import DBList from "@/components/DBList.vue";
import SwitchList from "@/components/SwitchList.vue";
import Epon from "@/components/Epon.vue";
import Gpon from "@/components/Gpon.vue";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";
import getDashboardData from "../services/dashboard.service";
import homePageOltInfoList from "../services/homePageOltInfoList.service";
import getDashboardChartData from "../services/get-dashboard-chart.service";
import getProfileCheck from "../services/check-profile.service";
import updateProfile from "../services/update-profile.service";
import sendOtp from "../services/send-otp.service";
import confirmOtp from "../services/confirm-otp.service";
import oltPortCount from "../services/oltPortCount";
import showSwal from "../mixins/showSwal.js";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      savedDarkMode: null,
      description_data: [],
      count_data: [],

      timeRemaining: 120, 
      timerInterval: null,

      profileFormData: {
        id: "",
        isp_name: "",
        number: "",
        address: "",
        fendy_user_id: "",
        user_data_ok: false,
      },

      chartData: null,
      showProfile: false,
      loading: false,
      otp: "",

      previousIndex: -1,
      dashoard_data: [],
      port_count_data: [],
      dashoard_olt_info_data: [],
      dashoard_chart_data: [],
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  components: {
    MiniStatisticsCard,
    // ReportsBarChart,
    // GradientLineChart,
    ProjectsCard,
    /* TimelineList,
    TimelineItem, */
    InvoiceCard,
    PortSkalaton,
    Gpon,
    Epon,
    DBList,
    SwitchList
  },

  async mounted() {
    this.getDashboardAllData();
    this.getDashboardChartData();
    this.getPortCount();
    this.getCheckProfile();
    this.savedDarkMode = localStorage.getItem("darkMode");
  },

  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timeRemaining / 60);
      const seconds = this.timeRemaining % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
  },


  methods: {
    startTimer() {
      if (this.timerInterval) return; 

      this.timerInterval = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining -= 1;
        } else {
          clearInterval(this.timerInterval);
          this.timerInterval = null;
        }
      }, 1000);
    },
    async getDashboardAllData() {
      try {
        this.dashoard_data = await getDashboardData.getDashboardData();
        this.dashoard_olt_info_data =
          await homePageOltInfoList.getOltHomePageData();
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    },
    async getPortCount() {
      try {
        this.port_count_data = await oltPortCount.getOltPortCount();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async popup_profile_close() {
      this.showProfile = false;
      this.profileFormData.id = "";
      this.profileFormData.isp_name = "";
      this.profileFormData.number = "";
      this.profileFormData.address = "";
      this.profileFormData.user_data_ok = false;
    },
    async getCheckProfile() {
      try {
        let response = await getProfileCheck.getProfileCheck();
        this.profileFormData.fendy_user_id = response.fendy_user_id;
        if (response.user_data_ok == false) {
          this.showProfile = true;
          this.profileFormData.id = response.id;          
          this.profileFormData.isp_name = response.isp_name;
          this.profileFormData.number = response.number;
          this.profileFormData.address = response.address;
          this.profileFormData.user_data_ok = true;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async updateProfileData() {
      try {
        this.loading =true
        await confirmOtp.confirmOtp(this.profileFormData.number, this.otp);

        await updateProfile.updateProfile(this.profileFormData);
        
        showSwal.methods.showSwal({
          type: "success",
          message: "Updated Successfully!",
        });        
        this.popup_profile_close();
        this.loading =false
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: error.response.data.message,
        });
        console.error("Error fetching data:", error);
        this.loading =false
      }
    },
    async sendOtp() {
      try {
        
        this.startTimer()
        await sendOtp.sendOtp(this.profileFormData.number);
        showSwal.methods.showSwal({
          type: "success",
          message: "Send Successfully!",
        });
      } catch (error) {
        console.error("Error sending data:", error);
      }
    },
    async getDashboardChartData() {
      try {
        this.dashoard_chart_data =
          await getDashboardChartData.getDashboardChartData();

        // Process the data
        this.count_data = this.dashoard_chart_data?.map((item) =>
          Number(item.count)
        );
        this.description_data = this.dashoard_chart_data?.map((item) =>
          Number(item.description)
        );

        // Prepare chart data
        this.chartData = {
          labels: this.dashoard_chart_data?.map((item, index) =>
            index.toString()
          ),
          datasets: [
            {
              label: "dBm",
              data: this.description_data,
            },
            {
              label: "Count",
              data: this.count_data,
            },
          ],
        };
      } catch (error) {
        console.error("Error fetching dashboard chart data:", error);
      }
    },
  },

   beforeUnmount() {
    clearInterval(this.timerInterval); 
  }
};

// dark mode
</script>

<style scoped>
.col-lg-4.col-md-6 {
  overflow-y: auto;
}

.col-lg-4.col-md-6::-webkit-scrollbar {
  display: none;
}

.modal_class {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #aaaaaa;
  opacity: 0.93;
}

.popup_bg {
  opacity: 0.5;
}

.modal-content {
  background-color: #fff;
  max-width: 500px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.invoice-card {
  height: 500px; 
  overflow-y: auto; 
  overflow-x: hidden; 
}
</style>



