<template>
      <div class="d-flex justify-content-center align-items-center fixed-container flex-column">
    <div class="loader-container">
      <div class="large-ripple-loader">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.large-ripple-loader {
  position: relative;
  width: 100px;
  height: 100px;
}

.large-ripple-loader div {
  position: absolute;
  border: 4px solid #3498db;
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.large-ripple-loader div:nth-child(2) {
  animation-delay: -0.75s;
}

@keyframes ripple {
  0% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    opacity: 0;
  }
}

.fixed-container {
  height: 50vh;
  flex-direction: column; /* Arrange elements in a column */
}

p {
  margin-top: 10px;
  text-align: center;
}

</style>