import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async getLogsDateList(ppoe) {
      try {
        const response = await axios.get(API_URL+`/api/olt-data-logs/?PPPOE_ID=${ppoe}`);
        return response.data;
      } catch (error) {
        console.error("Error retrieving user:", error);
        throw error; 
      }
    },
  };
