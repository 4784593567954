import axios from "axios";
const API_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  async getRefreshServer(id,port) {

    try {
      const response = await axios.get(API_URL+`/api-olt-update-data/${id}/?port_get=${port}`);
      return response.data;
    } catch (error) {
      console.error("Error retrieving token:", error);
      throw error; 
    }
  },
};
