<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card invoice">
          <!-- Card header -->
          <div class="pb-0 card-header invoice">
    <div class="d-lg-flex justify-content-between mx-3">
        <div>
            <h5> Log List</h5>

        </div>
        <div class="d-flex gap-3">
        <div class="flex-grow-1">
          <router-link to="/live-log-list">
          <button class="btn btn-sm btn-success">Live log</button>
          </router-link>
        </div>
        <div class="flex mx-1 justify-content-around">
                <div class="d-flex grid grid-cols-2 gap-3">
                    <select @change="handleSlugData()" v-model="selected_slug" class="form-control invoice dark-filter" name="filter" id="filter">
                        <option   value="" selected>All</option>
                        <option v-for="item in slug_data"  :key="item.device_slug" :value="item.device_slug">{{item.device_slug}}</option>
                    </select>
                </div>
        </div>
        <div class="flex-grow-1">
            <input type="text" v-model="searchQuery" class="form-control invoice" placeholder="Search...">
        </div>
    </div>
  </div>
</div>

            
          <div class="px-0 pb-0 card-body invoice rounded-0">
            <div class="table-responsive" v-if="!loader">
              <table id="users-list" class="table align-items-center mb-0">
                <thead >
                  <tr>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" @click="sort('date')" :class="{ 'asc': sortKey === 'date' && sortOrder === 'asc', 'desc': sortKey === 'date' && sortOrder === 'desc' }">Date<span class="sort-icon"> </span></th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" @click="sort('device_name')" :class="{ 'asc': sortKey === 'device_name' && sortOrder === 'asc', 'desc': sortKey === 'device_name' && sortOrder === 'desc' }">Device Name <span class="sort-icon"> </span></th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" @click="sort('log')" :class="{ 'asc': sortKey === 'log' && sortOrder === 'asc', 'desc': sortKey === 'log' && sortOrder === 'desc' }">Log <span class="sort-icon"> </span></th>   
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" @click="sort('time')" :class="{ 'asc': sortKey === 'time' && sortOrder === 'asc', 'desc': sortKey === 'time' && sortOrder === 'desc' }">Time <span class="sort-icon"> </span></th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <tr v-for="(item,index) in filteredData" :key="index">                    
                    <td class="align-middle text-center">{{ item.date }}</td>
                    <td class="align-middle text-center">{{ item.device_name }}</td>
                    <td class="align-middle text-center">{{ item.log }}</td>                         
                    <td class="align-middle text-center">{{ item.time }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div  v-else>
                <Skalaton />     
            </div>
            <div class="pagination-container">
                  <button class="btn btn-sm btn-success mx-3 mt-3" @click="previousPage" :disabled="currentPage === 1">Previous</button>
                  <span class="mx-2">Current Page {{ currentPage }}</span>
                  <button class="btn btn-sm btn-success mx-3 mt-3" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'sweetalert2/dist/sweetalert2.min.css';
import logList from "../services/log-list.service";
import logSearchList from "../services/log-search.service";
import slugList from "../services/slug-list.service";
import Skalaton from "@/components/Skalaton.vue";
export default {
  name: "Log",
  components: {
          Skalaton
        },
  data() {
    return {
      sortKey: '',
      sortOrder: 'asc', 

      slug_data: [],
      slug_string: '',
      slug: '',
      selected_slug:'',

      data: [],
      filteredData: [],
      searchQuery: "",

      currentPage: 1,
      totalPages:0,
      loader:false,

    };
  },
  async mounted() {
    await this.getSlug();
    await this.getData();
  },
  methods: {
    
    async handleSlugData() {
      if(this.selected_slug == ''){
        this.slug = this.slug_string;
      }else{
        this.slug = this.selected_slug;
      }
      
        this.currentPage = 1;
        await this.getData();
    },
    
    async getSlug() {
      const response = await slugList.getSlugListData();
      this.slug_data = response;
      this.slug_string = this.slug_data.map(slug => slug.device_slug).join(', ');
      this.slug = this.slug_data.map(slug => slug.device_slug).join(', ');
    },
    async getData() {
        this.loader=true;
      try {
        const response = await logList.getLogListData(this.currentPage, this.slug);
        this.data = response?.data;
        this.filteredData = response?.data;
        this.totalPages = response?.total_pages;
        this.loader=false;
      } catch (error) {
        console.error("Error fetching log list data:", error);
        this.data = [];
        this.filteredData = [];
        this.totalPages = 0;
        this.loader=false;
      }
      
    },
    sort(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }

      this.filteredData.sort((a, b) => {
        const valueA = a[key] ? a[key].toString().toLowerCase() : '';
        const valueB = b[key] ? b[key].toString().toLowerCase() : '';
        const comparison = valueA.localeCompare(valueB);
        return this.sortOrder === 'asc' ? comparison : -comparison;
      });
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getData()
      }
    },
    nextPage() {
      if (!this.totalPages == 0) {
        this.currentPage++;
        this.getData()
      }
    }
  },
  watch: {
      async searchQuery(newValue) {
        if(newValue==''){
          return this.filteredData = this.data
        }
        const response = await logSearchList.getLogSearchListData(this.currentPage, newValue);;

        this.filteredData = response?.data;
        this.currentPage = 1;
      }
  },
  };
</script>

<style>
td {
  padding: 12px 24px !important;
}
.sort-icon {
  margin-left: 5px;
}

.asc::after {
  content: "▲";
}

.desc::after {
  content: "▼";
}


.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50%;
    background-color: #40C133; /* or any other color you prefer */
    color: #fff; /* text color */
}


</style>
