<template>
  <!-- chart js  -->
  <div class="py-4 container-fluid">
    <!-- chart js -->
    <div class="row">
      <div
        v-for="(item, index) in data.filter(
          (item) => item.show == true && item.output_cpu
        )"
        :key="index"
        class="col-lg-2 text-center"
      >
        <Chart :chartId="index" :item="item" />
      </div>
    </div>
    <!-- chart js -->
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <!-- Payment Method -->
      <div class="col-6 text-end">
        <div v-if="showPaymentMethod" id="myModal" class="modal_class">
          <div class="modal-content invoice">
            <span @click="popup_close" class="close">&times;</span>

            <h5 class="text-center">Please Select Payment Method</h5>

            <div class="my-3 mx-12 text-center">
              <p v-if="pay_loading">Loading ..</p>
              <span
                v-if="pay_loading"
                class="spinner-border spinner-border-sm mx-3"
              ></span>
              <span v-else @click="getPay()" class="cursor-pointer"
                ><img src="../assets/img/logos/bkash.svg" style="width: 200px"
              /></span>
            </div>
            <div class="flex text-center"></div>
          </div>
        </div>
      </div>
      <!--Month List Popup -->
      <div v-if="showPopup" id="myModal" class="modal_class">
        <div class="modal-content dark-modeon">
          <span @click="popup_close" class="close text-end">&times;</span>

          <div class="my-3 mx-12 mx-auto">
            <default-info-card
              class="cursor-pointer"
              icon="text-white fas fa-shopping-cart"
              :title="pact_data.olt_packge_list[0].name"
              :port_count="pact_data.olt_packge_list[0].port_count"
              :olt_modes="pact_data.olt_packge_list[0].olt_modes_id"
              :value="
                'Tk ' +
                (firstIntegerValue
                  ? firstIntegerValue * pact_data.olt_packge_list[0].price
                  : pact_data.olt_packge_list[0].price)
              "
              :expeire_at="
                firstIntegerValue
                  ? formatDate(
                      new Date(
                        new Date().setMonth(
                          new Date().getMonth() + firstIntegerValue
                        )
                      )
                    )
                  : null
              "
            />
            <select
              v-model="selected_month"
              name=""
              id=""
              class="form-control mt-3 text-black font-bold text-center bg-white"
            >
              <option value="" disabled selected hidden>Select Month</option>
              <option
                v-for="(item, index) in month_data"
                :key="index"
                :value="index"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="flex text-center">
            <div class="grid grid-cols-2 gap-2">
              <button class="btn btn-secondary mx-3" @click="popup_close">
                Cancel
              </button>
              <button class="btn btn-success" @click="Pay(selected_month)">
                Pay Now
                <span
                  v-if="loading_pay_now"
                  class="spinner-border spinner-border-sm mx-3"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- ############### -->
      <!-- #### card #### -->
      <!-- ############# -->
      <div class="roles_ mb-4">
        <!-- #1 -->

        <div class="_card" v-for="(data,index) in device_data" :key="index">
          <p class="m-0 p-0 font-weight-bold" style="font-size: 12px">Device: {{data?.device_name}}</p>
          <p class="m-0 p-0 font-weight-bold" style="font-size: 12px">IP: {{data?.ip_address}}</p>
          <p v-if="data.error" class="m-0 p-0 font-weight-bold text-danger" style="font-size: 12px">Error: {{data?.error}}</p>
          <p v-else class="m-0 p-0 font-weight-bold" style="font-size: 12px">Count: {{data?.user_count}}</p>
          <button class="btn btn-success btn-xs w-30 text-xs p-0 py-1" @click="ShowMore(data)">Show More</button>
        </div>

        <!-- #3 -->

        <!-- <div class="_card" :key="item">
          <div class="header">
            <p
              class="m-0 p-0"
              style="font-weight: 400; font-size: 14px !important"
            >
              Active OLT
            </p>
          </div>
          <p class="m-0 p-0 font-weight-bold" style="font-size: 24px"> {{ filteredData.filter(item => item.status_sr_file === 'active').length }}</p>
        </div> -->

        <!-- #4 -->

        <!-- <div class="_card" :key="item">
          <div class="header">
            <p
              class="m-0 p-0"
              style="font-weight: 400; font-size: 14px !important"
            >
              Offline OLT
            </p>
          </div>
          <p class="m-0 p-0 font-weight-bold" style="font-size: 24px">{{filteredData.filter(item => item.status_sr_file == 'inactive').length}}</p>
        </div> -->
      </div>

      <div class="col-12 mt-4">
        <div class="card invoice">
          <!-- Card header -->
          <div class="pb-0 card-header invoice">
            <div
              class="d-lg-flex justify-content-between align-items-center mx-3"
            >
              <div class="">
                <h5>
                  Device List <span class="badge">{{ filteredData.length }}</span>
                </h5>
              </div>
              <div class="d-flex gap-2">
                <div class="flex-grow-1">
                  <input
                    type="text"
                    v-model="searchQuery"
                    class="form-control invoice"
                    placeholder="Search..."
                  />
                </div>
                <div class="">
                  <router-link :to="{ name: 'AddOlt' }">
                    <button
                      class="bg-success btn text-white rounded py-0"
                      :style="{
                        height: '35px',
                      }"
                    >
                      Add New
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div
            class="table-responsive"
            v-if="filteredData && filteredData.length > 0"
          >
            <div class="card mb-4">
              <div class="card-body px-0 pt-0 pb-2 invoice">
                <div class="table-responsive p-0">
                  <table class="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <!-- ### olt ip #### -->
                        <th
                          @click="sort('name')"
                          :class="{
                            asc: sortKey === 'name' && sortOrder === 'asc',
                            desc: sortKey === 'name' && sortOrder === 'desc',
                          }"
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          name
                          <span class="sort-icon"> </span>
                        </th>

                        <!-- ### olt name #### -->

                        <th
                          @click="sort('ip_address')"
                          :class="{
                            asc: sortKey === 'ip_address' && sortOrder === 'asc',
                            desc:
                              sortKey === 'ip_address' && sortOrder === 'desc',
                          }"
                          class="text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          IP
                          <span class="sort-icon"> </span>
                        </th>
                        <th
                          @click="sort('port')"
                          :class="{
                            asc: sortKey === 'port' && sortOrder === 'asc',
                            desc:
                              sortKey === 'port' && sortOrder === 'desc',
                          }"
                          class="text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Port
                          <span class="sort-icon"> </span>
                        </th>                       

                        <!-- ###status#### -->

                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Status
                          <span class="sort-icon"> </span>
                        </th>

                        <!-- ### actions #### -->
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Action
                          <span class="sort-icon"> </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in filteredData" :key="index">
                        <!-- ### olt ip #### -->

                        <td
                          :class="{ 'text-danger': !data.show }"
                          class="align-middle"
                        >
                          <p class="text-xs font-weight-bold mb-0">
                            {{ data.name }}
                          </p>
                        </td>

                        <!-- ### olt name #### -->
                        <td
                          :class="{ 'text-danger': !data.show }"
                          class="align-middle"
                        >
                          <p class="text-xs font-weight-bold mb-0">
                            {{ data.ip_address }}
                          </p>
                        </td>
                        <td
                          :class="{ 'text-danger': !data.show }"
                          class="align-middle"
                        >
                          <p class="text-xs font-weight-bold mb-0">
                            {{ data.port }}
                          </p>
                        </td>

                        <!-- ###status#### -->
                        <td class="align-middle">
                          <soft-badge
                            v-if="data.status_sr_file == 'inactive'"
                            class="bg-danger px-2 rounded-1 text-white"
                            variant="gradient"
                            size="sm"
                            style="font-size: 12px !important"
                            >Offline
                          </soft-badge>

                          <soft-badge
                            v-if="data.status_sr_file == 'active'"
                            class="btn-success px-2 rounded-1 text-white"
                            variant="gradient"
                            size="sm"
                            style="font-size: 12px !important"
                            >Online
                          </soft-badge>
                        </td>

                        <!-- #### actions###### -->
                        <td class="align-middle">
                          <div
                            class="d-flex align-items-center"
                            style="gap: 20px"
                          >
                            <!-- <router-link
                              class="dark-filter"
                              :to="{
                                name: 'OltDetails',
                                params: { id: data.id },
                              }"
                            >
                              <span class="text-sm me-1 _view">View </span>
                            </router-link> -->

                            <router-link
                              class="dark-filter"
                              :to="{
                                name: 'EditOlt',
                                params: { edit_id: data.id },
                              }"
                            >
                              <i class="fas fa-edit cursor-pointer"></i>
                            </router-link>
                            <i @click="handleDelete(data.id)" class="fas fa-trash text-danger cursor-pointer"></i>
                            <i v-if="index === index_resinc" @click="resencData(index)" class="fas fa-pause cursor-pointer"></i>
                            
                            <i v-else @click="resencData(index)" class="fas fa-play cursor-pointer"></i>

                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- #####paginations####### -->
                <div class="pagination-container">
                  <button
                    class="btn btn-sm btn-success mx-3 mt-3"
                    @click="previousPage"
                    :disabled="currentPage === 1"
                  >
                    Previous
                  </button>
                  <span class="mx-2"
                    >Page {{ currentPage }} of {{ totalPages }}</span
                  >
                  <button
                    class="btn btn-sm btn-success mx-3 mt-3"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <Skalaton />
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 text-end" v-if="delete_olt_popup">
      <div class="popup_bg"></div>
      <div id="myModal" class="modal_class">
        <div class="modal-content invoice p-4 shadow-lg rounded">
          <div class="d-flex justify-content-center align-items-center mb-3">
            <h5 class="text-center m-0">Are you sure?</h5>
          </div>
          <div class="text-center">
            <button @click="delete_data()" class="btn btn-danger w-40 mx-1">
              Yes, delete it!
            </button>
            <button @click="popup_close" class="btn btn-success w-40 mx-1">
              No, keep it
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 text-end" v-if="profile_popup" >
      <div class="popup_bg"></div>
      <div id="myModal" class="modal_class">
        <div class="modal-content invoice p-4 shadow-lg rounded">
          <div class="d-flex justify-content-center align-items-center mb-3">
            <h5 class="text-center m-0">Please Update This Information</h5>
          </div>

          <div class="row mb-3 p-3">
            <div class="col-12 col-sm-12 mb-3">
              <!-- <label class="form-label text-start">Company Name</label> -->
              <input
                v-model="formData.company_name"
                type="text"
                placeholder="Company Name"
                class="form-control invoice-card"
              />
            </div>
            <div class="col-12 col-sm-12 mb-3">
              <!-- <label class="form-label">Address</label> -->
              <input
                v-model="formData.location"
                type="text"
                placeholder="Address"
                class="form-control invoice-card"
              />
            </div>
          </div>


          <div class="text-center">
            <button @click="update_profile_data()" class="btn btn-success w-40 mx-1">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 text-end" v-if="device_popup">
      <div class="popup_bg"></div>
      <div id="myModal" class="modal_class">
        <div class="modal-content invoice p-4  rounded">
          <div class="d-flex justify-content-center align-items-center mb-3">
            <h5 class="text-center m-0">Device Information</h5>
          </div>

            <div class="d-flex" v-if="!single_device?.error">
              <div class="col-6">
                <p class="mb-1 text-left"><strong>Device:</strong> {{ single_device?.device_name }}</p>
                <p class="mb-1 text-left"><strong>IP:</strong> {{ single_device?.ip_address }}</p>
                <p class="mb-1 text-left"><strong>Firmware Version:</strong> {{ single_device?.firmware_version || 'N/A' }}</p>
                <p class="mb-1 text-left"><strong>Serial Number:</strong> {{ single_device?.serial_number || 'N/A' }}</p>
                <p class="mb-1 text-left"><strong>Platform:</strong> {{ single_device?.platform || 'N/A' }}</p>
                <p class="mb-1 text-left"><strong>User Extend Format:</strong> {{ single_device?.user_extend_fmt || 'N/A' }}</p>
              </div>
              <div class="col-6">              
                <p class="mb-1 text-left"><strong>Compat Old Firmware:</strong> {{ single_device?.compat_old_firmware || 'N/A' }}</p>
                <p class="mb-1 text-left"><strong>MAC Address:</strong> {{ single_device?.mac_address || 'N/A' }}</p>
                <p class="mb-1 text-left"><strong>User Count:</strong> {{ single_device?.user_count || 'N/A' }}</p>
                <p class="mb-1 text-left"><strong>Ip:</strong> {{ single_device?.network_params?.ip  || 'N/A' }}</p>
                <p class="mb-1 text-left"><strong>Mask:</strong> {{ single_device?.network_params?.mask || 'N/A' }}</p>
                <p class="mb-1 text-left"><strong>Gateway:</strong> {{ single_device?.network_params?.gateway || 'N/A' }}</p>
                <p v-if="single_device?.error" class="mb-1 text-left"><strong>Error:</strong> {{ single_device?.error || 'N/A' }}</p>
              </div>
            </div>
            <div class="" v-if="single_device?.error">              
                <p v-if="single_device?.error" class="mb-1 text-center"><strong>Error:</strong> {{ single_device?.error || 'N/A' }}</p>
            </div>

          <div class="text-center">
            <button @click="popup_close" class="btn btn-success w-40 mx-1">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
// import dataList from "../services/data_list.service";
// import deleteOlt from "../services/delete_olt.service";
// import getPortStatusCheck from "../services/check-ports-status.service";
import Chart from "@/components/Chart.vue";
// import MonthList from "../services/get-month-list.service";
// import FasterPayBill from "../services/faster-pay-bill.service";
// import PayBillByPacID from "../services/get-pack-name-with-olt-id.service";
import {deviceApi} from "../services/device.service";
import showSwal from "../mixins/showSwal.js";
import Skalaton from "@/components/Skalaton.vue";

export default {
  name: "Olt List",
  components: {
    Skalaton,
    DefaultInfoCard,
    Chart,
  },
  data() {
    return {
      sortKey: "",
      sortOrder: "asc",
      data: [],
      single_device: {},
      device_data: [],
      filteredData: [],
      searchQuery: "",
      currentPage: 1,
      profileId: "",
      selectedId: "",
      itemsPerPage: 20,
      device_popup: false,
      delete_olt_popup: false,
      profile_popup: false,
      resenc: false,
      index_resinc: '',
      formData: {
        company_name: "",
        location: "",
      }
    };
  },

  async mounted() {
    await this.getData();
    await this.getDeviceData();
  },
  methods: {
    async handleDelete(id) {
        this.selectedId = id;
        this.delete_olt_popup = true;
      },
    async ShowMore(data) {
        this.single_device = data;
        this.device_popup = true;
      },
    async delete_data () {
        await deviceApi().deleteDevice(this.selectedId);
        showSwal.methods.showSwal({ type: "success", message: "Data deleted successfully!"});
        this.getData();
        this.popup_close()
      },
    async resencData(i) {
  this.resenc = true;
  this.index_resinc = i;
  
  try {
    await deviceApi().resencData(this.selectedId);
    showSwal.methods.showSwal({ type: "success", message: "Resync successfully!" });
  } catch (error) {
    console.error("Resync failed:", error);
    showSwal.methods.showSwal({ type: "error", message: "Resync failed! Please try again." });
  } finally {
    this.resenc = false;
    this.index_resinc = "";
    this.getData();
  }
},

    async update_profile_data () {
        await deviceApi().putProfile(this.formData,this.profileId);
        showSwal.methods.showSwal({ type: "success", message: "Data updated successfully!"});
        this.getData();
        this.popup_close()
      },
    popup_close() {
      this.delete_olt_popup = false;
      this.selectedId = '';
      this.single_device = {};
        this.device_popup = false;
    },
    async getDeviceData() {
      const response = await deviceApi().getDeviceInfo();
      this.device_data = response;
    },
    async getData() {
      const response = await deviceApi().getDevice();
      this.data = response;
      this.filteredData = this.data;

      
      const response2 = await deviceApi().getProfile();
      if(response2.company_name && response2.location ){
        this.profile_popup = false
      }else{
        this.profileId = response2.user.id
        this.profile_popup = true
      }
      console.log(this.filteredData)
    },
    sort(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortKey = key;
        this.sortOrder = "asc";
      }

      this.filteredData.sort((a, b) => {
        const valueA = a[key] ? a[key].toString().toLowerCase() : "";
        const valueB = b[key] ? b[key].toString().toLowerCase() : "";
        const comparison = valueA.localeCompare(valueB);
        return this.sortOrder === "asc" ? comparison : -comparison;
      });
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      return this.filteredData.slice(
        startIndex,
        startIndex + this.itemsPerPage
      );
    },
    /* firstIntegerValue() {
      const match = this.selected_month.match(/\d+/);
      return match ? parseInt(match[0]) : null;
    }, */
  },
  watch: {
    searchQuery(newValue) {
      this.filteredData = this.data.filter((item) => {
        for (let key in item) {
          if (
            String(item[key]).toLowerCase().includes(newValue.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
      // Reset currentPage when searchQuery changes
      this.currentPage = 1;
    },
  },
};
</script>

<style>
/* The Modal (background) */
.modal_class {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #aaaaaa;
}

/* Modal Content/Box */
.modal-content {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 50%;
  padding: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

td {
  padding: 12px 24px !important;
}

.sort-icon {
  margin-left: 5px;
}

.asc::after {
  content: "▲";
}

.desc::after {
  content: "▼";
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  background-color: #40c133;
  /* or any other color you prefer */
  color: #fff;
  /* text color */
}

/* ###################
#####################
 */
.roles_ {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 30px;
}

._card {
  border-radius: 10px;
  padding: 20px;
  color: rgba(0, 0, 0, 0.589);
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.dark-mode ._card {
  background-color: #061f3e;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._view {
  font-weight: 600 !important;
}

._view:hover {
  color: #40c133 !important;
  transition-duration: 300ms;
  text-decoration: underline;
}
</style>
