<template>
  <div class="container-fluid mt-4">
    <div id="profile" class="card card-body mt-4 invoice">
      <div v-if="profile" class="row justify-content-left align-items-center">
        <div class="col-sm-auto col-4">
          <soft-avatar
            :img="pic"
            alt="profile"
            size="xl"
            shadow="sm"
            border-radius="lg"
          />
        </div>
        <div class="col-sm-auto col-8 my-auto">
          <div class="h-100">
            <h5 class="mb-1 font-weight-bolder">{{ profile.user_name }}  </h5>
             <button 
              class="btn btn-info btn-sm rounded " 
              @click="getVpn"
            >
              Add VPN
            </button>
            
          </div>
        </div>
      </div>

      <div class="d-flex flex-column">
  <p v-if="vpn_data.error" style="color: red !important; margin-bottom: 5px;">* {{ vpn_data.error }}</p>
  <p v-if="vpn_data.message" style="color: red !important;">* {{ vpn_data.message }}</p>
</div>


    </div>

    <div class="row">
      <div class="col-6 ">
        <div id="basic-info p-6" class="card mt-4 invoice">
          <div class="card-header invoice">
            <h5>Basic Info</h5>
          </div>

          <div class="d-flex p-3">
            <div class="card-body pt-0 ">
            <div class="row mb-3 p-3">
              <label class="form-label">User Name</label>
              <p>
                <i
                  class="fa fa-user"
                  :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-1'"
                ></i>
                {{ profileChange.user_name }}
              </p>
              <validation-error :errors="apiValidationErrors.user_name" />
            </div>
          </div>
          <div v-if="vpn_data">
          <!-- Display Fields -->
            <div >
              <label>Connection Name:</label><br>
              <input v-model="vpn_data.connection_name" type="text" class="rounded" readonly />
              <button @click="copyToClipboard('connection_name')">Copy</button>
            </div>
            
            <div>
              <label>PPPoE Username:</label><br>
              <input v-model="vpn_data.pppoe_username" type="text" class="rounded" readonly />
              <button @click="copyToClipboard('pppoe_username')">Copy</button>
            </div>
            
            <div>
              <label>PPPoE Password:</label><br>
              <input v-model="vpn_data.pppoe_password" type="text" class="rounded" readonly />
              <button @click="copyToClipboard('pppoe_password')">Copy</button>
            </div>

            <div>
              <label>Command:</label><br>
              <input v-model="vpn_data.command" type="text" class="rounded" readonly />
              <button @click="copyToClipboard('command')">Copy</button>
            </div>
          </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "/src/mixins/form-mixin.js";
import img from "/src/assets/img/bruce-mars.jpg";
import showSwal from "/src/mixins/showSwal.js";
import { useVpnApi } from "../services/vpn.service";

export default {
  name: "Profile",
  components: {
    SoftAvatar,
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    const passChange = {
      password: "",
      password_confirmation: "",
    };
    const profileChange = {
      user_name: "",
      id: "",
    };
    const vpn_data = [];
    return {
      passChange,
      profileChange,
      loading: false,
      loading2: false,
      img,
      vpn_data,
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    pic() {
      if (this.profile.profile_image) return this.profile.profile_image;
      else return require("/src/assets/img/placeholder.jpg");
    },
  },
  async created() {
    try {
      await this.$store.dispatch("profile/getProfile");
      this.profileChange = { ...this.profile };
    } catch (error) {
      console.error("Error loading profile:", error.message || error);
    }
  },
  methods: {
    copyToClipboard(field) {
      const textToCopy = this.vpn_data[field];
      const tempInput = document.createElement('input');
      tempInput.value = textToCopy;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      showSwal.methods.showSwal({
          type: "success",
          message: `${field} copied!`,
          width: 450,
        });
    },
    async getVpn() {
      try {
        const { checkVpn } = useVpnApi(); 
        const userId = this.profile.fendy_user_id;
        this.vpn_data = await checkVpn(userId);
        showSwal.methods.showSwal({
          type: "success",
          message: "VPN Added Successfully!",
          width: 450,
        });
        console.log("VPN Data:", this.vpn_data);
      } catch (error) {
        this.vpn_data = error.response.data
        console.error("Error retrieving VPN data:",  error);
      }
    },

    async handlePassChange() {
      const isDemoMode = (process.env.VUE_APP_IS_DEMO ?? 1) == 1;
      if (isDemoMode && this.profile.id <= 3) {
        showSwal.methods.showSwal({
          type: "error",
          message: "You cannot change data of default users!",
          width: 450,
        });
        return;
      }

      this.resetApiValidation();
      this.loading = true;

      try {
        await this.$store.dispatch("profile/updateProfile", this.passChange);
        showSwal.methods.showSwal({
          type: "success",
          message: "Password changed successfully!",
          width: 500,
        });
        this.passChange.password = "";
        this.passChange.password_confirmation = "";
        this.loading = false;
      } catch (error) {
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async handleProfileChange() {
      const isDemoMode = (process.env.VUE_APP_IS_DEMO ?? 1) == 1;
      if (isDemoMode && this.profile.id <= 3) {
        showSwal.methods.showSwal({
          type: "error",
          message: "You cannot change data of default users!",
          width: 450,
        });
        return;
      }

      this.resetApiValidation();
      this.loading2 = true;

      try {
        await this.$store.dispatch("profile/updateProfile", this.profileChange);
        showSwal.methods.showSwal({
          type: "success",
          message: "Profile updated successfully!",
          width: 600,
        });
        this.loading2 = false;
      } catch (error) {
        console.error("Error updating profile:", error.message || error);
        this.setApiValidation(error.response.data.errors);
        this.loading2 = false;
      }
    },
  },
};
</script>

<style>
img {
  object-fit: cover;
}
</style>
