import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async getStatusData(status) {
      try {
        const response = await axios.get(API_URL+`/api/olt_data_port/status/${status}`, {
          headers: {
            'X-Custom-Header': 'Bearer '+"sdgbadghsdgdhsd47874dsfdfd"
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error retrieving status:", error);
        throw error; 
      }
    },

    
  };
