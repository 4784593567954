import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async getPortCheck(ip,port) {
  
      try {
        const response = await axios.get(API_URL+`/api/check-ports/?ip=${ip}&port=${port}`);
        return response.data;
      } catch (error) {
        console.error("Error retrieving user:", error);
        throw error; 
      }
    },

    
  };
