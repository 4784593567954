<template>
  <div class="py-4 container-fluid">
    <div class="oltlist-btns px-5">
      <button
        class="btn btn-sm"
        @click="(dashboard = false), (Port = true)"
        :class="Port ? 'btn-success' : 'btn-secondary'"
        style="min-width: 170px"
      >
        Dashboard List
      </button>

      <button
        class="btn btn-sm"
        @click="(dashboard = true), (Port = false)"
        :class="dashboard ? 'btn-success' : 'btn-secondary'"
        style="min-width: 170px"
      >
        OLT Port List
      </button>

      <button
        class="btn btn-sm btn-success"
        v-if="share_token"
        @click="copyToken"
      >
        Copy Token
      </button>

      <button class="btn btn-sm btn-secondary" @click="createToken">
        Create New Token
      </button>
    </div>

    <div class="row" v-if="dashboard">
      <div class="col-12">
        <div class="card invoice">
          <!-- Card header -->
          <div class="pb-0 card-header invoice">
            <div class="d-lg-flex justify-content-between mx-3">
              <div class="flex">
                <span class="badge mx-1 bg-dark"
                  >Total {{ filteredData.length }}</span
                >
                <span class="badge mx-1 bg-dark text-white">
                  <span
                    class="inline-block bg-success rounded-circle"
                    style="
                      height: 0.5rem;
                      width: 0.5rem;
                      display: inline-block;
                      margin-right: 0.25rem;
                    "
                  ></span>
                  Online
                  {{
                    filteredData.filter((item) => item.status_is == "online")
                      .length
                  }}
                </span>
                <span class="badge mx-1 bg-dark text-white">
                  <span
                    class="inline-block bg-danger rounded-circle"
                    style="
                      height: 0.5rem;
                      width: 0.5rem;
                      display: inline-block;
                      margin-right: 0.25rem;
                    "
                  ></span>
                  Offline
                  {{
                    filteredData.filter((item) => item.status_is == "offline")
                      .length
                  }}
                </span>
                <span class="badge mx-1 bg-dark"
                  >OLT no response
                  {{
                    filteredData.filter(
                      (item) => item.last_dereg_reason == "OLT no response"
                    ).length
                  }}</span
                >
                <span class="badge mx-1 bg-dark"
                  >Fiber-Down
                  {{
                    filteredData.filter(
                      (item) => item.last_dereg_reason == "Fiber-Down"
                    ).length
                  }}</span
                >
                <span class="badge mx-1 bg-dark"
                  >Power-OFF
                  {{
                    filteredData.filter(
                      (item) => item.last_dereg_reason == "Power-OFF"
                    ).length
                  }}</span
                >
              </div>
              <div class="d-flex gap-3">
                <div class="flex mx-1 justify-content-around">
                  <div class="d-flex grid grid-cols-2 gap-3">
                    <select
                      @change="handleOltData()"
                      v-model="selected_olt"
                      class="form-control invoice dark-filter"
                      name="filter"
                      id="filter"
                    >
                      <option value="" selected disabled hidden>OLT</option>
                      <option value="">All</option>
                      <option
                        v-for="item in filter_port_olt"
                        :key="item.OLT_NAME"
                        :value="item.OLT_NAME"
                      >
                        {{ item.OLT_NAME }}
                      </option>
                    </select>
                    <select
                      @change="handlePortData(selected_port)"
                      v-model="selected_port"
                      v-if="port_olt[0]"
                      class="form-control invoice dark-filter"
                      name="filter"
                      id="filter"
                    >
                      <option value="" selected disabled hidden>PORT</option>
                      <option value="">All</option>
                      <option
                        v-for="item in port_olt[0]?.PORT"
                        :key="item"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <input
                    type="text"
                    v-model="searchQuery"
                    class="form-control invoice"
                    placeholder="Search..."
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="px-0 pb-0 card-body invoice rounded-0">
            <div class="table-responsive" v-if="data && data.length > 0">
              <table id="users-list" class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('olt_connect')"
                      :class="{
                        asc: sortKey === 'olt_connect' && sortOrder === 'asc',
                        desc: sortKey === 'olt_connect' && sortOrder === 'desc',
                      }"
                    >
                      OLT<span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('port_number')"
                      :class="{
                        asc: sortKey === 'port_number' && sortOrder === 'asc',
                        desc: sortKey === 'port_number' && sortOrder === 'desc',
                      }"
                    >
                      PON NO. <span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('vendor')"
                      :class="{
                        asc: sortKey === 'vendor' && sortOrder === 'asc',
                        desc: sortKey === 'vendor' && sortOrder === 'desc',
                      }"
                    >
                      Vendor <span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('distance')"
                      :class="{
                        asc: sortKey === 'distance' && sortOrder === 'asc',
                        desc: sortKey === 'distance' && sortOrder === 'desc',
                      }"
                    >
                      Distance <span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('PPPOE_ID')"
                      :class="{
                        asc: sortKey === 'PPPOE_ID' && sortOrder === 'asc',
                        desc: sortKey === 'PPPOE_ID' && sortOrder === 'desc',
                      }"
                    >
                      DESCRIPTION <span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('status')"
                      :class="{
                        asc: sortKey === 'status' && sortOrder === 'asc',
                        desc: sortKey === 'status' && sortOrder === 'desc',
                      }"
                    >
                      STATUS <span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('description')"
                      :class="{
                        asc: sortKey === 'description' && sortOrder === 'asc',
                        desc: sortKey === 'description' && sortOrder === 'desc',
                      }"
                    >
                      RX POWER <span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('TXPower')"
                      :class="{
                        asc: sortKey === 'TXPower' && sortOrder === 'asc',
                        desc: sortKey === 'TXPower' && sortOrder === 'desc',
                      }"
                    >
                      Tx POWER <span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      ONU INFO <span class="sort-icon"> </span>
                    </th>

                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <!-- Use paginatedData instead of filteredData -->
                  <tr v-for="(item, index) in paginatedData" :key="index">
                    <td>{{ item.olt_connect }}</td>
                    <td>
                      {{ item.port_number }}
                      <span
                        class="badge rounded-pill"
                        style="background-color: #28c76f !important"
                      >
                        {{ item.Temperature_onu }} °C
                      </span>
                    </td>
                    <td>{{ item.vendor }}</td>
                    <td>{{ item.distance }}</td>
                    <td>
                      Pppoe Users: <br />
                      <span
                        v-for="(item_str, index) in formatdata(item.PPPOE_ID) ??
                        []"
                        :key="index"
                        class="mx-3s"
                        >&nbsp;&nbsp;&nbsp;&nbsp;
                        <router-link
                          v-if="item_str"
                          class="dark-filter"
                          :to="{ name: 'Graph', params: { pppoe: item_str } }"
                          >*{{ item_str }}</router-link
                        >
                        <br
                      /></span>
                    </td>
                    <td>
                      <p
                        class="badge"
                        :style="{
                          backgroundColor:
                            item.status_is == 'online'
                              ? item.bgcolor
                              : item.bgcolor,
                        }"
                      >
                        {{ item.status_is }}
                      </p>
                    </td>
                    <!-- <td class="align-items-center">
                  <div class="badge" :style="`background-color: ${item.bgcolor};`">
                      <p>
                           <sub class="badge" style="background-color:  green; margin-right: 5px; font-size:8px">{{ item.description_message?.value }}</sub>
                          <span style="color: white!important;">{{ item.description }}</span>
                          <sup class="badge" style="background-color:  green; margin-left: 5px;"><sup>{{ item.Temperature_onu }} °C</sup></sup>
                      </p>
                  </div>
              </td> -->
                    <td>
                      <span
                        class="position-relative badge"
                        :style="`background-color: #DFF7E9 !important;height: 30px;`"
                      >
                        <p style="color: #28c76f !important">
                          {{ item.description }}
                        </p>
                        <span
                          class="position-absolute top-0 start-100 translate-middle badge rounded-pill"
                          :style="`background-color: ${item.description_message?.color} !important;`"
                        >
                          {{ item.description_message?.value }}
                        </span>
                      </span>
                    </td>

                    <td>{{ item.TXPower }}</td>
                    <td>
                      <ul>
                        <li>Onu Id: {{ item.port_number }}</li>
                        <li>Mac: {{ item.mac_address }}</li>
                        <li>Last Dereg Time:</li>
                        <li>Last Dereg Reason: {{ item.last_dereg_reason }}</li>
                      </ul>
                    </td>

                    <td>
                      <a
                        @click="
                          Reset(item.port_number, item.olt_connect__id, index)
                        "
                        class="actionButton cursor-pointer pt-6 me-3"
                        data-bs-toggle="tooltip"
                        title="Reboot"
                      >
                        <span
                          v-if="reset_load && load_index == index"
                          class="spinner-border spinner-border-sm mx-3"
                        ></span>
                        <svg
                          v-else
                          style="width: 20px; color: gray"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1"
                            fill="#808080"
                          />
                        </svg>
                      </a>
                      <a
                        @click="
                          getResetData(
                            item.port_number,
                            item.olt_connect__id,
                            index
                          )
                        "
                        class="actionButton deleteButton cursor-pointer pt-6"
                        data-bs-toggle="tooltip"
                        title="Get"
                      >
                        <span
                          v-if="data_load && load_index == index"
                          class="spinner-border spinner-border-sm mx-3"
                        ></span>
                        <svg
                          v-else
                          style="width: 20px; color: gray"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                          <path
                            d="M64 32C28.7 32 0 60.7 0 96v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm48 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM64 288c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V352c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm56 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"
                            fill="#808080"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <Skalaton />
            </div>
            <div class="pagination-container">
              <button
                class="btn btn-sm btn-success mx-3 mt-3"
                @click="previousPage"
                :disabled="currentPage === 1"
              >
                Previous
              </button>
              <span class="mx-2"
                >Page {{ currentPage }} of {{ totalPages }}</span
              >
              <button
                class="btn btn-sm btn-success mx-3 mt-3"
                @click="nextPage"
                :disabled="currentPage === totalPages"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="dashoard_data?.db_list" class="mt-3">
        <DBList :dashoard_data="dashoard_data" />
      </div>

      <div v-if="port_count_data">
        <!-- GPON -->
        <div v-if="port_count_data.GPON">
          <Gpon :port_count_data="port_count_data" />
        </div>

        <!-- EPON -->
        <div v-if="port_count_data.EPON">
          <Epon :port_count_data="port_count_data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import getShareOltPorts from "../services/share-olt-port-list.service";
import getData from "../services/get-data.service";
import rebotData from "../services/rebotData.service";
import getShareToken from "../services/get-share-token.service";
import postShareToken from "../services/post-share-token.service";
import Skalaton from "@/components/Skalaton.vue";
import showSwal from "/src/mixins/showSwal.js";

import DBList from "@/components/DBList.vue";
import Epon from "@/components/Epon.vue";
import Gpon from "@/components/Gpon.vue";

import getDBList from "../services/get-share-db-list";
import getPortList from "../services/get-share-port-list";

export default {
  name: "Users",
  components: {
    Skalaton,
    Gpon,
    Epon,
    DBList,
  },
  data() {
    return {
      splitData: [],
      sortKey: "",
      sortOrder: "asc",
      data: [],
      filteredData: [],
      filter_port_olt: [],
      port_olt: [],
      selected_olt: "",
      selected_port: "",
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 20,
      filter: [],
      reset_load: false,
      data_load: false,
      dashboard: false,
      Port: true,
      load_index: "",
      share_token: "",
      dashoard_data: [],
      port_count_data: [],
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
    };
  },
  async mounted() {
    await this.getToken();
    await this.getData();
  },
  methods: {
    async getToken() {
      const response = await getShareToken.getShareToken();
      this.share_token = response.token;

      this.dashoard_data = await getDBList.getDBList(this.share_token);
      this.port_count_data = await getPortList.getPortList(this.share_token);
    },

    formatdata(data) {
      return (this.splitData = data.split(","));
    },
    copyToken() {
      const token = this.share_token;

      try {
        // Fallback: Create a temporary textarea for copying
        const textArea = document.createElement("textarea");
        textArea.value = token;
        document.body.appendChild(textArea);

        // Select the token text
        textArea.select();
        document.execCommand("copy"); // This still works in non-HTTPS environments

        // Remove the textarea from the DOM
        document.body.removeChild(textArea);

        // Show success message
        showSwal.methods.showSwal({
          type: "success",
          message: "Copy Successfully",
        });
      } catch (err) {
        // Handle copy failure
        console.error("Failed to copy token: ", err);
        showSwal.methods.showSwal({
          type: "error",
          message: "Failed to copy. Please try manually.",
        });
      }
    },

    async createToken() {
      await postShareToken
        .postShareToken()
        .then(() => {
          showSwal.methods.showSwal({
            type: "success",
            message: "Created Successfully",
          });
        })
        .catch(() => {
          showSwal.methods.showSwal({
            type: "error",
            message: "Already Created",
          });
        });
    },

    async Reset(port, id, index) {
      this.reset_load = true;
      this.load_index = index;
      const response = await rebotData.rebotData(port, id);
      if (response.status == "200") {
        showSwal.methods.showSwal({
          type: "success",
          message: "Reboot Successfully",
        });
      }
      this.reset_load = false;
    },

    async getResetData(port, id, index) {
      this.data_load = true;
      this.load_index = index;

      try {
        const response = await getData.getData(port, id);

        Swal.fire({
          icon: "success",
          title: "dBm " + response.dBm,
          timer: 9000,
          timerProgressBar: true,
          showConfirmButton: false,
          background: "#17ad37",
          color: "#fff",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error fetching data",
          timer: 5000,
          timerProgressBar: true,
          showConfirmButton: false,
          confirmButtonText: "Close",
          background: "#17ad37",
          color: "#fff",
        });
      } finally {
        this.data_load = false;
      }
    },

    handleOltData() {
      if (this.selected_olt == "") {
        this.filteredData = this.data;
        this.port_olt = [];
      } else {
        this.filteredData = this.data.filter(
          (item) => item.olt_connect === this.selected_olt
        );
        this.filter = this.filteredData;
        this.currentPage = 1;

        this.port_olt = this.filter_port_olt.filter(
          (item) => item.OLT_NAME === this.selected_olt
        );
      }
    },
    handlePortData(port) {
      if (port === "") {
        this.handleOltData();
      } else {
        this.filteredData = this.data.filter((item) => {
          const [itemPort] = item.port_number.split(":");
          return itemPort === port && item.olt_connect === this.selected_olt;
        });
        this.filter = this.filteredData;
        //this.filteredData = this.data.filter(item => item.port_number.split(":")[0] === port && item.olt_connect === this.selected_olt);
      }
      this.currentPage = 1;
    },

    async getData() {
      const response = await getShareOltPorts.getShareOltPorts(
        this.share_token
      );
      this.filter = this.data;
      this.data = response.olt_all_data;
      this.filter_port_olt = response.filter_port_olt;
      this.filteredData = response.olt_all_data;
    },
    sort(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortKey = key;
        this.sortOrder = "asc";
      }

      this.filteredData.sort((a, b) => {
        const valueA = a[key] ? a[key].toString().toLowerCase() : "";
        const valueB = b[key] ? b[key].toString().toLowerCase() : "";
        const comparison = valueA.localeCompare(valueB);

        // Apply sort order based on the current state
        return this.sortOrder === "asc" ? comparison : -comparison;
      });
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      return this.filteredData.slice(
        startIndex,
        startIndex + this.itemsPerPage
      );
    },
  },
  watch: {
    searchQuery(newValue) {
      if (newValue == "") {
        return (this.filteredData = this.filter);
      }

      let old_data = [];
      if (this.selected_port || this.selected_olt) {
        old_data = this.filteredData;
      } else {
        old_data = this.data;
      }

      this.filteredData = old_data.filter((item) => {
        for (let key in item) {
          if (
            String(item[key]).toLowerCase().includes(newValue.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
      // Reset currentPage when searchQuery changes
      this.currentPage = 1;
    },
  },
};
</script>

<style>
td {
  padding: 12px 24px !important;
}
.sort-icon {
  margin-left: 5px;
}

.asc::after {
  content: "▲";
}

.desc::after {
  content: "▼";
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  background-color: #40c133; /* or any other color you prefer */
  color: #fff; /* text color */
}
</style>
