import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  async login(user) {
    const response = await fetch(API_URL+"/api-login/token/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': this.getCookie('csrftoken')
      },
      body: JSON.stringify(user)
    });
    const data = await response.json(); 
    console.log(data.access)

    if (data.access) {
      try {
        localStorage.setItem("userF", JSON.stringify(data.access));
        const response2 = await axios.get(API_URL+`/api/user/profile/`, {
            headers: {
              'Authorization': 'Bearer '+data.access
            }
          });
          localStorage.setItem("user_data", JSON.stringify(response2.data));
        } catch (error) {
          console.error("Error retrieving:", error);
          throw error; 
        }
    }
  },

  async logout() {
    try {
      await axios.post("logout", {}, { headers: authHeader() });
    } finally {
      localStorage.removeItem("userF");
      localStorage.removeItem("user_data");
    }
  },

  async register(user) {
    const response = await axios.post(API_URL + "/api/user/register/", user);
    /* if (response.data.token) {
      localStorage.setItem("userF", JSON.stringify(response.data.token));
      localStorage.setItem("user_data", JSON.stringify(response.data));
    } */
  },

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
};
