import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async getLogListData(page,slug) {
      try {
        const response = await axios.get(API_URL+`/get-log-data/?page=${page}`, {
          headers: {
            'X-Custom-Header': slug
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error retrieving log:", error);
        throw error; 
      }
    },

    
  };
