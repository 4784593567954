<template>
  <div class="row justify-content-center">
    <div class="col-12 col-sm-10 px-4">
      <div id="basic-info" class="card mt-4">
        <div class="card-body pt-0 invoice-card">
          <div class="row mb-3 p-3">
            <h5>Share Token</h5>
            <div class="col-12 col-sm-3 mb-3">
              <label class="form-label">Token</label>
              <input
                  v-model="formData.shared_with_token"
                  type="text"
                  placeholder="Token"
                  class="form-control invoice-card"
                />
            </div>
            
          </div>
          
          <button
            class="float-end mb-0 btn btn-success"
            color="dark"
            variant="gradient"
            size="sm"
            @click="submitForm"
          >
            Update Token
          </button>
            <router-link :to="{name:'OltList'}">
          <button
            class="float-end mx-3 mb-0 btn btn-secondary"
            color="dark"
            variant="gradient"
            size="sm"
          >
            Cancel
          </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataList from "../services/data_list.service";
import updateOlt from "../services/update-olt.service";
import oltCatListData from "../services/olt-cat-list.service";
import showSwal from "../mixins/showSwal.js";
export default {
  data() {
    return {
      cat_data : [],
      data : [],
      edit_id : this.$route.params.edit_id,
      formData: {
        Mikrotik_ip: "",
        m_username: "",
        m_password: "",
        m_port: "",
        olt_ip: "",
        olt_username: "",
        olt_password: "",
        olt_port: "",
        description: "",
        show: true,
        olt_name: "",
        olt_modes: "",
        olt_brands: "",
        wav_id: "",
        teleg_id: "",
        data_collcetratio: "",
        snmp_port: "",
        db_allert_range: "",
        shared_with_token:""
      },
      isValidMPort: null,
       isValidOltPort: null
    };
  },
  mounted(){
    this.getData();
    this.getCatData();
    let user= JSON.parse(localStorage.getItem("user_data"));
    console.log(user.id)
    this.formData.login_user=user.id
  },
  methods: {
            async getData() {
                const response = await dataList.getDataList();
                this.data = response.filter(item => item.id == this.$route.params.edit_id);
                if(this.data){
                    this.formData.Mikrotik_ip =this.data[0].Mikrotik_ip;
                    this.formData.m_username =this.data[0].m_username;
                    this.formData.m_password =this.data[0].m_password;
                    this.formData.m_port =this.data[0].m_port;
                    this.formData.olt_ip =this.data[0].olt_ip;
                    this.formData.olt_username =this.data[0].olt_username;
                    this.formData.olt_password =this.data[0].olt_password;
                    this.formData.olt_port =this.data[0].olt_port;
                    this.formData.description =this.data[0].description;
                    this.formData.olt_name =this.data[0].olt_name;
                    this.formData.olt_modes =this.data[0].olt_modes;
                    this.formData.olt_brands =this.data[0].olt_brands;

                    this.formData.wav_id =this.data[0].wav_id;
                    this.formData.teleg_id =this.data[0].teleg_id;
                    this.formData.data_collcetratio =this.data[0].data_collcetratio;
                    this.formData.snmp_port =this.data[0].snmp_port;
                    this.formData.db_allert_range =this.data[0].db_allert_range;
                    this.formData.shared_with_token =this.data[0].shared_with_token;
                }
            },
            async getCatData() {
                const response = await oltCatListData.getCatListData();
                this.cat_data = response;
            },
    async submitForm() {
      console.log("Form submitted with data:", this.formData);
      try {
        await updateOlt.updateData(this.formData,this.edit_id);
        showSwal.methods.showSwal({
          type: "success",
            message: "Token updated Successfully!",
        });
        this.$router.push("/olt-list");
        } catch (error) {
        console.error("Error adding Olt:", error);
        showSwal.methods.showSwal({
          type: "error",
            message: "Failed to Create Olt!",
        });
        }
    },

    
  }
};
</script>
<style scoped>
.has-error .form-control {
  border: 1px solid red;
}
.has-success .form-control {
  border: 1px solid green;
}
.check-icon {
  color: green!important;
  position: absolute;
  right: 20px;
  top: 37px;
}
.cross-icon {
  color: red!important;
  position: absolute;
  right: 20px;
  top: 37px;
}
</style>

