<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <!-- <li class="nav-item">
        <sidenav-collapse navText="Dashboard" :to="{ name: 'Dashboard' }">
          <template #icon>
            <shop />
          </template>
        </sidenav-collapse>
      </li> -->

      <!-- <li class="nav-item">
        <sidenav-collapse
          navText="Olt Data Port List"
          :to="{ name: 'OltDataPort' }"
        >
          <template #icon>
            <switches />
          </template>
        </sidenav-collapse>
      </li> -->
      <li class="nav-item">
        <sidenav-collapse navText="Dashboard" :to="{ name: 'OltList' }">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>

      <!-- <li class="nav-item">
        <sidenav-collapse navText="Share OLT" :to="{ name: 'Share' }">
          <template #icon>
            <switches />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Sys Log" :to="{ name: 'Log' }">
          <template #icon>
            <switches />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse navText="Add Switch" :to="{ name: 'SwitchList' }">
          <template #icon>
            <switches />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Switch List" :to="{ name: 'OltPort' }">
          <template #icon>
            <switches />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Package List" :to="{ name: 'Package List' }">
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
      </li> -->
    </ul>
  </div>

  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <button
      @click="logoutUser"
      title="Logout"
      class="btn bg-gradient-success mt-4 w-100"
      type="button"
    >
      <span class=""
        ><i class="fas fa-sign-out-alt text-lg" aria-hidden="true"></i
      ></span>
      <!-- <span> Sign Out</span> -->
      <span v-if="loading" class="spinner-border spinner-border-sm mx-3"></span>
    </button>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
/* import SidenavCard from "./SidenavCard.vue"; */
// import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
// import CreditCard from "../../components/Icon/CreditCard.vue";
/* import Box3d from "../../components/Icon/Box3d.vue"; */
// import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
// import Switches from "../../components/Icon/Switches.vue";
/* import Document from "../../components/Icon/Document.vue"; */
/* import Spaceship from "../../components/Icon/Spaceship.vue"; */
/* import Settings from "../../components/Icon/Settings.vue"; */

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      loading: false,
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      darkMode: false,
    };
  },
  components: {
    SidenavCollapse,
    /* SidenavCard, */
    // Shop,
    Office,
    // CreditCard,
    /* Box3d, */
    // CustomerSupport,
    // Switches,
    /* Document, */
    /* Spaceship, */
    /* Settings, */
  },
  mounted() {
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode !== null) {
      this.darkMode = JSON.parse(savedDarkMode); // Update dark mode state
    }
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    logoutUser() {
      this.loading = true;
      this.$store.dispatch("auth/logout");
      this.loading = false;
    },
  },
};
</script>
