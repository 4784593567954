import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async addData(form_data) {
  
      try {
        const response = await axios.post(API_URL + "/api/v1/sg-5/create-sw/", form_data, {
            headers: {
              'X-Custom-Header': '11223344'
            }
          });
        return response.data;
      } catch (error) {
        console.error("Error retrieving user:", error);
        throw error; 
      }
    },
  };
