import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  async postShareToken() {
    let userPromise = new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("user_data"));
      if (user) {
        resolve(user);
      } else {
        reject("User not found in localStorage");
      }
    });

    try {
      const user_id = await userPromise;

      // Construct form data
      let form_data = {
        user: user_id.id,
      };

      // Make the POST request
      const response = await axios.post(
        `${API_URL}/api/v1/sg-5/get-Olt_data_port/shr-olt-token/`,
        form_data, // This is the request body
        {
          headers: {
            'X-Custom-Header': user_id.id, // Assuming a bearer token
            'Content-Type': 'application/json', // Ensure proper headers
          },
        }
      );

      return response.data;
    } catch (err) {
      console.error("Error:", err);
      throw err;
    }
  },
};
