<template>

    <div v-if="chartData" class="col-lg-12" >
        <!-- line chart -->
        <div class="card z-index-2 invoice-card mt-3" v-if="chartData" style="height:500px">
          <gradient-graph-chart
            id="chart-line"
            title="Chart"
            description="<i class='fa fa-arrow-up text-success'></i>
      <span class='font-weight-bold'>Chart </span>"
            :chart="chartData"
          />
        </div>
      </div>
      <div  v-else class="mt-12">
                <Skalaton />
                       
            </div>
</template>
<script>
import GradientGraphChart from "@/examples/Charts/GradientGraphChart.vue";
import getSwitchGrapgData from "../services/switch-graph-data.service";
import Skalaton from "@/components/Skalaton.vue";

export default {
components: {
    GradientGraphChart,
    Skalaton
  },

  data() {
        return {
            description_data: [],
            dashoard_chart_data:[],
            chartData: null,
            olt:this.$route.params.olt,
            id:this.$route.params.id

        }
  },

  async mounted() {
    this.getSwitchGrapg();
  },

  watch: {
    '$route.params.olt': function(newVal) {
      this.olt = newVal;
      window.location.reload();
    },
    '$route.params.id': function(newVal) {
      this.id = newVal;
      window.location.reload();
    }
  },

  methods: {
    async getSwitchGrapg() {
      try {
        this.dashoard_chart_data = await getSwitchGrapgData.getSwitchGrapgData(this.id,this.olt);
        let data = this.dashoard_chart_data?.data?.filter(item => Object.prototype.hasOwnProperty.call(item, 'RXpowerdBm'));
        this.description_data = data.map(item => {
          const rxPowerValue = item.RXpowerdBm.replace(' dBm', '');
          return Number(rxPowerValue);
        });;
        // Prepare chart data
        this.chartData = {
          labels: data?.map(item => `${item.id}`),

          datasets: [
            {
              label: '-dBm',
              data: this.description_data,
            },
          ],
        };
        console.log(this.chartData)
      } catch (error) {
        console.error('Error fetching dashboard chart data:', error);
      }
    },
  }
};

</script>
