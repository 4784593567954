import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async generateInvoicePayNow(form_data) {
      try {
        const response = await axios.post(API_URL + `/api/v1/sg-5/billing/create/`, form_data);
        return response.data;
      } catch (err) {
        console.error("Error:", err);
        throw err.response.data.error; 
      }
    },
  };
