<template>
  <div class="row justify-content-center">
    <div class="col-8 col-sm-8 px-4">
      <div id="basic-info" class="card mt-4">
        <div class="card-body pt-0 invoice-card">

          <div class="row mb-3 p-3">
            <div class="col-12 col-sm-12 mb-3">
              <label class="form-label">Name</label>
              <input
                v-model="formData.name"
                type="text"
                placeholder="Name"
                class="form-control invoice-card"
              />
            </div>
            <div class="col-12 col-sm-12 mb-3">
              <label class="form-label">IP</label>
              <input
                v-model="formData.ip_address"
                type="text"
                placeholder="IP"
                class="form-control invoice-card"
              />
            </div>
            <div class="col-12 col-sm-12 mb-3">
              <label class="form-label">Port</label>
              <input
                v-model="formData.port"
                type="text"
                placeholder="Port"
                class="form-control invoice-card"
              />
            </div>
          </div>
          
          <button
            class="float-end mb-0 btn btn-success"
            color="dark"
            variant="gradient"
            size="sm"
            @click="submitForm"
          >
            Add New <span v-if="loading" class="spinner-border spinner-border-sm mx-3"></span>
          </button>
            <router-link :to="{name:'OltList'}">
          <button
            class="float-end mx-3 mb-0 btn btn-secondary"
            color="dark"
            variant="gradient"
            size="sm"
          >
            Cancel
          </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showSwal from "../mixins/showSwal.js";  
import {deviceApi} from "../services/device.service";
export default {
  data() {
    return {
      formData: {
        ip_address: "",
        name: "",
        port: ""
      }
    };
  },
  methods: {
    async submitForm() {
      this.loading = true
      try{
            await deviceApi().postDevice(this.formData);
            showSwal.methods.showSwal({
              type: "success",
                message: "Data Created Successfully!",
            });
            this.$router.push("/dashboard");
        } catch (error) {        
        this.loading=false
        console.error("Error adding data:", error);
        showSwal.methods.showSwal({
          type: "error",
            message: "Failed to Create!",
        });
        }
    },
  }
};
</script>
<style scoped>
.has-error .form-control {
  border: 1px solid red;
}
.has-success .form-control {
  border: 1px solid green;
}
.check-icon {
  color: green!important;
  position: absolute;
  right: 20px;
  top: 37px;
}
.cross-icon {
  color: red!important;
  position: absolute;
  right: 20px;
  top: 37px;
}
</style>
