import axios from "axios";
const API_URL = process.env.VUE_APP_API_BASE_URL;
export function useVpnApi() {
  async function checkVpn(id) {
    try {

      let tokenPromise = new Promise((resolve, reject) => {
        let token = JSON.parse(localStorage.getItem("userF"));
        if (token) {
          resolve(token);
        } else {
          reject("Token not found in localStorage");
        }
      });

        const token = await tokenPromise;
        const response = await axios.get(API_URL+`/create_pppoe_user/?user_id=${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        return response.data;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }
  
  return {
    checkVpn,
  };
}