<template>
  <div
    class="mb-4 col-lg-6 mb-lg-3"
    v-if="port_count_data?.GPON[0] && port_count_data.GPON[0]?.port_count_List"
  >
    <div class="card z-index-1">
      <div class="p-3 card-body invoice-card" style="min-height: 400px">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <span>GPON </span>
            <!-- <span
              @click="deleteMacPopupOpen"
              class="bg-danger rounded mx-2 px-2 cursor-pointer"
            >
              Delete Mac
            </span> -->
          </div>

          <div>
            <p class="mt-3">
              OLT: {{ port_count_data.GPON[0]?.olt_name }} | Port Used:
              {{ port_count_data.GPON[0]?.port_use }}
            </p>
          </div>
        </div>

        <hr class="my-3" />
        <div class="mt-3 row justify-content-center">
          <div class="row justify-content-between">
            <div
              class="col-md-6 px-6 mb-3 text-center cursor-pointer"
              v-for="(
                data, index
              ) in port_count_data.GPON[0]?.port_count_List.slice(0, 8)"
              :key="index"
            >
              <div>
                <div class="d-flex justify-content-between">
                  <p class="mb-0 text-xs font-weight-bold">
                    <b class="text-lg"> {{ data?.port_name }} </b> 
                      <span @click="deleteMacPopupOpen(data?.port_name)" class="rounded mx-1 pr-1 text-xs cursor-pointer" style="background-color:#730e0e !important">
                        Clear
                      </span>
                  </p>
                  <p class="mb-0 text-xs font-weight-bold">
                    <b class="text-lg">{{ data?.port_count }}/128</b>
                  </p>
                </div>
                <div class="progress w-100 mt-3">
                  <div
                    class="progress-bar"
                    :class="progressBarClass(data?.port_count)"
                    role="progressbar"
                    :style="{
                      width: `${Math.min(
                        (data?.port_count / 128) * 100,
                        100
                      )}%`,
                    }"
                    :aria-valuenow="data?.port_count"
                    aria-valuemin="0"
                    aria-valuemax="128"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn btn-secondery bg-light w-20 btn-xs mt-3"
            @click="gpon_popup = true"
          >
            Show More
          </button>
        </div>
      </div>
    </div>
    <div class="col-6 text-end" v-if="gpon_popup">
      <div class="popup_bg"></div>
      <div id="myModal" class="modal_class">
        <div class="modal-content invoice" style="max-width: 1000px !important">
          <div class="d-flex justify-content-between">
            <span>GPON</span>
            <span @click="popup_close" class="close">&times;</span>
          </div>

          <div
            class="hover-popup my-3"
            style="height: 500px; overflow-y: auto; scrollbar-width: thin"
          >
            <div class="popup-content">
              <div class="row justify-content-center">
                <div
                  class="col-md-6 px-6 mb-3 text-center cursor-pointer"
                  v-for="(item, index) in port_count_data?.GPON"
                  :key="index"
                >
                  <p class="mt-3">
                    OLT: {{ item?.olt_name }} | Port Used:
                    {{ item.port_use }}
                  </p>

                  <hr class="my-3" />
                  <div
                    v-for="(data, index) in item?.port_count_List"
                    :key="index"
                  >
                    <div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-0 text-xs font-weight-bold">
                          <b class="text-lg"> {{ data?.port_name }} </b>
                          <span @click="deleteMacPopupOpenLoop(item?.olt_Port_count,data?.port_name)" class="rounded mx-1 pr-1 text-xs cursor-pointer" style="background-color:#730e0e !important">
                            Clear
                          </span>
                        </p>
                        <p class="mb-0 text-xs font-weight-bold">
                          <b class="text-lg">{{ data?.port_count }}/128</b>
                        </p>
                      </div>
                      <div class="progress w-100 mt-2 mb-3">
                        <div
                          class="progress-bar"
                          :class="progressBarClass(data?.port_count)"
                          role="progressbar"
                          :style="{
                            width: `${Math.min(
                              (data?.port_count / 128) * 100,
                              100
                            )}%`,
                          }"
                          :aria-valuenow="data?.port_count"
                          aria-valuemin="0"
                          aria-valuemax="128"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 text-end" v-if="delete_popup">
      <div class="popup_bg"></div>
      <div id="myModal" class="modal_class">
        <div class="modal-content invoice p-4 shadow-lg rounded">
          <div class="d-flex justify-content-center align-items-center mb-3">
            <h5 class="text-center m-0">Are you sure?</h5>
          </div>
          <p class="text-center">
            Need Telnet & OLT credential for MAC address delete from OLTCARE
          </p>
          <span v-if="loader">            
            <Skalaton /> 
          </span>
          <p v-if="port_error" class="text-center" style="color: red !important;">
            {{port_error}}
          </p>
          <div class="text-center">
            <button  @click="deletePortPopupOpen(port_count_data.GPON[0]?.olt_Port_count)" class="btn btn-sm btn-danger w-40 mx-1">Delete Only Port</button>
          </div>
          <div class="text-center">
            <button :disabled="delete_permission" @click="deleteMac(port_count_data.GPON[0]?.olt_Port_count)" class="btn btn-danger w-40 mx-1">Yes, delete it!</button>
            <button
              class="btn btn-success w-40 mx-1"
              @click="deleteMacPopupClose"
            >
              No, keep it
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 text-end" v-if="delete_port_popup">
      <div class="popup_bg"></div>
      <div id="myModal" class="modal_class">
        <div class="modal-content invoice p-4 shadow-lg rounded">
          <div class="d-flex justify-content-center align-items-center mb-3">
            <h5 class="text-center m-0">Are you sure?</h5>
          </div>
          <p class="text-center">
            Want to delete port !!
          </p>
           <span v-if="loader">            
            <Skalaton /> 
          </span>
          <p v-if="port_error" class="text-center" style="color: red !important;">
            {{port_error}}
          </p>
          <div class="text-center">
            <button :disabled="delete_permission" @click="deletePort()" class="btn btn-danger w-40 mx-1">Yes, delete it!</button>
            <button
              class="btn btn-success w-40 mx-1"
              @click="deletePortPopupClose"
            >
              No, keep it
            </button>
          </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";
import showSwal from "/src/mixins/showSwal.js";
import getDeleteMac from "../services/delete-mac.service";
import getCheckOlt from "../services/check-olt.service";
import Skalaton from "@/components/Skalaton.vue";
import getDeletePortUser from "../services/delete-port-user.service";


// Props (assumed)
const props = defineProps({
  port_count_data: {
    type: Array,
    required: true,
  },
});

const gpon_popup = ref(false);

// Methods
const popup_close = () => {
  gpon_popup.value = false;
  delete_id.value = ''
};

//Delete Mac
const delete_popup = ref(false);
const delete_permission = ref(true);
const delete_port = ref('');
const port_error = ref('') 
const delete_id = ref('') 

const deleteMacPopupOpen = async (port) => {
  try {
  let response = await getCheckOlt.getCheckOlt(props.port_count_data.GPON[0]?.olt_Port_count);
  if (response.status == "200") {
    delete_permission.value = false
  }
  } catch (error) {
    port_error.value = error.response?.data?.error || "An error occurred. Please try again.";
    showSwal.methods.showSwal({
      type: "error",
      message: port_error.value,
    });
    console.error("Error deleting data:", error);
  }
  delete_port.value = port
  delete_popup.value = true;
};

const deleteMacPopupOpenLoop = async ( id, port) => {
  delete_id.value = id;
  try {
  let response = await getCheckOlt.getCheckOlt(delete_id.value);
  if (response.status == "200") {
    delete_permission.value = false
  }
  } catch (error) {
    port_error.value = error.response?.data?.error || "An error occurred. Please try again.";
    showSwal.methods.showSwal({
      type: "error",
      message: port_error.value,
    });
    console.error("Error deleting data:", error);
  }
  delete_port.value = port
  delete_popup.value = true;
};


const deleteMacPopupClose = () => {
  delete_popup.value = false;
};


const loader = ref(false)

const deleteMac = async (port) => {
  loader.value =true
  try {
        if(delete_id.value){
          await getDeleteMac.getDeleteMac(delete_id.value,delete_port.value);
        }else{
          await getDeleteMac.getDeleteMac(port,delete_port.value);
        }
        
        showSwal.methods.showSwal({
          type: "success",
          message: "Deleted Successfully!",
        });
        deleteMacPopupClose();
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: error.response.data.message ?? error.response.data.error,
        });
        console.error("Error deleting data:", error);
      }
  loader.value =false
};



//Delete Port
const port_id = ref("");
const delete_port_popup = ref(false);

const deletePortPopupOpen = (port) => {
  delete_popup.value = false;
  port_id.value = port;
  delete_port_popup.value = true;
};

const deletePortPopupClose = () => {
  port_id.value = "";
  delete_port.value = "";
  delete_port_popup.value = false;
};

const deletePort = async () => {
  loader.value = true;
  try {
    await getDeletePortUser.getDeletePortUser(port_id.value, delete_port.value);
    showSwal.methods.showSwal({
      type: "success",
      message: "Deleted Successfully!",
    });
    deletePortPopupClose();
  } catch (error) {
    showSwal.methods.showSwal({
      type: "error",
      message: error.response.data.message,
    });
    console.error("Error deleting data:", error);
  }
  loader.value = false;
};

const progressBarClass = (port_count) => {
  if (port_count >= 120) {
    return "bg-danger"; 
  } else if (port_count >= 100) {
    return "bg-warning"; 
  } else {
    return "bg-success"; 
  }
};

const port_count_data = props.port_count_data;
</script>
<style scoped>
.modal_class {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #aaaaaa;
  opacity: 0.93;
}

.popup_bg {
  opacity: 0.5;
}

.modal-content {
  background-color: #fff;
  max-width: 500px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
</style>

