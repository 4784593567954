<template>
  <div class="row justify-content-center">
    <div class="col-12 col-sm-10 px-4">
      <div id="basic-info" class="card mt-4">
        <div class="card-body pt-0 invoice-card">
          <div class="row mb-3 p-3">
            <div class="col-12 col-sm-12 mb-3">
              <label class="form-label">Name</label>
              <input
                v-model="formData.name"
                type="text"
                placeholder="Name"
                class="form-control invoice-card"
              />
            </div>
            <div class="col-12 col-sm-12 mb-3">
              <label class="form-label">IP</label>
              <input
                v-model="formData.ip_address"
                type="text"
                placeholder="IP"
                class="form-control invoice-card"
              />
            </div>
            <div class="col-12 col-sm-12 mb-3">
              <label class="form-label">Port</label>
              <input
                v-model="formData.port"
                type="text"
                placeholder="Port"
                class="form-control invoice-card"
              />
            </div>
          </div>
          
          <button
            class="float-end mb-0 btn btn-success"
            color="dark"
            variant="gradient"
            size="sm"
            @click="submitForm"
          >
            Update Olt
          </button>
            <router-link :to="{name:'OltList'}">
          <button
            class="float-end mx-3 mb-0 btn btn-secondary"
            color="dark"
            variant="gradient"
            size="sm"
          >
            Cancel
          </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {deviceApi} from "../services/device.service";
import showSwal from "../mixins/showSwal.js";
export default {
  data() {
    return {
      data : [],
      edit_id : this.$route.params.edit_id,
      formData: {
        ip_address: "",
        name: "",
        port: ""
      }
    };
  },
  mounted(){
    this.getData();
  },
  methods: {
            async getData() {
                const response = await deviceApi().getDevice();
                this.data = response.filter(item => item.id == this.$route.params.edit_id);
                if(this.data){
                    this.formData.ip_address =this.data[0].ip_address;
                    this.formData.name =this.data[0].name;
                    this.formData.port =this.data[0].port;
                }
            },
    async submitForm() {
      try {
        await deviceApi().putDevice(this.formData,this.edit_id);
        showSwal.methods.showSwal({
          type: "success",
            message: "Data Updated Successfully!",
        });
        this.$router.push("/dashboard");
        } catch (error) {
        console.error("Error updating data:", error);
        showSwal.methods.showSwal({
          type: "error",
            message: "Failed to Update!",
        });
        }
    },

    
  }
};
</script>
<style scoped>
.has-error .form-control {
  border: 1px solid red;
}
.has-success .form-control {
  border: 1px solid green;
}
.check-icon {
  color: green!important;
  position: absolute;
  right: 20px;
  top: 37px;
}
.cross-icon {
  color: red!important;
  position: absolute;
  right: 20px;
  top: 37px;
}
</style>

