<template>
  <main class="mt-0 main-content main-content-bg">
    <div v-if="show" class="col-6 text-end">
      <div v-if="Terms" id="myModal" class="modal_class">
        <div class="modal-content invoice">
          <span @click="show = false" class="close">&times;</span>
          <h3 class="text-center">{{ Terms[0]?.title }}</h3>
          <p class="text-center text-sm text-justify py-3">
            {{ Terms[0]?.content }}
          </p>
        </div>
      </div>
    </div>
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-5 col-lg-7 col-md-9 d-flex flex-column">
              <div class="mt-6 card card-plain border p-3 dark-modeon">
                <div class="d-flex justify-content-center">
                  <img
                    src="../assets/img/logos/logo.jpg"
                    class="navbar-brand-img rounded-circle"
                    style="height: 90px"
                    alt="main_logo"
                  />
                </div>
                <div class="card-body">
                  <form
                    @submit.prevent="handleRegister"
                    role="form"
                    class="text-start"
                  >
                    <div class="mb-3">
                      <input
                        id="username"
                        type="text"
                        placeholder="User Name"
                        aria-label="Name"
                        class="form-control dark-modeon"
                        v-model="user.username"
                      />
                      <p v-if="errors" class="text-danger">{{errors?.username[0]}}</p>
                    </div>
                    <div class="mb-3">
                      <input
                        id="password"
                        type="password"
                        placeholder="Password"
                        aria-label="Password"
                        class="form-control dark-modeon"
                        v-model="user.password"
                      />
                      <p v-if="errors" class="text-danger">{{errors.password[0]}}</p>
                    </div>
                    <soft-checkbox
                      v-if="Terms"
                      id="flexCheckDefault"
                      name="flexCheckDefault"
                      class="font-weight-light"
                      checked
                    >
                      I agree the
                      <a
                        href="javascript:;"
                        @click="show = true"
                        class="text-dark font-weight-bolder"
                        >Terms and Conditions</a
                      >
                    </soft-checkbox>

                    <div class="text-center">
                      <soft-button
                        color="success"
                        full-width
                        variant="gradient"
                        class="my-4"
                        >Sign up
                        <span
                          v-if="loading"
                          class="spinner-border spinner-border-sm mx-3"
                        ></span>
                      </soft-button>
                    </div>
                    <p class="text-sm mt-3 mb-0">
                      Already have an account?
                      <router-link
                        :to="'/login'"
                        class="text-success text-gradient font-weight-bold"
                      >
                        Sign in
                      </router-link>
                    </p>
                  </form>
                </div>
                <div v-if="CompanyInfo" class="text-center">
                  <a
                    :href="CompanyInfo.social_media.facebook.page.link"
                    target="_blank"
                    ><img
                      class="rounded-circle mx-3"
                      style="width: 30px"
                      :src="CompanyInfo.social_media.facebook.page.logo"
                      alt=""
                  /></a>
                  <a
                    :href="
                      CompanyInfo.social_media.facebook.app.play_store_link
                    "
                    target="_blank"
                    ><img
                      class="rounded-circle"
                      style="width: 30px"
                      :src="CompanyInfo.social_media.facebook.app.logo"
                      alt=""
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
import getTermsData from "../services/terms.service";
import getCompanyInfoData from "../services/company-info.service";

import { mapMutations } from "vuex";

export default {
  name: "SignupBasic",
  components: {
    SoftCheckbox,
    SoftButton,
  },
  data() {
    const user = {
      username: "",
      password: "",
    };
    let errors = {
      username: "",
      password: "",
    };

    const Terms = null;
    const CompanyInfo = null;

    return {
      user,
      loading: false,
      show: false,
      Terms,
      CompanyInfo,
      errors
    };
  },
  async mounted() {
    this.getTerms();
    this.getCompanyInfo();
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async handleRegister() {
      this.loading = true;
      try {
        await this.$store.dispatch("auth/register", this.user);
        this.loading = false;
        showSwal.methods.showSwal({
          type: "success",
          message:
            "Successfully registered.",
          width: 600,
        });
        
         location.reload()
        // this.$router.push("/login");
      } catch (error) {
        this.errors = error.response.data
        showSwal.methods.showSwal({
          type: "error",
          message: `${error.response?.data?.username?.[0] || error.response?.data?.password?.[0] || "An error occurred."}`,
          width: 500,
        });
      }
      this.loading = false;
    },

    async getTerms() {
      this.Terms = await getTermsData.getTermsData();
    },

    async getCompanyInfo() {
      this.CompanyInfo = await getCompanyInfoData.getCompanyInfoData();
    },
  },
};
</script>
<style scoped>
/* The Modal (background) */
.modal_class {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #aaaaaa;
}

/* Modal Content/Box */
.modal-content {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  max-width: 95%;
  padding: 20px;
  position: fixed;
  overflow-y: auto;
}

/* Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
