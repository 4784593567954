import axios from "axios";
const API_URL = process.env.VUE_APP_API_BASE_URL;
export function deviceApi() {
  async function getDevice() {
    try {
      let tokenPromise = new Promise((resolve, reject) => {
        let token = JSON.parse(localStorage.getItem("userF"));
        if (token) {
          resolve(token);
        } else {
          reject("Token not found in localStorage");
        }
      });

      const token = await tokenPromise;

      const response = await axios.get(API_URL + `/api/get-devices/`, {
         headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }
  async function getDeviceInfo() {
    try {
      let tokenPromise = new Promise((resolve, reject) => {
        let token = JSON.parse(localStorage.getItem("userF"));
        if (token) {
          resolve(token);
        } else {
          reject("Token not found in localStorage");
        }
      });

      const token = await tokenPromise;

      const response = await axios.get(API_URL + `/api/get-device-info/`, {
         headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }
  async function getProfile() {
    try {
      let tokenPromise = new Promise((resolve, reject) => {
        let token = JSON.parse(localStorage.getItem("userF"));
        if (token) {
          resolve(token);
        } else {
          reject("Token not found in localStorage");
        }
      });

      const token = await tokenPromise;

      const response = await axios.get(API_URL + `/api/user/profile/`, {
         headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }
  async function postDevice(from_data) {
    let tokenPromise = new Promise((resolve, reject) => {
      let token = JSON.parse(localStorage.getItem("userF"));
      if (token) {
        resolve(token);
      } else {
        reject("Token not found in localStorage");
      }
    });

    const token = await tokenPromise;
    try {
      const response = await axios.post(API_URL + `/api/get-devices/`,from_data, {
        headers: {
         'Authorization': 'Bearer ' + token
       }
     });
      return response.data;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }
  async function deleteDevice(id) {
    let tokenPromise = new Promise((resolve, reject) => {
      let token = JSON.parse(localStorage.getItem("userF"));
      if (token) {
        resolve(token);
      } else {
        reject("Token not found in localStorage");
      }
    });

    const token = await tokenPromise;
    try {
      const response = await axios.delete(API_URL + `/api/devices/${id}`, {
        headers: {
         'Authorization': 'Bearer ' + token
       }
     });
      return response.data;
    } catch (error) {
      console.error("Error retrieving user:", error);
      throw error; 
    }
  }
  async function putDevice(from_data,id) {
    let tokenPromise = new Promise((resolve, reject) => {
      let token = JSON.parse(localStorage.getItem("userF"));
      if (token) {
        resolve(token);
      } else {
        reject("Token not found in localStorage");
      }
    });

    const token = await tokenPromise;
    try {
      const response = await axios.put(API_URL + `/api/devices/${id}/`,from_data, {
        headers: {
         'Authorization': 'Bearer ' + token
       }
     });
      return response.data;
    } catch (error) {
      console.error("Error retrieving user:", error);
      throw error; 
    }
  }
  async function putProfile(from_data,id) {
    let tokenPromise = new Promise((resolve, reject) => {
      let token = JSON.parse(localStorage.getItem("userF"));
      if (token) {
        resolve(token);
      } else {
        reject("Token not found in localStorage");
      }
    });

    const token = await tokenPromise;
    try {
      const response = await axios.put(API_URL + `/api-update/user-profile/${id}/`,from_data, {
        headers: {
         'Authorization': 'Bearer ' + token
       }
     });
      return response.data;
    } catch (error) {
      console.error("Error retrieving user:", error);
      throw error; 
    }
  }
  async function resencData() {
    try {
      // Get token from localStorage
      const token = JSON.parse(localStorage.getItem("userF"));
      if (!token) {
        throw new Error("Token not found in localStorage");
      }
  
      // Make API request with proper headers
      const response = await axios.post(
        API_URL + `/api/services/resync/hlPspf9zN5WZmjoFwAAp/`,
        {}, // Empty data object if no request body is needed
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error("Error retrieving user:", error);
      throw error;
    }
  }
  
 
  
  return {
    getDevice,
    getDeviceInfo,
    getProfile,
    postDevice,
    deleteDevice,
    putDevice,
    putProfile,
    resencData
  };
}