<template>
  <div class="fixed-plugin">
    <a
      class="px-3 py-2 fixed-plugin-button text-dark position-fixed"
      @click="toggle"
    >
      <!-- Support Icon -->
      <i class="py-2 fas fa-headset"></i>
    </a>
    <div class="shadow-lg card blur ">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-white fixed-plugin-close-button">
            x <!-- <i class="fas fa-close"></i> -->
          </button>
        </div>
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="pt-0 card-body pt-sm-3">
        <div class=" dark" >
          <h5 class="card-title">Contact Information</h5>
          <ul class="list-unstyled custom-list-style" >
            <li><a class="text-secondary" :href="`tel:${SupportData[0]?.phone_number}`"><i class="fa fa-phone mx-3"></i>{{SupportData[0]?.phone_number}}</a></li>
            <li><a class="text-secondary" :href="`mailto:${SupportData[0]?.maill_addres}`"><i class="fa fa-envelope mx-3"></i>{{SupportData[0]?.maill_addres}}</a></li>
            <li><a class="text-secondary" :href="SupportData[0]?.whtapp" target="_blank"><i class="fa fa-whatsapp mx-3"></i> Click here to chat</a></li>
          </ul>
        </div>
        <hr class="my-1 horizontal dark" />
        <div class="pt-0  pt-sm-3">
          <h5 class="card-title mb-4" style="border-bottom: 1px solid #ccc; padding-bottom: 10px;">Other Service List</h5>
          <div class="dark text-white" style="max-height: 450px; overflow-y: auto;">
            <div v-for="(product, index) in ProductListData" :key="index" class="mb-3 border-light" 
                 style="width: 18rem; border: 1px solid #ccc; border-radius: 10px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
              <a :href="product.target_link" target="_blank" class="text-decoration-none text-white">
                <img :src="product.product_photo" class="card-img-top" alt="Product Image" 
                     style="width: 100%; height: 150px; border-top-left-radius: 10px; border-top-right-radius: 10px;">
                <div class="card-body">
                  <h5 class="card-title">{{ product.product_name }}</h5>
                  <p class="card-text">{{ product.description }}</p>
                  <a :href="product.target_link" target="_blank" class="btn btn-success">Open</a>
                </div>
              </a>
            </div>
          </div>
          
         
          <div class="pb-3 mx-6 mb-3 sidenav-footer fixed-bottom text-center">
            <p class="mb-0">© NuxtGen 2024</p>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>


<script>
import others from ".././services/others.service";
export default {
  name: "configurator",
  props: ["toggle"],
  data() {
    return {
      ProductListData:[],
       SupportData:[]
    };
  },
  methods: {
     async getOthersData() {
      try {
        this.ProductListData = await others.getOthersProductListData();
        this.SupportData = await others.getOthersSupportData();
      } catch (error) {
        console.error('Error fetching others data:', error);
      }
    },
  },

  
  async mounted() {
            this.getOthersData();
        },
  
};
</script>
