<template>
  <div :style="{ width: chartWidth, height: chartHeight }">
    <canvas ref="doughnutChart"></canvas>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import {
  Chart,
  DoughnutController,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
Chart.register(DoughnutController, ArcElement, Tooltip, Legend);

export default {
  name: "DoughnutChart",
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
    chartWidth: {
      type: String,
      default: "400px",
    },
    chartHeight: {
      type: String,
      default: "400px",
    },
    cutoutSize: {
      type: String,
      default: "85%",
    },
    centerText: {
      type: String,
      default: "", // Text to display
    },
  },
  setup(props) {
    const doughnutChart = ref(null);

    // Custom plugin for center text
    const centerTextPlugin = {
      id: "centerText",
      beforeDraw(chart) {
        const ctx = chart.ctx;
        const { width, height } = chart;

        ctx.save();
        ctx.font = "bold 16px Arial"; // Customize font
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#000"; // Text color

        const text = props.centerText || "";
        const centerX = width / 2;
        const centerY = height / 2;

        ctx.fillText(text, centerX, centerY); // Render text
        ctx.restore();
      },
    };

    onMounted(() => {
      // Register the plugin
      Chart.register(centerTextPlugin);

      const options = {
        ...props.chartOptions,
        cutout: props.cutoutSize,
      };

      new Chart(doughnutChart.value, {
        type: "doughnut",
        data: props.chartData,
        options: options,
      });
    });

    return {
      doughnutChart,
    };
  },
};
</script>

<style>
/* Optional styling */
</style>
