<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card invoice">
          <!-- Card header -->
          <div class="pb-0 card-header invoice">
    <div class="d-lg-flex justify-content-between mx-3">
        <div class="d-flex gap-3">
            <h5>Live Log List</h5>
            <div style="margin-top:-18px">
              <router-link to="/live-log-list">
          <img :src="live" alt="">
          </router-link>
            </div>

        </div>
        <div class="d-flex gap-3">
        <div class="flex-grow-1">
          <router-link to="/log-list">
          <button class="btn btn-sm btn-success">Back</button>
          </router-link>
        </div>
    </div>
  </div>
</div>

            
          <div class="px-0 pb-0 card-body invoice rounded-0">
            <div class="table-responsive">
              <table id="users-list" class="table align-items-center mb-0">
                <thead >
                  <tr>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" @click="sort('date')" :class="{ 'asc': sortKey === 'date' && sortOrder === 'asc', 'desc': sortKey === 'date' && sortOrder === 'desc' }">Date<span class="sort-icon"> </span></th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" @click="sort('device_name')" :class="{ 'asc': sortKey === 'device_name' && sortOrder === 'asc', 'desc': sortKey === 'device_name' && sortOrder === 'desc' }">Device Name <span class="sort-icon"> </span></th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" @click="sort('log')" :class="{ 'asc': sortKey === 'log' && sortOrder === 'asc', 'desc': sortKey === 'log' && sortOrder === 'desc' }">Log <span class="sort-icon"> </span></th>   
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" @click="sort('time')" :class="{ 'asc': sortKey === 'time' && sortOrder === 'asc', 'desc': sortKey === 'time' && sortOrder === 'desc' }">Time <span class="sort-icon"> </span></th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <tr v-for="(item,index) in filteredData" :key="index">                    
                    <td class="align-middle text-center">{{ item.date }}</td>
                    <td class="align-middle text-center">{{ item.device_name }}</td>
                    <td class="align-middle text-center">{{ item.log }}</td>                         
                    <td class="align-middle text-center">{{ item.time }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'sweetalert2/dist/sweetalert2.min.css';
import logList from "../services/live-log-list.service";

import live from "@/assets/img/live.png";

export default {
  name: "Log",
  data() {
    return {
      sortKey: '',
      sortOrder: 'asc', 
      filteredData: [],
      live
    };
  },
  async mounted() {
    await this.getData();

    this.intervalId = setInterval(() => {
      this.getData();
    }, 3000);
  },
  beforeUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.intervalId);
  },
  methods: {
    async getData() {
      try {
        const response = await logList.getLiveLogListData();
        this.filteredData = response?.data;
      } catch (error) {
        console.error("Error fetching log list data:", error);
        this.filteredData = [];
      }
    },
    sort(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }

      this.filteredData.sort((a, b) => {
        const valueA = a[key] ? a[key].toString().toLowerCase() : '';
        const valueB = b[key] ? b[key].toString().toLowerCase() : '';
        const comparison = valueA.localeCompare(valueB);
        return this.sortOrder === 'asc' ? comparison : -comparison;
      });
    },
  },
};
</script>

<style>
td {
  padding: 12px 24px !important;
}
.sort-icon {
  margin-left: 5px;
}

.asc::after {
  content: "▲";
}

.desc::after {
  content: "▼";
}


.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50%;
    background-color: #40C133; /* or any other color you prefer */
    color: #fff; /* text color */
}


</style>
