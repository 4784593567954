<template>
    <div class="skeleton">
        <div class="skeleton-content"></div><!-- 
        <div class="skeleton-content"></div>
        <div class="skeleton-content"></div>
        <div class="skeleton-content"></div>
        <div class="skeleton-content"></div> -->
    </div>
</template>
<style scoped>


.skeleton-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-size: 400% 100%;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}


.skeleton-content {
  width: 30%;
  height: 20px;
  background: linear-gradient(90deg, #213550 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 400% 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  animation: shimmer 3.5s infinite linear;
}
</style>