<template>
  <div class="chart-js-res d-flex gap-3">
    <canvas :id="'myChart_' + chartId" class="myChart charts-cards dark-modeon"></canvas>
  </div>
</template>

<script setup>
import { onMounted, watch, defineProps } from 'vue';
import Chart from 'chart.js/auto';

const { chartId, item } = defineProps(["chartId","item"]);

let chartInstance = null;

onMounted(() => {
  drawChart();
});

watch(() => item, () => {
  if (chartInstance) {
    chartInstance.destroy();
  }
  drawChart();
});

function drawChart() {
  if (!item) return; // Exit if item is not yet available

  const labels = [`CPU  ${item.output_cpu}%`, `IDLE  ${100 - item.output_cpu}%`, `Memory  ${item.output_mem}%`];
  const data = [item.output_cpu, 100 - item.output_cpu];

  const chartColors = item.output_cpu > 70 ? ['#EA0606', '#eaeaea'] : ['#66ca99', '#eaeaea'];

  const ctx = document.getElementById(`myChart_${chartId}`).getContext('2d');

  chartInstance = new Chart(ctx, {
    type: 'doughnut',
    data: {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: data,
        backgroundColor: chartColors,
        hoverOffset: 4,
        cutout: '75%',
        borderWidth: 0
      }]
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle'
          }
        },
        title: {
          display: true,
          text: item.olt_name,
          font: {
            size: 16,
            weight: 'bold'
          },
          color: 'gray',
          padding: {
            top: 10
          }
        }
      },
      layout: {
        padding: 0
      }
    }
  });
}
</script>

<style>
/* Add any necessary styles here */
</style>
