import axios from "axios";
const Mac_Delete = process.env.VUE_APP_API_Mac_Delete;
export default {
    async getDeleteMac(id,port) {
      try {
        const response = await axios.post(Mac_Delete+"/get_data", 
          {
            oltid: id, 
            ponport: port
          }, 
          {
            headers: {
              'X-Custom-Header': '24dsfgdsg45435ref',
              'Content-Type': 'application/json'
            },
            referrerPolicy: 'no-referrer-when-downgrade'
          });
        
          
        return response.data;
      } catch (error) {
        console.error("Error retrieving user:", error);
        throw error; 
      }
    },
  };
