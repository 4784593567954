<template>
  <div class="mb-4 col-lg-6 mb-lg-3">
    <div class="card z-index-1">
      <div class="p-3 card-body invoice-card" style="min-height: 400px">
        <Skalaton />
      </div>
    </div>
  </div>
</template>
<script setup></script>
