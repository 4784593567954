<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div class="col-lg-12">
    <div class="d-flex align-items-center flex-wrap gap-3">
      <b
        >Current dBm
        <span
          v-if="
            status === 'online' ||
            ppp_active_sessions?.length > 0
          "
          class="badge mx-1 bg-dark text-white"
        >
          <span
            class="inline-block bg-success rounded-circle"
            style="
              height: 0.5rem;
              width: 0.5rem;
              display: inline-block;
              margin-right: 0.25rem;
            "
          ></span>
          Online
        </span>
        <span v-else class="badge mx-1 bg-dark text-white">
          <span
            class="inline-block bg-danger rounded-circle"
            style="
              height: 0.5rem;
              width: 0.5rem;
              display: inline-block;
              margin-right: 0.25rem;
            "
          ></span>
          Offline
        </span>
      </b>
      <b>
        <span
          class="badge mx-1 bg-dark text-danger cursor-pointer"
          @click="refresh"
        >
          Refresh
          <span
            v-if="refresh_loader"
            class="spinner-border spinner-border-sm mx-3"
          ></span>
          <span v-else>
            <i
              class="fas fa-sync-alt ml-1 mb-1"
              style="font-size: 10px; color: red"
            ></i>
          </span>
        </span>
      </b>
      <b v-if="share_port_data.port_type">
        <span>
          <div>
            <div class="d-flex justify-content-between">
              <p class="mb-0 text-xs font-weight-bold">
                <b class="text-sm"> {{ share_port_data?.port_number_name }} </b>
              </p>
              <p class="mb-0 text-xs font-weight-bold">
                <b class="text-sm mx-3"
                  >{{ share_port_data?.running_connection_port }}/{{
                    share_port_data?.port_type == "GPON" ? 128 : 64
                  }}</b
                >
              </p>
            </div>
            <div class="progress w-100">
              <div
                class="progress-bar"
                :class="
                  share_port_data?.port_type == 'GPON'
                    ? progressBarClass(share_port_data?.running_connection_port)
                    : progressBarClassEpon(
                        share_port_data?.running_connection_port
                      )
                "
                role="progressbar"
                :style="{
                  width: `${Math.min(
                    (share_port_data?.running_connection_port /
                      (share_port_data?.port_type == 'GPON' ? 128 : 64)) *
                      100,
                    100
                  )}%`,
                }"
                :aria-valuenow="share_port_data?.running_connection_port"
                aria-valuemin="0"
                :aria-valuemax="share_port_data?.port_type == 'GPON' ? 128 : 64"
              ></div>
            </div>
          </div>
        </span>
      </b>

      <i
        @click="setEdit()"
        class="fas fa-edit cursor-pointer"
        style="color: gray; font-size: 14px"
      ></i>
      <i
        @click="deletePopup(id)"
        class="fas fa-trash-alt cursor-pointer"
        style="color: gray; font-size: 12px"
      ></i>
    </div>
    <!-- 
    <div class="d-flex align-iems-center gap-4 mt-1">
      <p style="font-size: 13px !important" class="m-0 p-0">OLT STATUS</p>
      <p style="font-size: 13px !important" class="m-0 p-0">ONU STATUS</p>
    </div> -->
    <div class="d-flex">
      <div class="tabs mt-4 px-2 py-1">
        <button
          @click="setActive(1)"
          class="tabs_btn"
          :class="`${active === 1 ? 'tabs_btn_active' : ''}`"
        >
          Overview
        </button>
        <button
          @click="setActive(2)"
          class="tabs_btn"
          :class="`${active === 2 ? 'tabs_btn_active' : ''}`"
        >
          ONU Realtime
        </button>
        <button
          @click="setActive(3)"
          class="tabs_btn"
          :class="`${active === 3 ? 'tabs_btn_active' : ''}`"
        >
          Tasks
        </button>
        <button
          @click="setActive(4)"
          class="tabs_btn"
          :class="`${active === 4 ? 'tabs_btn_active' : ''}`"
        >
          Hosts
        </button>
      </div>
    </div>

    <div v-if="active === 1">
      <div class="mt-3 overview_items-wrapper">
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">Uptime</div>
          <div
            class="py-3"
            style="
              background: linear-gradient(
                to left top,
                rgb(79 79 79) 0%,
                rgb(42 51 78) 67%
              );
            "
          >
            <h4>
              <b>{{
                ppp_active_sessions?.length > 0
                  ? ppp_active_sessions[0].Uptime?.substring(0, 20)
                  : "N/A"
              }}</b>
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">PPPOE ID</div>
          <div
            class="py-3"
            style="
              background: linear-gradient(
                to left top,
                rgb(125 92 92) 0%,
                rgb(98 112 153) 67%
              );
            "
          >
            <h4>
              <b>{{ single_user_db_data.nowdb?.PPPOE_ID ?? "N/A" }}</b>
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">dBm</div>
          <div
            class="py-3"
            style="
              background: linear-gradient(
                to right,
                rgb(8 138 165) 0%,
                rgb(8 101 93) 99%
              );
            "
          >
            <h4>
              <b>{{ description?.substring(0, 20) ?? "N/A" }}</b>
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">
            Upload / Download
          </div>
          <div
            class="py-3"
            style="
              background: linear-gradient(
                to left top,
                rgb(211 32 32) 0%,
                rgb(169 52 81) 67%
              );
            "
          >
            <h4>
              <b
                >{{ total_rx ?? "N/A" }} /
                {{ total_tx ?? "N/A" }}
              </b>
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">Olt Name</div>
          <div
            class="py-3"
            style="
              background: linear-gradient(
                to left top,
                rgb(209 95 34) 0%,
                rgb(179 120 70) 67%
              );
            "
          >
            <h4>
              <b>{{ olt_connect__olt_name?.substring(0, 20) || "N/A" }}</b>
            </h4>
          </div>
        </div>

        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">Vendor</div>
          <div
            class="py-3"
            style="
              background: linear-gradient(
                to left top,
                rgb(107 24 131) 0%,
                rgb(165 25 151) 67%
              );
            "
          >
            <h4>
              <b>{{ vendor?.substring(0, 20) || "N/A" }}</b>
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">Router Brand</div>
          <div class="py-3">
            <h4>
              <b>{{
                ppp_active_sessions?.length > 0
                  ? ppp_active_sessions[0]?.Company?.substring(0, 20)
                  : "N/A"
              }}</b>
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">Remote Address</div>
          <div class="py-3">
            <h4>
              <b>{{
                ppp_active_sessions?.length > 0
                  ? ppp_active_sessions[0]?.RemoteAddress?.substring(0, 20)
                  : "N/A"
              }}</b>
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">
            Temperature / Vlan ID
          </div>
          <div class="py-3">
            <h4>
              <b
                >{{ Temperature_onu?.substring(0, 20) ?? "N/A" }} °C /
                {{
                  single_user_db_data.nowdb?.vlan_id?.substring(0, 20) ?? "N/A"
                }}</b
              >
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background-color: rgb(2,0,36 ">
            Last Update
          </div>
          <div class="py-3">
            <h5>
              <b>{{ last_update ?? "N/A" }}</b>
            </h5>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background-color: rgb(2,0,36 ">
            Last Disconnect / Time
          </div>
          <div class="py-3">
            <span
              v-if="refresh_loader"
              class="spinner-border spinner-border-sm mx-3"
            ></span>
            <span v-else>
              <h4>
                <b
                  >{{ last_dereg_reason?.substring(0, 10) ?? "N/A" }} /
                  {{ alive_time ? alive_time : "N/A" }}</b
                >
              </h4>
            </span>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background-color: rgb(2,0,36 ">
            Port Number
          </div>
          <div class="py-3">
            <h4>
              <b>{{ port_number?.substring(0, 20) ?? "N/A" }}</b>
            </h4>
          </div>
        </div>
      </div>

      <p class="mt-3"><b>Previous dBm</b></p>
      <div class="mb-2 overview_items-wrapper">
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">dBm</div>
          <div class="py-3">
            <h4>
              <b>{{
                single_user_db_data.previousdb?.description?.substring(0, 20) ??
                "N/A"
              }}</b>
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">PPPOE ID</div>
          <div class="py-3">
            <h4>
              <b
                >{{
                  single_user_db_data.previousdb?.PPPOE_ID?.substring(0, 20) ??
                  "N/A"
                }}
              </b>
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">Vlan ID</div>
          <div class="py-3">
            <h4>
              <b>{{
                single_user_db_data.previousdb?.vlan_id?.substring(0, 20) ??
                "N/A"
              }}</b>
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">Mac Address</div>
          <div class="py-3">
            <h4>
              <b>{{
                single_user_db_data.previousdb?.mac_address?.substring(0, 20) ??
                "N/A"
              }}</b>
            </h4>
          </div>
        </div>
        <div
          class="my-1 d-inline-block dark-modeon text-sm text-center"
          style="border-radius: 0px !important; padding-left: 0px !important"
        >
          <div class="py-1" style="background: rgb(2,0,36 ">Port Number</div>
          <div class="py-3">
            <h4>
              <b>{{
                single_user_db_data.previousdb?.port_number?.substring(0, 20) ??
                "N/A"
              }}</b>
            </h4>
          </div>
        </div>
      </div>

      <!-- line chart -->
      <div
        class="card z-index-2 invoice-card"
        v-if="chartData && loader"
        style="height: 450px"
      >
        <div class="calendar">
          <div
            class="calendar-item"
             :class="seleced_index == 'All' ? 'selected' : ''"
          >
            <div @click="filer('All', 'All')">
              <div class="calendar-day"></div>
              <div class="calendar-date pt-2">All</div>
            </div>
          </div>
          <div
            class="calendar-item"
            v-for="(data, index) in dashoard_chart_data"
            :key="index"
            :class="seleced_index == index ? 'selected' : ''"
          >
            <div @click="filer(data.date, index)">
              <div class="calendar-day">{{ data.date.split(", ")[1] }}</div>
              <div class="calendar-date">{{ data.date.split(", ")[0] }}</div>
            </div>
          </div>
        </div>
        <gradient-graph-chart
          v-if="loadernew"
          id="chart-line"
          title="Chart"
          description="<i class='fa fa-arrow-up text-success'></i>
      <span class='font-weight-bold'>Chart </span>"
          :chart="chartData"
        />
      </div>
      <div v-else class="card z-index-2 invoice-card" style="height: 450px">
        <Skalatonloader v-if="!loader" />
        <Skalaton class="mt-12" v-if="loader" />
      </div>

      <div class="col-12 text-end" v-if="epon_popup">
        <div class="popup_bg"></div>
        <div id="myModal" class="modal_class">
          <div
            class="modal-content invoice"
            style="max-width: 400px !important"
          >
            <div class="d-flex justify-content-between">
              <span>Update Data</span>
              <span @click="popup_close" class="close">&times;</span>
            </div>

            <div class="hover-popup my-3">
              <div class="popup-content">
                <div class="row justify-content-center">
                  <div class="mb-3 p-3">
                    <div class="col-12 col-sm-12 mb-3">
                      <div>
                        <textarea
                          v-model="formData.PPPOE_ID"
                          type="textarea"
                          rows="4"
                          placeholder="PPPOE ID"
                          class="form-control invoice-card"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 mb-3">
                      <div>
                        <input
                          v-model="formData.port_number"
                          type="text"
                          placeholder="Port Number"
                          class="form-control invoice-card"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex text-center">
                    <div class="grid grid-cols-2 gap-2">
                      <button
                        class="btn btn-secondary mx-3"
                        @click="popup_close"
                      >
                        Cancel
                      </button>
                      <button class="btn btn-success" @click="submitForm">
                        Update
                        <span
                          v-if="loading"
                          class="spinner-border spinner-border-sm mx-3"
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 text-end" v-if="delete_popup">
        <div class="popup_bg"></div>
        <div id="myModal" class="modal_class">
          <div class="modal-content invoice p-4 shadow-lg rounded">
            <div class="d-flex justify-content-center align-items-center mb-3">
              <h5 class="text-center m-0">Are you sure?</h5>
            </div>
            <div class="text-center">
              <button @click="deleteItem" class="btn btn-danger w-40 mx-1">
                Yes, delete it!
              </button>
              <button @click="popup_close" class="btn btn-success w-40 mx-1">
                No, keep it
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="active === 2" style="margin-top: 10px">
      <div class="chart_row_two text-white" v-if="Object.keys(rtl_data).length > 0">
        <div class="p-4 chart_row_one_card">
          <div class="d-flex ">
            <p
              class="text-center p-0 m-0"
              style="font-size: 25px; line-height: 80px"
            >
              Total Onu Status 
              <span v-if="rtl_data.VendorID != 'N/A'"
                class="inline-block bg-success rounded-circle"
                style="
                  height: 1rem;
                  width: 1rem;
                  display: inline-block;
                  margin-right: 0.25rem;
                "
              ></span>
              <span v-else
                class="inline-block bg-danger rounded-circle"
                style="
                  height: 1rem;
                  width: 1rem;
                  display: inline-block;
                  margin-right: 0.25rem;
                "
              ></span>
              <sup class="text-xs">{{this.port_number}}</sup>
            </p>
          </div>

          <div class="row" v-if="onu_status && Object.keys(rtl_data).length > 0">
            <div class="active_services">
              <div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Distance</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_data.Distance}}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Mac Address</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_data.Serial_number}}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Vendor ID</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_data.VendorID}}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Model ID</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_data.ModelID}}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Onu Type</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_data.Applied_onutype_template}}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Online Duration</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_data.Online_Duration}}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Current Time</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_data.Current_time}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          <div class="row" v-else>
            <Skalaton  />
            </div>
        </div>

        <div class="p-4 d-flex flex-column chart_row_two_card">
          <div
            class="w-100 d-flex align-items-center justify-content-center position-relative"
          >
            <DonutChart
              :chartData="donutchartDataMiddle"
              :donutchartOptions="chartOptions"
              chartWidth="300px"
              chartHeight="300px"
              cutoutSize="80%"
              centerText=""
            />
            <div class="centered_text_donought_chart">
              <p class="p-0 m-0 text-center" style="font-size: 20px">{{description}}</p>
              <p
                class="p-2 m-0 text-center text-xs "
                style="font-weight: 600; margin-top: -13px !important"
              >
                dBm
              </p>
            </div>
          </div>

          <div
                class="d-flex flex-grow-1 p-5 align-items-center justify-content-center"
                style="height: 190px"
              >
                <div class="w-50 d-flex flex-column align-items-start" style="border-right: 1px solid #80808071; padding-right: 10px;">
                  <p
                    class="text-center p-0 m-1"
                    style="font-size: 14px; font-weight: 600;"
                  >
                    <strong style="color: greenyellow !important;">• Temperature:</strong> {{Temperature_onu || 'N/A'}} °C
                  </p>
                </div>

                <div class="w-50 d-flex flex-column align-items-start" style="padding-left: 10px;">
                  <p
                    class="text-center p-0 m-1"
                    style="font-size: 14px; font-weight: 600;"
                  >
                    <strong style="color: greenyellow !important;">• Last Disconnect Reason:</strong> <br> {{last_dereg_reason || 'N/A'}}
                  </p>
                </div>
              </div>

        </div>

        <div class="p-4 chart_row_one_card">
          <div class="d-flex flex-column flex-md-row my-4">
            <div class="w-md-50 d-flex align-items-center">
              <div>
                <DonutChart
                  :chartData="donutchartData"
                  :donutchartOptions="chartOptions"
                  chartWidth="100px"
                  chartHeight="100px"
                  cutoutSize="80%"
                />
              </div>

              <div class="d-flex align-items-center mt-2 mt-lg-0 ml-3">
                <p class="text-center p-0 m-0" style="font-size: 60px">{{rtl_data?.Deactivation_Logs?.length}}</p>
                <div class="ml-2" style="color: gray; font-weight: 600">
                  <p class="p-0 m-0">Total</p>
                  <span class="p-0 m-0">Up Down List </span>
                </div>
              </div>
            </div>

            <div class="w-md-50 d-flex align-items-center">
              <div style="border-right: 1px solid #80808071; width: 33.3%" v-for="(item,index) in rtl_data.Deactivation_Reason_Summary" :key="index">
                <p
                  class="text-center p-0 m-0"
                  style="font-size: 30px; font-family: 600"
                >
                  {{item?.Count}}
                </p>
                <p
                  class="text-center"
                  style="
                    color: greenyellow !important;
                    font-weight: 600;
                    margin-top: -10px !important;
                  "
                >
                  {{item?.Deactive_Reason}}
                </p>
              </div>
            </div>
          </div>

          <div class="d-flex mt-3">
            <hr class="flex-grow-1 me-2" />
            <span style="font-size: 15px">Deactive Logs</span>
            <hr class="flex-grow-1 ml-2" />
          </div>
          <div class="active_services mt-2" v-if="Object.keys(rtl_data).length > 0">
            <div v-for="item in rtl_data?.Deactivation_Logs?.slice(0, 6)" :key="item">
              <div class="d-flex align-items-center">
                <div
                  class="rounded"
                  style="height: 50px; width: 50px; background-color: dimgrey"
                ></div>
                <div class="p-2">
                  <p class="m-0">{{item.Deactive_Reason}}</p>
                  <p
                    class="m-0"
                    style="font-size: 14px; color: greenyellow !important"
                  >
                    • Active Time ({{item.Active_Time}})
                  </p>
                  <p
                    class="m-0"
                    style="font-size: 14px; color: red !important"
                  >
                    • Deactive Time ({{item.Deactive_Time}})
                  </p>
                </div>
              </div>
            </div>

            <button  class="btn btn-secondery bg-light w-40 btn-xs mt-3 d-flex justify-content-center align-items-center text-center" @click="logs_popup=true"> 
              See More
            </button>
          </div>
          <div class=" mt-2" v-else>
                        <Skalaton  />
          </div>
        </div>
      </div>
      <div class="chart_row_three  text-white" v-else-if="Object.keys(rtl_Epon_data).length > 0">
        <div class="p-4 chart_row_one_card">
          <div class="d-flex ">
            <p
              class="text-center p-0 m-0"
              style="font-size: 25px; line-height: 80px"
            >
              Total Onu Status 
              <span v-if="rtl_Epon_data?.basic_info?.data?.status == 'Online'"
                class="inline-block bg-success rounded-circle"
                style="
                  height: 1rem;
                  width: 1rem;
                  display: inline-block;
                  margin-right: 0.25rem;
                "
              ></span>
              <span v-else
                class="inline-block bg-danger rounded-circle"
                style="
                  height: 1rem;
                  width: 1rem;
                  display: inline-block;
                  margin-right: 0.25rem;
                "
              ></span>
              <sup class="text-xs">{{this.port_number}}</sup>
            </p>
          </div>

          <div class="row" v-if="onu_status && Object.keys(rtl_Epon_data).length > 0">
            <div class="active_services">
              <div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Onu Name</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_Epon_data?.basic_info?.data?.onu_name}}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Distance</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_Epon_data?.basic_info?.data?.distance}}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Mac Address</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_Epon_data?.basic_info?.data?.macaddr}}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Software Version</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_Epon_data?.basic_info?.data?.ctcver}}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Extmodel</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_Epon_data?.basic_info?.data?.extmodel}}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="rounded"
                    style="height: 50px; width: 50px; background-color: dimgrey"
                  ></div>
                  <div class="p-2">
                    <p class="m-0">Vendor</p>
                    <p
                      class="m-0"
                      style="font-size: 14px; color: greenyellow !important"
                    >
                      {{rtl_Epon_data?.basic_info?.data?.vendor}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          <div class="row" v-else>
            <Skalaton  />
            </div>
        </div>
        <!-- <div
            class="w-100 d-flex align-items-center chart_row_one_card justify-content-center position-relative"
          >
            <DonutChart
              :chartData="donutchartData"
              :donutchartOptions="chartOptions"
              chartWidth="200px"
              chartHeight="200px"
              cutoutSize="80%"
              centerText=""
            />
            <div class="centered_text_donought_chart">
              <p class="p-0 m-0 text-center" style="font-size: 20px">{{description}}</p>
              <p
                class="p-0 m-0 text-center"
                style="font-weight: 600; margin-top: -13px !important"
              >
                dBm
              </p>
            </div>
          </div> -->


        <div class="p-4 d-flex flex-column chart_row_two_card">
          <div
            class="w-100 d-flex align-items-center justify-content-center position-relative"
          >
            <DonutChart
              :chartData="donutchartDataMiddle"
              :donutchartOptions="chartOptions"
              chartWidth="300px"
              chartHeight="300px"
              cutoutSize="80%"
              centerText=""
            />
            <div class="centered_text_donought_chart">
              <p class="p-0 m-0 text-center" style="font-size: 20px">{{rtl_Epon_data?.optical_diagnose?.data?.receive_power.split("dBm")[0]}}</p>
              <p
                class="p-2 m-0 text-center text-xs "
                style="font-weight: 600; margin-top: -13px !important"
              >
                Receive Power
              </p>
            </div>
          </div>

          <div
  class="d-flex flex-grow-1 align-items-center justify-content-center"
  style="height: 190px"
>
  <div class="w-50 d-flex flex-column align-items-start" style="border-right: 1px solid #80808071; padding-right: 10px;">
    <p
      class="text-center p-0 m-1"
      style="font-size: 14px; font-weight: 600;"
    >
      <strong style="color: greenyellow !important;">• Work Temperature:</strong> {{rtl_Epon_data?.optical_diagnose?.data?.work_temprature || 'N/A'}}
    </p>
    <p
      class="text-center p-0 m-1"
      style="font-size: 14px; font-weight: 600;"
    >
      <strong style="color: greenyellow !important;">• Work Voltage:</strong> {{rtl_Epon_data?.optical_diagnose?.data?.work_voltage || 'N/A'}}
    </p>
  </div>

  <div class="w-50 d-flex flex-column align-items-start" style="padding-left: 10px;">
    <p
      class="text-center p-0 m-1"
      style="font-size: 14px; font-weight: 600;"
    >
      <strong style="color: greenyellow !important;">• Transmit Bias:</strong> {{rtl_Epon_data?.optical_diagnose?.data?.transmit_bias || 'N/A'}}
    </p>
    <p
      class="text-center p-0 m-1"
      style="font-size: 14px; font-weight: 600;"
    >
      <strong style="color: greenyellow !important;">• Transmit Power:</strong> {{rtl_Epon_data?.optical_diagnose?.data?.transmit_power || 'N/A'}}
    </p>
  </div>
</div>

        </div>

        <div class="p-4 chart_row_one_card">
          <div class="d-flex flex-column flex-md-row my-4">
            <div class="w-md-50 d-flex align-items-center">
              <div>
                <DonutChart
                  :chartData="donutchartData"
                  :donutchartOptions="chartOptions"
                  chartWidth="100px"
                  chartHeight="100px"
                  cutoutSize="80%"
                />
              </div>

              <div class="d-flex align-items-center mt-2 mt-lg-0 ml-3">
                <p class="text-center p-0 m-0" style="font-size: 60px">{{rtl_Epon_data?.upldown_logs?.length}}</p>
                <div class="ml-2" style="color: gray; font-weight: 600">
                  <p class="p-0 m-0">Total</p>
                  <span class="p-0 m-0">Up Down List </span>
                </div>
              </div>
            </div>

            <div class="w-md-50 d-flex align-items-center">
              <div style="border-right: 1px solid #80808071; width: 33.3%" v-for="(value, key) in rtl_Epon_data?.upldown_logs_count" :key="key">
                <p
                  class="text-center p-0 m-0"
                  style="font-size: 30px; font-family: 600"
                >
                  {{value}}
                </p>
                <p
                  class="text-center p-1"
                  style="
                    color: greenyellow !important;
                    font-weight: 600;
                    margin-top: -10px !important;
                    margin:5px !important
                  "
                >
                  {{key}}
                </p>
              </div>
            </div>
          </div>

          <div class="d-flex mt-3">
            <hr class="flex-grow-1 me-2" />
            <span style="font-size: 15px">Deactive Logs</span>
            <hr class="flex-grow-1 ml-2" />
          </div>
          <div class="active_services mt-2" v-if="Object.keys(rtl_Epon_data).length > 0">
            <div v-for="item in rtl_Epon_data?.upldown_logs?.slice(0, 6)" :key="item">
              <div class="d-flex align-items-center">
                <div
                  class="rounded"
                  style="height: 50px; width: 50px; background-color: dimgrey"
                ></div>
                <div class="p-2">
                  <p class="m-0">{{item.status}}</p>
                  <p
                    class="m-0"
                    style="font-size: 14px; color: greenyellow !important"
                  >
                    • Date ({{item.date}})
                  </p>
                </div>
              </div>
            </div>

            <button  class="btn btn-secondery bg-light w-60 btn-xs mt-3 d-flex justify-content-center align-items-center text-center" @click="logs_popup=true"> 
              See More
            </button>
          </div>
          <div class=" mt-2" v-else>
                        <Skalaton  />
          </div>
        </div>
      </div>
      <div v-else>
        <skalatonloader></skalatonloader>
      </div>

      <!-- 2nd row -->
      <div
        class="chart_row_two text-white pb-6"
        style="margin-top: 30px; margin-bottom: 100px !important"
      >
        <!-- 1st chart -->
        <div class="p-4 d-flex flex-column chart_row_two_card">
          <div
            class="w-100 d-flex align-items-center justify-content-center position-relative"
          >
            <DonutChart
              :chartData="donutchartData"
              :donutchartOptions="chartOptions"
              chartWidth="200px"
              chartHeight="200px"
              cutoutSize="80%"
              centerText=""
            />
            <div class="centered_text_donought_chart">
              <p class="p-0 m-0 text-center" style="font-size: 40px">412</p>
              <p
                class="p-0 m-0 text-center"
                style="font-weight: 600; margin-top: -13px !important"
              >
                Total Tasks
              </p>
            </div>
          </div>

          <div
            class="d-flex flex-grow-1 align-items-center justify-content-center"
            style="height: 190px"
          >
            <div class="w-50" style="border-right: 1px solid #80808071">
              <p
                class="text-center p-0 m-0"
                style="font-size: 30px; font-family: 600"
              >
                00
              </p>
              <p
                class="text-center"
                style="
                  color: greenyellow !important;
                  font-weight: 600;
                  margin-top: -10px !important;
                "
              >
                Active Tasks
              </p>
            </div>

            <div class="w-50">
              <p
                class="text-center p-0 m-0"
                style="font-size: 30px; font-family: 600"
              >
                139
              </p>
              <p
                class="text-center"
                style="
                  color: yellow !important;
                  font-weight: 600;
                  margin-top: -10px !important;
                "
              >
                Pending Tasks
              </p>
            </div>
          </div>
        </div>
        <!-- middle chart -->
        <div class="p-4 d-flex flex-column chart_row_two_card">
          <div>
            <p class="text-center p-0 m-0" style="font-size: 50px">
              23.6
              <span class="p-0 m-0" style="font-size: 30px">ms</span>
            </p>
            <p
              class="text-center"
              style="
                color: yellow;
                font-weight: 500;
                margin-top: -10px !important;
              "
            >
              Task Wait Time
            </p>

            <div class="d-flex justify-content-center">
              <button class="statBtn">32%</button>
            </div>
          </div>

          <div
            class="d-flex flex-grow-1 align-items-center justify-content-center"
          >
            <gradient-graph-chart
              id="chart-line4"
              title="Chart"
              :chart="chartData"
            />
          </div>
        </div>

        <!-- third chart -->
        <div class="p-4 d-flex flex-column chart_row_two_card">
          <div>
            <p class="text-center p-0 m-0" style="font-size: 50px">12%</p>
            <p
              class="text-center"
              style="color: red; font-weight: 600; margin-top: -10px !important"
            >
              Task Failure Rate
            </p>

            <div class="d-flex justify-content-center">
              <button class="statBtn bg-danger">17%</button>
            </div>
          </div>

          <div
            class="d-flex flex-grow-1 align-items-center justify-content-center"
          >
            <gradient-graph-chart
              id="chart-line5"
              title="Chart"
              :chart="chartData"
            />
          </div>
        </div>
      </div>
    </div>


    <div class="col-6 text-end" v-if="logs_popup">
            <div class="popup_bg"></div>
            <div id="myModal" class="modal_class">
              <div class="modal-content invoice">
                <div class="d-flex justify-content-between">
                  <span>Deactivation Logs</span>
                  <span @click="logs_popup_close" class="close">&times;</span>
                </div>

                <div
                  class="hover-popup my-3"
                  style="height: 500px; overflow-y: auto; scrollbar-width: thin"
                >
                  <div class="popup-content">
                    <div class="active_services mt-2" v-if="Object.keys(rtl_Epon_data).length">
                      <div v-for="item in rtl_Epon_data?.upldown_logs" :key="item">
                        <div class="d-flex align-items-center">
                          <div
                            class="rounded"
                            style="height: 50px; width: 50px; background-color: dimgrey"
                          ></div>
                          <div class="p-2">
                            <p class="m-0 text-left">{{item.status}}</p>
                            <p
                              class="m-0 text-left"
                              style="font-size: 14px; color: greenyellow !important"
                            >
                              • Date ({{item.date}})
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="active_services mt-2" v-if="Object.keys(rtl_data).length">
                      <div v-for="item in rtl_data?.Deactivation_Logs" :key="item">
                        <div class="d-flex align-items-center">
                          <div
                            class="rounded"
                            style="height: 50px; width: 50px; background-color: dimgrey"
                          ></div>
                          <div class="p-2">
                            <p class="m-0 text-left">{{item.Deactive_Reason}}</p>
                            <p
                              class="m-0 text-left"
                              style="font-size: 14px; color: greenyellow !important"
                            >
                              • Active Time ({{item.Active_Time}})
                            </p>
                            <p
                              class="m-0 text-left"
                              style="font-size: 14px; color: red !important"
                            >
                              • Deactive Time ({{item.Deactive_Time}})
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    


    <div class="col-6 text-end" v-if="multiple_list_popup">
  <div class="popup_bg"></div>
  <div id="myModal" class="modal_class">
    <div class="modal-content invoice">
      <div class="d-flex justify-content-between">
        <span>Duplicate PPPOE ID Detect</span>
        <span @click="logs_popup_close" class="close">&times;</span>
      </div>

      <div
        class="hover-popup my-3"
        style="height: 300px; overflow-y: auto; scrollbar-width: thin"
      >
        <div class="popup-content">
          <table class="table ">
            <thead class="table-bordered">
              <tr>                
                <th>Actions</th>
                <th> ID</th>
                <th>PPOE ID</th>
                <th>Port Number</th>
                <th>Port Name</th>
                <th>Port Type</th>
                <th>Last Update</th>
              </tr>
            </thead>
            <tbody class="table-bordered">
              <tr v-for="item in multiple_data.data" :key="item.id">
                <td>
                  <i @click="deletePopupPppoe(item.id)" class="fas fa-trash-alt cursor-pointer"
        style="color: red; font-size: 12px"></i>
                </td>
                <td>{{ item.id }}</td>
                <td>{{ item.ppoe_id }}</td>
                <td>{{ item.port_number }}</td>
                <td>{{ item.port_number_name }}</td>
                <td>{{ item.port_type }}</td>
                <td>{{ item.last_update  }}</td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>



 <div class="col-6 text-end" v-if="delete_popup_pppoe">
      <div class="popup_bg"></div>
      <div id="myModal" class="modal_class">
        <div class="modal-content invoice p-4 shadow-lg rounded">
          <div class="d-flex justify-content-center align-items-center mb-3">
            <h5 class="text-center m-0">Are you sure?</h5>
          </div>
          <div class="text-center">
            <button @click="deleteItemPPPOE" class="btn btn-danger w-40 mx-1">
              Yes, delete it!
            </button>
            <button @click="popup_close" class="btn btn-success w-40 mx-1">
              No, keep it
            </button>
          </div>
        </div>
      </div>
    </div>











  </div>
</template>
<script>
import GradientGraphChart from "@/examples/Charts/GradientGraphChart.vue";
import DonutChart from "@/examples/Charts/DonutChart.vue";
// import getPPOEGrapgData from "../services/pppoe-graph-data.service";
import getLogsDateList from "../services/get-logs-date";
// import sequreRtData from "../services/sequre-rt-data.service";
import getStatusData from "../services/status.service";
// import getReloadServer from "../services/realod-server.service";
import getRefreshServer from "../services/refresh-server.service";
import Skalaton from "@/components/Skalaton.vue";
import Skalatonloader from "@/components/Skalatonloader.vue";
import getSingleUserDb from "../services/get-single-user-db.service";
import getSharePortCount from "../services/share-port-count";
import showSwal from "/src/mixins/showSwal.js";
import deleteData from "../services/delete-data.service";
import updatesData from "../services/update-data.service";
import { useRtlApi } from "../services/rtl-olt.service";  

export default {
  components: {
    GradientGraphChart,
    Skalaton,
    Skalatonloader,
    DonutChart,
  },

  data() {
    return {
      donutchartData: {
        datasets: [
          {
            label: "My Dataset",
            data: [0, 0, 100],
            backgroundColor: ["#adff2f", "#ffff00"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      },
      donutchartDataMiddle: {
        datasets: [
          {
            label: "My Dataset",
            data: [0, 0, 100],
            backgroundColor: ["#adff2f", "#ffff00"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      },
      donutchartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            enabled: true,
          },
        },
      },
      active: 1,
      description_data: [],
      single_user_db_data: [],
      share_port_data: [],
      dashoard_chart_data: [],
      // sequre_rt_data: [],
      multiple_data:[],
      status: "",
      Temperature_onu: "",
      vendor: null,
      olt_connect__olt_name: "",
      last_update: null,
      description: null,
      last_dereg_reason: null,
      alive_time: null,
      port_number: null,
      id: null,
      olt_connect__oltid: "",
      chartData: null,
      seleced_index: "All",
      loader: true,
      loading: false,
      epon_popup: false,
      loadernew: false,
      refresh_loader: false,
      logs_popup: false,
      pppoe: this.$route.params.pppoe,
      delete_popup: false,
      delete_popup_pppoe: false,
      delete_id_pppoe: "",
      multiple_list_popup: false,
      ppp_active_sessions: [],
      total_rx: null,
      total_tx: null,
      delete_id: "",
      formData: {
        PPPOE_ID: "",
        port_number: "",
      },
      onu_status:false,
      rtl_data:[],
      rtl_Epon_data:[],
    };
  },

  


  async mounted() {
    this.getCurrentDateData();
    this.getPPOEGrapg();  
    this.getSingleUserDbData();

     
  },

  watch: {
    "$route.params.pppoe": function (newVal) {
      this.pppoe = newVal;
      window.location.reload();
    },

    rtl_data: {
    handler(newData) {
      this.donutchartData.datasets[0].data[1] = Array.isArray(newData.Deactivation_Logs)
        ? newData.Deactivation_Logs.length
        : 0;

      this.donutchartData.datasets[0].data[2] = 100 - (Array.isArray(newData.Deactivation_Logs)
        ? newData.Deactivation_Logs.length
        : 0);
    },
    deep: true, 
    },

    rtl_Epon_data: {
    handler(newData) {
      this.donutchartData.datasets[0].data[1] = Array.isArray(newData?.upldown_logs)
        ? newData?.upldown_logs.length
        : 0;

      this.donutchartData.datasets[0].data[2] = 100 - (Array.isArray(newData?.upldown_logs)
        ? newData?.upldown_logs.length
        : 0);

      this.donutchartDataMiddle.datasets[0].data[1] = newData?.optical_diagnose?.data?.receive_power.split("dBm")[0] ;

      this.donutchartDataMiddle.datasets[0].data[2] = 100 - newData?.optical_diagnose?.data?.receive_power.split("dBm")[0];
        
    },
    deep: true, 
  },
  },

  


  methods: {

    
async deletePopupPppoe(id) {
      this.delete_id_pppoe = id;
      this.delete_popup_pppoe = true;
    },

    async deleteItemPPPOE() {
      this.loading = true;
      try {
        await deleteData.deleteData(this.delete_id_pppoe);

        showSwal.methods.showSwal({
          type: "success",
          message: "Deleted Successfully!",
        });

        this.delete_popup_pppoe = false;

        await this.getCurrentDateData();
        await this.getPPOEGrapg();  
        await this.getSingleUserDbData();

      } catch (error) {
        this.loading = false;
        console.error("Error on delete:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Failed to delete!",
        });
      }
    },

    //RtL Data
    async fetchRtlBdcomData(id,port) {
      try {
        const response = await useRtlApi().getBdcomData(id,port.split("/")[1]); 
        this.rtl_data = response;
        this.donutchartData.datasets[0].data[1] = this.rtl_data.Deactivation_Logs.length;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async fetchRtlEponData(id,port) {
      try {
        const response = await useRtlApi().getBdEponData(id,port.split("/")[1]); 
        this.rtl_Epon_data = response;
        // this.donutchartData.datasets[0].data[1] = this.rtl_data.Deactivation_Logs.length;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async checkRtlBdcomData(id) {
      try {
        const response = await useRtlApi().checkUiLogic(id); 
        if(response.olt_modes=='GPON' && response.olt_brands=='BDCOM'){
          this.onu_status = true
          this.fetchRtlBdcomData(id,this.port_number)
        }else if(response.olt_modes=='EPON' && response.olt_brands=='AVIES'){
          this.onu_status = true
          this.fetchRtlEponData(id,this.port_number)
        }else{
          showSwal.methods.showSwal({
          type: "error",
          message: "404 Not Found",
        });
        }
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },



    setActive(value) {
      
      if(this.olt_connect__oltid){
        
      this.active = value;
        this.checkRtlBdcomData(this.olt_connect__oltid);
      }else{
         showSwal.methods.showSwal({
          type: "error",
          message: "Olt Id Not Found. Please Wait!",
        });
      }
      
    },
    logs_popup_close() {
      this.logs_popup = false;
      this.multiple_list_popup = false;
    },

    async getCurrentDateData() {
      const today = new Date();
      const options = { day: '2-digit', weekday: 'short' }; 
      const todayFormatted = today.toLocaleDateString('en-US', options).replace(' ', ', ');   

      
        const filteredData = this.dashoard_chart_data?.filter(
          (item) => item.date == todayFormatted
        );

        console.log("Filtered Data:", JSON.stringify(filteredData, null, 2));

      this.filer(todayFormatted,null)
      
      this.loadernew = true;
    },

    async filer(filterDate, index) {
      this.chartData = null;
      if (filterDate == "All") {
        this.getPPOEGrapg();
        this.seleced_index = index
      } else {
        this.dashoard_chart_data = await getLogsDateList.getLogsDateList(
          this.pppoe
        );
        const filteredData = this.dashoard_chart_data?.filter(
          (item) => item.date === filterDate
        );

        //Mached Index
        this.seleced_index = this.dashoard_chart_data?.findIndex(
          (item) => item.date === filterDate
        );

        // Flatten the nested structure to extract data
        this.description_data = filteredData?.flatMap((dateObj) =>
          dateObj.data.map((item) => Number(item.description))
        );

        // Prepare chart data
        this.chartData = {
          labels: filteredData?.flatMap((dateObj) =>
            dateObj.data.map(
              (item) => `${item.last_update_formatted} (${item.description})`
            )
          ),
          datasets: [
            {
              label: "-dBm",
              data: this.description_data,
            },
          ],
        };
      }
    },

    async submitForm() {
      this.loading = true;
      try {
        await updatesData.updatesData(this.formData, this.single_data_id);
        await this.getData();
        this.popup_close();

        showSwal.methods.showSwal({
          type: "success",
          message: "Updated Successfully!",
        });
      } catch (error) {
        this.loading = false;
        console.error("Error on update:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Failed to Create!",
        });
      }
    },

    async deletePopup(id) {
      this.delete_id = id;
      this.delete_popup = true;
    },

    async deleteItem() {
      this.loading = true;
      try {
        await deleteData.deleteData(this.delete_id);
        showSwal.methods.showSwal({
          type: "success",
          message: "Deleted Successfully!",
        });
        this.delete_popup = false;
        this.getPPOEGrapg();
        this.getSingleUserDbData();
      } catch (error) {
        this.loading = false;
        console.error("Error on delete:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Failed to delete!",
        });
      }
    },

    popup_close() {
      this.delete_popup_pppoe = false;
      this.delete_popup = false;
      this.epon_popup = false;
      this.formData.PPPOE_ID = "";
      this.formData.port_number = "";
    },
    setEdit() {
      this.epon_popup = true;
      this.single_data_id = this.id;
      this.formData.PPPOE_ID = this.single_user_db_data.nowdb?.PPPOE_ID;
      this.formData.port_number = this.port_number;
    },

    async getPPOEGrapg() {
      try {
        // this.dashoard_chart_data = await getPPOEGrapgData.getPPOEGrapgData(this.pppoe);
        this.dashoard_chart_data = await getLogsDateList.getLogsDateList(
          this.pppoe
        );

        /* this.description_data = this.dashoard_chart_data?.map(item => Number(item.description));

        // Prepare chart data
        this.chartData = {
          labels: this.dashoard_chart_data?.map(item => `${item.last_update_formatted}    (${item.description})`),

          datasets: [
            {
              label: '-dBm',
              data: this.description_data,
            },
          ],
        }; */

        // Flatten the nested structure to extract data
        this.description_data = this.dashoard_chart_data?.flatMap((dateObj) =>
          dateObj.data.map((item) => Number(item.description))
        );

        // Prepare chart data
        this.chartData = {
          labels: this.dashoard_chart_data?.flatMap((dateObj) =>
            dateObj.data.map(
              (item) => `${item.last_update_formatted} (${item.description})`
            )
          ),
          datasets: [
            {
              label: "-dBm",
              data: this.description_data,
            },
          ],
        };

        const response = await getStatusData.getStatusData(this.pppoe);
        this.status = response.status_is;
        this.olt_connect__oltid = response.olt_connect__oltid;  
        this.Temperature_onu = response.Temperature_onu;
        this.last_dereg_reason = response.last_dereg_reason;
        this.alive_time = response.alive_time;
        this.port_number = response.port_number;
        this.vendor = response.vendor;
        this.olt_connect__olt_name = response.olt_connect__olt_name;
        this.description = response.description;
        this.last_update = response.last_update;
        this.id = response.id;
        this.ppp_active_sessions = response.ppp_active_sessions;
        this.total_rx = response.total_rx;
        this.total_tx = response.total_tx;

        /* try {
          this.sequre_rt_data = await sequreRtData.sequreRtData(
            response.olt_connect__oltid,
            response.id
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        } */
      } catch (error) {
        console.error("Error fetching dashboard chart data:", error);
      }
    },

    progressBarClass(port_count) {
      if (port_count >= 120) {
        return "bg-danger"; // red
      } else if (port_count >= 100) {
        return "bg-warning"; // medium
      } else {
        return "bg-success"; // normal
      }
    },
    progressBarClassEpon(port_count) {
      if (port_count >= 60) {
        return "bg-danger"; // red
      } else if (port_count >= 50) {
        return "bg-warning"; // medium
      } else {
        return "bg-success"; // normal
      }
    },
    async getSingleUserDbData() {
      try {
        this.single_user_db_data = await getSingleUserDb.getSingleUserDb(
          this.pppoe
        );
        const response = await getSharePortCount.getSharePortCount(
          this.pppoe
        );

        this.share_port_data = response;
        
        /* if(response.error){
          alert('error')
        } */
      } catch (error) {
          try {
            const response = await useRtlApi().getMultiplePPPOE(this.pppoe); 
            this.multiple_data = response;
            this.multiple_list_popup = true
          } catch (error2) {
            console.error("Error fetching data:", error2);
          }
        console.error("Error fetching data:", error);
      }
    },
    async refresh() {
      try {
        this.refresh_loader = true;
        const response = await getRefreshServer.getRefreshServer(
          this.olt_connect__oltid,
          this.port_number.split("/")[1]
        );
        if (response.status == "success") {
          showSwal.methods.showSwal({
            type: "success",
            message: response.status,
          });
        } else {
          showSwal.methods.showSwal({
            type: "error",
            message: response.status,
          });
        }

        await this.getPPOEGrapg();
        await this.getSingleUserDbData();

        this.refresh_loader = false;
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Refresh Failed!",
        });
        this.refresh_loader = false;
        console.error("Error fetching data:", error);
      }
    },
    /* async reload() {
      try {
        this.loader = false;
        let reload_data = await getReloadServer.getReloadServer(
          this.olt_connect__oltid
        );

        this.getPPOEGrapg();
        this.getSingleUserDbData();

        this.loader = true;

        console.log(reload_data);
      } catch (error) {
        this.getPPOEGrapg();
        this.getSingleUserDbData();
        this.loader = true;
        console.error("Error fetching data:", error);
      }
    }, */
  },
};
</script>
<style scoped>
.calendar {
  display: flex;
  justify-content: start;
  padding: 10px;
  background-color: #020024;
}
.calendar-item {
  width: 56px;
    height: 59px;
    background-color: #444;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 4px;
    font-size: 14px;
    margin-right: 24px !important;

}
.calendar-item.selected {
  background-color: #98ffcc;
  color: black;
}
.calendar-day {
  font-size: 12px;
}
.calendar-date {
  font-size: 20px;
  font-weight: bold;
}
</style>
