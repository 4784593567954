import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Tables from "@/views/Tables.vue";
import Billing from "@/views/Billing.vue";
import Log from "@/views/Log.vue";
import LiveLog from "@/views/LiveLog.vue";
import SwitchList from "@/views/SwitchList.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import ProfileTemplate from "../views/ProfileTemplate.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import SendEmail from "../views/SendEmail.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Profile from "@/views/Profile.vue";
import Graph from "@/views/Graph.vue";
import SwitchGraph from "@/views/SwitchGraph.vue";
import Users from "../views/Users.vue";
import OltDataPort from "../views/OltDataPort.vue";
import OltPort from "../views/OltPort.vue";
import OltList from "../views/OltList.vue";
import Share from "../views/Share.vue";
import AddOlt from "../views/AddOlt.vue";
import EditOlt from "../views/EditOlt.vue";
import ShareOlt from "../views/ShareOlt.vue";
import store from "/src/store";
import guest from "../middlewares/guest.js";
import auth from "../middlewares/auth.js";
import OltDetails from "../views/OltDetails.vue";

function middlewarePipeline(context, middleware, index) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);
    nextMiddleware({ ...context, next: nextPipeline });
  };
}

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/dashboard",
  },
  {
    path: "/dashboardx",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/package-list",
    name: "Package List",
    component: Billing,
  },
  {
    path: "/log-list",
    name: "Log",
    component: Log,
  },
  {
    path: "/share-olt",
    name: "Share",
    component: Share,
  },
  {
    path: "/live-log-list",
    name: "LiveLog",
    component: LiveLog,
  },
  {
    path: "/switch-list",
    name: "SwitchList",
    component: SwitchList,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/profileTemplate",
    name: "ProfileTemplate",
    component: ProfileTemplate,
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/graph/:pppoe",
    name: "Graph",
    component: Graph,
  },
  {
    path: "/switch-graph/:id/:olt?",
    name: "SwitchGraph",
    component: SwitchGraph,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/password/email",
    name: "SendEmail",
    component: SendEmail,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/password/reset",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/olt-data-ports",
    name: "OltDataPort",
    component: OltDataPort,
  },
  {
    path: "/olt-ports",
    name: "OltPort",
    component: OltPort,
  },
  {
    path: "/dashboard",
    name: "OltList",
    component: OltList,
  },
  {
    path: "/olt-list/:id",
    name: "OltDetails",
    component: OltDetails,
  },
  {
    path: "/add-device",
    name: "AddOlt",
    component: AddOlt,
  },
  {
    path: "/edit-device/:edit_id",
    name: "EditOlt",
    component: EditOlt,
  },
  {
    path: "/share-olt/:edit_id",
    name: "ShareOlt",
    component: ShareOlt,
  },
  {
    path:"/:pathMatch(.*)*",
    name: "NotFound",
    component: Dashboard, // or a NotFound component
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            resolve({
              el: to.hash,
              behavior: "smooth",
            });
          } else {
            resolve({ top: 0 });
          }
        }, 2000); // Add a short delay to ensure the element is rendered
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  linkActiveClass: "active",
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  const context = {
    to,
    from,
    next,
    store,
  };

  if (!to.meta.middleware) {
    return auth({ ...context, next });
  }

  const middleware = to.meta.middleware;
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
