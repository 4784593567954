import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async confirmOtp(number,otp) {
  
      try {
        const response = await axios.post(API_URL+"/api/v1/confirm-otp/", {
            "mobile_number":`${number}`,
            "otp_code":`${otp}`
        }
        );
        return response.data;
      } catch (error) {
        console.error("Error retrieving user:", error);
        throw error; 
      }
    },

    
  };
