<template>
  <nav v-bind="$attrs" id="navbarBlur" data-scroll="true">
    <div class="nav">
      <!-- search -->
      <div>
        <p class="search m-0" style="font-size: 14px" @click="showPopup = true">
          <SearchIcon style="opacity: 0.6" class="search-icon" />
          <span> Search</span>
          <K style="opacity: 0.6" class="search-k" />
        </p>
        <Search :isVisible="showPopup" @close-popup="showPopup = false" />
      </div>
      <!-- Icon Items -->
      <div class="items">
        <span @click="toggleDarkMode" class="item" v-if="!darkMode">
          <Sun />
        </span>

        <span @click="toggleDarkMode" class="item" v-else>
          <Moon />
        </span>

        <!-- tab -->
        <li class="nav-item dropdown d-flex align-items-center">
          <a
            href="#"
            class="item"
            :class="[
              darkMode ? 'text-white' : 'text-black',
              showMenu ? 'show' : '',
            ]"
            id="dropdownMenuButton2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="showMenu2 = !showMenu2"
          >
            <Star />
          </a>

          <ul
            class="px-3 py-3 dropdown-menu shortcuts dropdown-menu-end me-sm-n4 fixed-width-dropdown"
            :class="[
              darkMode ? 'bg-dark' : 'bg-white',
              showMenu2 ? 'show' : '',
            ]"
            aria-labelledby="dropdownMenuButton"
          >
            <li class="mb-2">
              <div>
                <div
                  class="d-flex justify-content-between mb-3 margin-bottom-1px"
                >
                  <h6 class="text-[#041E3E]">Shortcuts</h6>
                </div>

                <div class="shortcut-panel light-mode">
                  <!-- Invoice Shortcut -->
                  <div class="shortcut-item">
                    <i class="fas fa-file-invoice shortcut-icon"></i>
                    <router-link
                      :to="{ path: '/dashboard', hash: '#invoice' }"
                      class="shortcut-title"
                    >
                      Invoice
                    </router-link>
                  </div>

                  <!-- ID Shortcut -->
                  <div class="shortcut-item" v-if="fendy_user_id">
                    <i class="fas fa-users shortcut-icon"></i>
                    <div class="shortcut-title" @click="copyToken">
                      ID: {{ fendy_user_id }}
                    </div>
                  </div>

                  <!-- Support Shortcut -->
                  <div class="shortcut-item" @click="toggleConfigurator">
                    <i class="fas fa-cogs shortcut-icon"></i>
                    <div class="shortcut-title">Support</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>

        <!-------------- notification------- -->
        <li class="nav-item dropdown d-flex align-items-center">
          <a
            href="#"
            class="item"
            :class="[
              darkMode ? 'text-white' : 'text-black',
              showMenu ? 'show' : '',
            ]"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="showMenu = !showMenu"
          >
            <Notification />
          </a>
          <ul
            class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
            :class="[darkMode ? 'bg-dark' : 'bg-white', showMenu ? 'show' : '']"
            aria-labelledby="dropdownMenuButton"
          >
            <li class="">
              <a class="dropdown-item border-radius-md" href="javascript:;">
                <div class="py-1 d-flex">
                  <div class="my-auto">
                    <img
                      src="../../assets/img/team-2.jpg"
                      class="avatar avatar-sm me-3"
                      alt="user image"
                    />
                  </div>

                  <div
                    class="d-flex flex-column justify-content-center"
                    style=""
                  >
                    <h6 class="mb-1 text-sm font-weight-normal">
                      <span class="font-weight-bold">New message</span>
                      <span> from Laur</span>
                    </h6>
                    <p class="mb-0 text-xs text-secondary">
                      <i class="fa fa-clock me-1"></i>
                      13 minutes ago
                    </p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </li>

        <span class="profile-pic"></span>
      </div>
    </div>
  </nav>
</template>
<script>
// import Breadcrumbs from "../Breadcrumbs.vue";
// import getUniquePPOE from "@/services/unique_pppoe_ids.service";
import { mapMutations, mapActions } from "vuex";
import getProfileCheck from "@/services/check-profile.service";
import showSwal from "/src/mixins/showSwal.js";
import Search from "@/components/Search.vue";

import Moon from "../../components/Icon/Moon.vue";
import Star from "../../components/Icon/Star.vue";
import Notification from "../../components/Icon/Notification.vue";

import Sun from "../../components/Icon/Sun.vue";
import SearchIcon from "../../components/Icon/Search.vue";
import K from "../../components/Icon/K.vue";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      showMenu2: false,
      darkMode: false,
      showPopup: false,

      fendy_user_id: "",
    };
  },
  components: {
    Moon,
    Star,
    Notification,
    Search,
    SearchIcon,
    K,
    Sun,
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      if (this.darkMode == false) {
        this.toggleSidebarColor("bg-white");
      } else {
        this.toggleSidebarColor("bg-dark");
      }
      this.navbarMinimize();
    },

    copyToken() {
      const token = this.fendy_user_id;

      try {
        // Fallback: Create a temporary textarea for copying
        const textArea = document.createElement("textarea");
        textArea.value = token;
        document.body.appendChild(textArea);

        // Select the token text
        textArea.select();
        document.execCommand("copy"); // This still works in non-HTTPS environments

        // Remove the textarea from the DOM
        document.body.removeChild(textArea);

        // Show success message
        showSwal.methods.showSwal({
          type: "success",
          message: "Copy Successfully",
        });
      } catch (err) {
        // Handle copy failure
        console.error("Failed to copy token: ", err);
        showSwal.methods.showSwal({
          type: "error",
          message: "Failed to copy. Please try manually.",
        });
      }
    },

    async getCheckProfile() {
      try {
        let response = await getProfileCheck.getProfileCheck();
        this.fendy_user_id = response.fendy_user_id;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      localStorage.setItem("darkMode", JSON.stringify(this.darkMode));
      window.location.reload();
    },

    async logoutUser() {
      await this.$store.dispatch("auth/logout");
    },
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
  },
  mounted() {
    // this.getData();
    this.getCheckProfile();
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode !== null) {
      this.darkMode = JSON.parse(savedDarkMode); // Update dark mode state
    }
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
};

// function
</script>
<style>
/* Dark mode styles */
.dark-mode .list-group-item {
  background-color: #041e3e; /* Dark background color */
  color: #ffffff; /* Light text color for better visibility */
}
.dark-mode-suggestions {
  width: 100%;
}

.fixed-width-dropdown {
  width: 400px; /* Adjust width as needed */
}

/* Style for the shortcut cards */
.shortcut-card {
  text-align: center;
  padding: 15px;
  border: 1px solid rgb(202, 69, 69) !important;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
}

.shortcut-card:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.shortcut-icon {
  font-size: 1.5rem;
  color: #6c757d;
  margin-bottom: 5px;
}

.shortcut-title {
  font-weight: 600;
  margin-bottom: 3px;
}

.shortcut-subtitle {
  font-size: 0.9rem;
  color: #6c757d;
}

/* ########################### */

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center !important;

  border-radius: 15px !important;
}

.searchDiv {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.search {
  position: relative;
  padding: 5px;
  padding-left: 35px;
  padding-right: 35px;
  border: 1px solid rgba(128, 128, 128, 0.144);
  border-radius: 10px;
  width: 170px;
  cursor: pointer !important;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  color: black;
  transform: translateY(-50%); /* Center vertically */
}
.dark-mode .search-icon {
  color: rgb(204, 201, 201);
}
.dark-mode .search-k {
  color: rgb(204, 201, 201);
}
.dark-mode .search-input {
  color: white;
}

.search-input {
  background: none;
  border: none;
  color: black;
  outline: none;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 14px;
  cursor: pointer;
}

.search-k {
  position: absolute;
  right: 10px;
  top: 50%;
  color: black;
  transform: translateY(-50%); /* Center vertically */
}

/* ####### right itemms ##### */

.items {
  display: flex;
  align-items: center;
  gap: 5px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100%;
}

.dark-mode .item {
  color: white !important;
}

.dark-mode .item:hover {
  background-color: #fafafa18 !important;
}

.item:hover {
  background-color: rgba(41, 25, 4, 0.11);
  transition: all;
  transition-duration: 500ms;
}

.profile-pic {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgb(0, 195, 255);
}

.dark-mode .dropdown-item:hover {
  color: black !important;
  background-color: #041e3e71;
}
/* Common Styles */
.shortcut-panel {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 0px !important;

  margin: auto;
  border-radius: 8px;
}

.shortcut-item {
  flex: 1 1 calc(50% - 6px);
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  border: 1px solid;
}

.shortcut-item:hover {
  transform: translateY(-4px);
}

.shortcut-icon {
  font-size: 24px; /* Smaller icon size */
  margin-bottom: 4px; /* Reduced margin */
}

.shortcut-title {
  font-size: 13px; /* Slightly smaller font */
  text-decoration: none;
  cursor: pointer;
  word-break: break-word;
}

/* Light Mode Styles */
.light-mode {
  /* background-color: #f9fafc; */
}

.light-mode .shortcut-item {
  background-color: #ffffff;
  border-color: #ddd; /* Light border color for cards */
}

.light-mode .shortcut-item:hover {
  background-color: #f1f5f9;
}

.light-mode .shortcut-icon {
  color: #374151;
}

.light-mode .shortcut-title {
  color: #111827;
}

.light-mode .shortcut-title:hover {
  color: #1d4ed8;
}

/* Dark Mode Styles */
.dark-mode {
  background-color: #1e2843;
  border: 1px solid #3a475e; /* Dim border color for container */
}

.dark-mode .shortcut-panel {
  padding: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dark-mode .shortcut-item {
  background-color: #273657;
  border-color: #3a475e; /* Dim border color for cards */
}

.dark-mode .shortcut-item:hover {
  background-color: #344a72;
}

.dark-mode .shortcut-icon {
  color: #ffffffaa;
}

.dark-mode .shortcut-title {
  color: white;
}

.dark-mode .shortcut-title:hover {
  color: #50b3a2;
}

/* Responsive Styles */
@media screen and (max-width: 576px) {
  .shortcut-panel {
    flex-direction: column;
    gap: 8px;
  }

  .shortcut-item {
    flex: 1 1 100%;
  }
}
</style>
