<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 36 36"
  >
    <path
      fill="currentColor"
      d="M18 34.28A2.67 2.67 0 0 0 20.58 32h-5.26A2.67 2.67 0 0 0 18 34.28"
      class="clr-i-outline--badged clr-i-outline-path-1--badged"
    />
    <path
      fill="currentColor"
      d="M32.51 27.83A14.4 14.4 0 0 1 30 24.9a12.6 12.6 0 0 1-1.35-4.81v-4.94a11 11 0 0 0-.16-1.79a7.4 7.4 0 0 1-2.24-.84a8.9 8.9 0 0 1 .4 2.64v4.94a14.2 14.2 0 0 0 1.65 5.85a16.2 16.2 0 0 0 2.44 3H5.13a16.2 16.2 0 0 0 2.44-3a14.2 14.2 0 0 0 1.65-5.85v-4.95A8.8 8.8 0 0 1 18 6.31a8.6 8.6 0 0 1 4.76 1.44A7.5 7.5 0 0 1 22.5 6v-.63a10.6 10.6 0 0 0-3.32-1V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.3 10.73v4.94a12.6 12.6 0 0 1-1.35 4.81a14.4 14.4 0 0 1-2.47 2.93a1 1 0 0 0-.34.75v1.36a1 1 0 0 0 1 1h27.8a1 1 0 0 0 1-1v-1.36a1 1 0 0 0-.34-.75"
      class="clr-i-outline--badged clr-i-outline-path-2--badged"
    />
    <circle
      cx="30"
      cy="6"
      r="5"
      fill="currentColor"
      class="clr-i-outline--badged clr-i-outline-path-1--badged clr-i-badge"
    />
    <path fill="none" d="M0 0h36v36H0z" />
  </svg>
</template>
