import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async getSingleUserDb(pppoe) {
  
      try {
        const response = await axios.get(API_URL+"/filter_by_pppoe_id/api/", {
          headers: {
            'X-Custom-Header': pppoe
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error retrieving user:", error);
        throw error; 
      }
    },
  };
