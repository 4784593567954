<template>
  <div v-if="isVisible" class="popup-container">
    <div class="popup_bg"></div>
    <div id="myModal" class="modal_class">
      <!-- ============= modal======== -->
      <div
        class="modal-content invoice"
        style="min-height: 300px !important; opacity: 1 !important"
      >
        <div class="d-flex justify-content-between align-items-center p-0">
          <!-- searchtype select selection dropdown and input -->
          <div class="d-flex p-0 align-items-center gap-3 w-100">
            <!-- Search Input -->
            <div class="position-relative w-80" style="max-width: 500px">
              <i
                class="fas fa-search position-absolute"
                style="
                  left: 20px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: gray;
                "
              ></i>

              <div style="display: flex; align-items: center; gap: 10px;">
                <input
                  v-if="typedata == 'realtime'"
                  type="text"
                  @keyup.enter="fetchData()"
                  v-model="search_field_realtime"
                  placeholder="Search..."
                  class="form-control input-text ps-5 bg-transparent text-dark input-border"
                  style="box-shadow: none; height: 42px; flex: 1;"
                />
                <button
                  v-if="typedata == 'realtime'"
                  @click="fetchData"
                  class="bg-success btn text-white rounded py-0 mt-3"
                  style="height: 42px; padding: 0 20px;"
                >
                  Submit
                </button>

                <input
                  v-if="typedata == 'pppoe'"
                  type="text"
                  @keyup.enter="graph(search_field)"
                  @input="filter_suggestions"
                  v-model="search_field"
                  placeholder="Search..."
                  class="form-control input-text ps-5 bg-transparent text-dark input-border"
                  style="box-shadow: none; height: 42px; flex: 1;"
                />
              </div>


              <!-- PPPOE Search Suggetation -->
              <div
                v-if="search_field"
                class="suggestions position-absolute dark-mode-suggestions suggestions-box custom-scroll"
                style="
                  z-index: 100000 !important;
                  height: 180px !important;
                  overflow-y: auto;
                  top: 120% !important;
                "
              >
                <ul class="suggestion-list">
                  <li
                    class="suggestion-item"
                    style="border: 1px solid transparent !important"
                    v-for="(data, index) in suggestions"
                    :key="index"
                  >
                    <p class="suggestion-text" @click="graph(data.PPPOE_ID)">
                      {{ data.PPPOE_ID }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <!-- Search type selection dropdown================n -->
            <div class="" style="width: 120px">
              <select
                v-model="typedata"
                @change="resetSearch"
                class="form-select input-text bg-transparent text-center input-border select"
                style="box-shadow: none; height: 42px"
              >
                <option value="" disabled selected hidden>Select</option>
                <option value="pppoe" class="input-text-select">PPPOE</option>
                <option value="realtime">Mac Find</option>
              </select>
            </div>
          </div>

          <!-- =============  close btn =========== -->
          <span class="">
            <span @click="$emit('close-popup')" class="close item"
              >&times;</span
            >
          </span>
        </div>

        <div
          v-if="searchData?.data"
          class="custom-scroll"
          style="
            max-height: 300px !important;
            overflow-y: auto;
            overflow-x: hidden !important;
          "
        >
          <div class="row g-2" v-if="searchData?.source == 'bdcom' && searchData?.data?.data[0]?.OltModes=='EPON'">
            <div class="col-md-6 px-3">
              <h6 class="text-[#041E3E] pt-3">OLT Information</h6>
              <p class="text-sm">Vlan: {{ searchData?.data?.data[0]?.Vlan }}</p>
              <p class="text-sm">Type: {{ searchData?.data?.data[0]?.Type }}</p>
              <p class="text-sm">
                Ports: {{ searchData?.data?.data[0]?.Ports }}
              </p>
              <p class="text-sm">
                Olt ID: {{ searchData?.data?.data[0]?.OltID }}
              </p>
              <p class="text-sm">
                Olt Brand: {{ searchData?.data?.data[0]?.OltBrand }}
              </p>
              <p class="text-sm">
                Olt Modes: {{ searchData?.data?.data[0]?.OltModes }}
              </p>
            </div>
            <div class="col-md-6 px-3">
              <h6 class="text-[#041E3E] pt-3">EPON Data</h6>
              <p class="text-sm">
                Bias Current(mA): {{ searchData?.data?.data[0]?.EPONData?.biascurrent }}
              </p>
              <p class="text-sm">
                Operating Temperature:
                {{ searchData?.data?.data[0]?.EPONData?.operatingtemperature }}
              </p>
              <p class="text-sm">
                Supply Voltage(V): {{ searchData?.data?.data[0]?.EPONData?.supplyvoltage }}
              </p>
              <p class="text-sm">
                Transmitted Power(DBm): {{ searchData?.data?.data[0]?.EPONData?.transmittedpower }}
              </p>
              <p class="text-sm">
                Received power: {{ searchData?.data?.data[0]?.EPONData?.receivedpower }}
              </p>
            </div>
          </div>
          <div class="row g-2" v-if="searchData?.source == 'bdcom' && searchData?.data?.data[0]?.OltModes=='GPON'">
            <div class="col-md-6 px-3">
              <h6 class="text-[#041E3E] pt-3">OLT Information</h6>
              <p class="text-sm">Vlan: {{ searchData?.data?.data[0]?.Vlan }}</p>
              <p class="text-sm">Type: {{ searchData?.data?.data[0]?.Type }}</p>
              <p class="text-sm">
                Ports: {{ searchData?.data?.data[0]?.Ports }}
              </p>
              <p class="text-sm">
                Olt ID: {{ searchData?.data?.data[0]?.OltID }}
              </p>
              <p class="text-sm">
                Olt Brand: {{ searchData?.data?.data[0]?.OltBrand }}
              </p>
              <p class="text-sm">
                Olt Modes: {{ searchData?.data?.data[0]?.OltModes }}
              </p>
            </div>
            <div class="col-md-6 px-3">
              <h6 class="text-[#041E3E] pt-3">GPON Data</h6>
              <p class="text-sm">
                Interface: {{ searchData?.data?.data[0]?.GPONData?.Interface }}
              </p>
              <p class="text-sm">
                Temperature:
                {{ searchData?.data?.data[0]?.GPONData?.Temperature }}
              </p>
              <p class="text-sm">
                Rx Power: {{ searchData?.data?.data[0]?.GPONData?.RxPower }}
              </p>
            </div>
          </div>
          <div class="row g-2" v-if="searchData?.source == 'vsol' && searchData?.data?.data[0]?.OltModes=='EPON'">
            <div class="col-md-6 px-3">
              <h6 class="text-[#041E3E] pt-3">OLT Information</h6>
              <p class="text-sm">Vlan: {{ searchData?.data?.data[0]?.VLAN }}</p>
              <p class="text-sm">Type: {{ searchData?.data?.data[0]?.Type }}</p>
              <p class="text-sm">
                Ports: {{ searchData?.data?.data[0]?.Port }}
              </p>
              <p class="text-sm">
                ONU ID: {{ searchData?.data?.data[0]?.ONUID }}
              </p>
              <p class="text-sm">
                Olt Brand: {{ searchData?.data?.data[0]?.OltBrand }}
              </p>
              <p class="text-sm">
                Olt Modes: {{ searchData?.data?.data[0]?.OltModes }}
              </p>
            </div>
            <div class="col-md-6 px-3">
              <h6 class="text-[#041E3E] pt-3">GPON Data</h6>
              <p class="text-sm">
                Temperature:
                {{ searchData?.data?.data[0]?.new_api_response?.Temperature }}
              </p>
              <p class="text-sm">
                Supply Voltage:
                {{ searchData?.data?.data[0]?.new_api_response?.SupplyVoltage }}
              </p>
              <p class="text-sm">
                TX Bias Current: {{ searchData?.data?.data[0]?.new_api_response?.TXBiasCurrent }}
              </p>
              <p class="text-sm">
                Rx Power: {{ searchData?.data?.data[0]?.new_api_response?.RxPower }}
              </p>
              <p class="text-sm">
                TX Power: {{ searchData?.data?.data[0]?.new_api_response?.TXPower }}
              </p>
            </div>
          </div>
          <div class="row g-2" v-if="searchData?.source == 'vsol' && searchData?.data?.data[0]?.OltModes=='GPON'">
            <div class="col-md-6 px-3">
              <h6 class="text-[#041E3E] pt-3">OLT Information</h6>
              <p class="text-sm">Vlan: {{ searchData?.data?.data[0]?.VLAN }}</p>
              <p class="text-sm">Type: {{ searchData?.data?.data[0]?.Type }}</p>
              <p class="text-sm">
                Ports: {{ searchData?.data?.data[0]?.Port }}
              </p>
              <p class="text-sm">
                ONU ID: {{ searchData?.data?.data[0]?.ONUID }}
              </p>
              <p class="text-sm">
                Olt Brand: {{ searchData?.data?.data[0]?.OltBrand }}
              </p>
              <p class="text-sm">
                Olt Modes: {{ searchData?.data?.data[0]?.OltModes }}
              </p>
            </div>
            <div class="col-md-6 px-3">
              <h6 class="text-[#041E3E] pt-3">GPON Data</h6>
              <p class="text-sm">
                Temperature:
                {{ searchData?.data?.data[0]?.new_api_response?.Temperature }}
              </p>
              <p class="text-sm">
                Laser Bias Current:
                {{ searchData?.data?.data[0]?.new_api_response?.LaserBiasCurrent }}
              </p>
              <p class="text-sm">
                Power Feed Voltage: {{ searchData?.data?.data[0]?.new_api_response?.PowerFeedVoltage }}
              </p>
              <p class="text-sm">
                Rx Optical Level: {{ searchData?.data?.data[0]?.new_api_response?.RxOpticalLevel }}
              </p>
              <p class="text-sm">
                Tx Optical Level: {{ searchData?.data?.data[0]?.new_api_response?.TxOpticalLevel }}
              </p>
            </div>
          </div>
          <div class="row g-2" v-if="searchData?.source == 'avies' && searchData?.data[0]?.olt_modes=='EPON'">
            <!-- ONU Information -->
            <div class="col-md-6 px-6" >
              <h6 class="text-[#041E3E] pt-3">ONU Information</h6>
              <p class="text-sm">OLT Id: {{ searchData?.data[0].oltid }}</p>
              <p class="text-sm">OLT Brand: {{ searchData?.data[0].olt_brands }}</p>
              <p class="text-sm">OLT Modes: {{ searchData?.data[0].olt_modes }}</p>
              <p class="text-sm">ONU Name: {{ searchData?.data[0]?.ap1_data?.data?.onu_name }}</p>
              <p class="text-sm">Model: {{ searchData?.data[0]?.ap1_data?.data?.extmodel }}</p>
              <p class="text-sm">Vendor: {{ searchData?.data[0]?.ap1_data?.data?.vendor }}</p>
            </div>

            <!-- Performance Data -->
            <div class="col-md-6 px-3">
              <h6 class="text-[#041E3E] pt-3">Performance Data</h6>
              <p class="text-sm">Temperature: {{ searchData?.data[0]?.ap2_data?.data?.work_temprature }}</p>
              <p class="text-sm">Voltage: {{ searchData?.data[0]?.ap2_data?.data?.work_voltage }}</p>
              <p class="text-sm">Transmit Bias Current: {{ searchData?.data[0]?.ap2_data?.data?.transmit_bias }}</p>
              <p class="text-sm">Transmit Power: {{ searchData?.data[0]?.ap2_data?.data?.transmit_power }}</p>
              <p class="text-sm">Receive Power: {{ searchData?.data[0]?.ap2_data?.data?.receive_power }}</p>
            </div>
          </div>

        </div>

        <div v-else>          
          <div v-if="loader_data">
            <Skalaton />
          </div>
          <div class="d-flex align-items-center justify-content-between mt-5">
            <p class="w-50">Regular Search</p>
            <p class="w-50">Realtime Search</p>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from "vue";
import { useSearchApi } from "../services/search.service";
import getUniquePPOE from "@/services/unique_pppoe_ids.service";
import { useRouter } from "vue-router";
import Skalaton from "@/components/Skalaton.vue";

const router = useRouter();

defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["close-popup"]);

const loader_data = ref(false);
const searchData = ref([]);
const typedata = ref("realtime");
const UniquePPOE_data = ref([]);
const suggestions = ref([]);
const search_field = ref("");
const search_field_realtime = ref("");

onMounted(() => {
  getData();
});

const fetchData = async () => {
  resetSearch()
  loader_data.value = true;
  try {
    let user = JSON.parse(localStorage.getItem("user_data"));
    const response = await useSearchApi().getSearch(
      user.fendy_user_id,
      search_field_realtime.value
    );
    searchData.value = response;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setTimeout(() => {
      loader_data.value = false; // Adding delay to observe spinner
    }, 1000);
  }
};


const getData = async () => {
  try {
    const response = await getUniquePPOE.getUniquePPOE();
    UniquePPOE_data.value = response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const resetSearch = () => {
  suggestions.value = []
loader_data.value = false
search_field.value = ''
// search_field_realtime.value = ''
suggestions.value=[]
searchData.value=[]
};

const graph = async (value) => {
  try {
    router.push({ name: "Graph", params: { pppoe: value } });
    search_field.value = "";
    emit("close-popup");
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const filter_suggestions = async () => {
  try {
    // getData()
    suggestions.value = UniquePPOE_data.value.filter((item) =>
      item.PPPOE_ID.includes(search_field.value)
    );
    console.log(suggestions.value)
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
</script>

<style scoped>
.modal_class {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #aaaaaa;
  opacity: 0.93;
}

.popup_bg {
  opacity: 0.5;
}

.modal-content {
  background-color: #fff;
  max-width: 800px;
  width: 100%;
  border-radius: 8px;
  opacity: 1 !important;
  overflow: hidden;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 100%;
  background-color: #2919041c;
  color: rgb(10, 0, 0) !important;
  background-color: #180f021c;
}

.dark-mode .item {
  color: white !important;
  background-color: #ffffff27;
}

.dark-mode .item:hover {
  background-color: #ffffff3a !important;
}

.item:hover {
  background-color: rgba(41, 25, 4, 0.11);
  transition: all;
  transition-duration: 500ms;
}

.input-border {
  border: 1px solid rgba(128, 128, 128, 0.233);
}
.text-sm {
  font-size: 14px !important;
}

.input-text {
  color: black !important;
}
.dark-mode .input-text {
  color: white !important;
}
.dark-mode .input-text-select {
  color: gainsboro !important;
}
.dark-mode .select {
  background-color: #041d3c !important;
}

.suggestion-item {
  cursor: pointer !important;
}
.suggestion-item:hover .suggestion-text {
  color: black !important;
  transition: all;
  transition-duration: 300ms;
}
.dark-mode .suggestion-item:hover .suggestion-text {
  color: greenyellow !important;
}

.suggestions-box {
  background-color: white !important;
}
.dark-mode .suggestions-box {
  background-color: #041d3c !important;
}
/* Custom Scrollbar Styles */
.custom-scroll::-webkit-scrollbar {
  width: 8px !important; /* Width of the scrollbar */
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #555 !important;
  border-radius: 4px !important;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #888 !important;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #9e9c9cbd !important; /* Slightly darker track */
  border-radius: 4px !important; /* Rounded corners for the track */
}
</style>
