<template>
  <div class="container-fluid py-5">
    <div class="row p-0 m-0">
      <!-- Left Side: Two stacked boxes -->
      <div
        class="col-md-3 d-flex flex-column p-0 m-0"
        style="padding-right: 30px !important; row-gap: 30px !important"
      >
        <!-- First box -->
        <div class="_card" style="flex: 1">
          <h5>OLT info</h5>
          <!-- {{data}} -->

          <p class="text-sm m-0 font-weight-bold">Id : {{data?.oltid}}</p>
          <p class="text-sm m-0 font-weight-bold">User Name : {{data?.olt_username}}</p>
          <p class="text-sm m-0 font-weight-bold">Olt Name : {{data?.olt_name}}</p>
          <p class="text-sm m-0 font-weight-bold">Ip : {{data?.olt_ip}}</p>
          <p class="text-sm m-0 font-weight-bold">Port : {{data?.olt_port}}</p>
          <p class="text-sm m-0 font-weight-bold">Modes : {{data?.olt_modes}}</p>
          <p class="text-sm m-0 font-weight-bold">Brands : {{data?.olt_brands}}</p>
          <p class="text-sm m-0 font-weight-bold">Branch : {{data?.Branch_olt}}</p>
          <p class="text-sm m-0 font-weight-bold">Billing : {{data?.olt_billing}}</p>
        </div>
        <!-- Second box -->
        <div class="_card">
          <h5>Mikrotik Info</h5>

          <p class="text-sm m-0 font-weight-bold">User Name : {{data?.m_username}}</p>
          <p class="text-sm m-0 font-weight-bold">Ip : {{data?.Mikrotik_ip}}</p>
          <p class="text-sm m-0 font-weight-bold">Port : {{data?.m_port}}</p>
        </div>
      </div>

      <!-- Right Side: Large box -->
      <div class="col-md-9 _card p-0 py-4">
        <h5 class="px-3 mb-3">OLT Summary</h5>
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th :class="tHead" class="text-sm">DB RANGE</th>
                <th :class="tHead" class="text-sm">Billing Info</th>
                <th :class="tHead" class="text-sm">DB RANGE</th>
                <th :class="tHead" class="text-sm">Billing Info</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in [1, 2, 3, 4, 5, 6, 7, 8]"
                :key="index"
              >
                <td class="text-sm">DB range is null</td>
                <td class="text-sm">Billing info is null</td>
                <td class="text-sm">No data</td>
                <td class="text-sm">No data</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataList from "../services/data_list.service";

export default {
  name: "OltDetails",
  data() {
    return {
      data: null, // State for storing a single item
    };
  },
  methods: {
    async getData() {
      try {
        const response = await dataList.getDataList();
        const id = this.$route.params.id; // Get the 'id' from route params
        alert(id)
        this.data = response.find((item) => item.id == id); // Find the item by 'id'
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>





<style scoped>
.border {
  border-width: 2px;
  border-style: solid;
}
.rounded {
  border-radius: 8px;
}

._card {
  border-radius: 10px;
  padding: 20px;
  color: rgba(0, 0, 0, 0.589);
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.dark-mode ._card {
  background-color: #061f3e;
}
</style>
