<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card invoice">
          <!-- Card header -->
          <div class="pb-0 card-header invoice">
            <div
              class="d-lg-flex justify-content-between align-items-center mx-3"
            >
              <div>
                <h5>
                  Switch List<span class="badge ml-2">{{
                    filteredData.length
                  }}</span>
                </h5>
              </div>
              <div class="d-flex gap-3">
                <div class="flex mx-1 justify-content-around">
                  <div class="d-flex grid grid-cols-2 gap-3">
                    <select
                      @change="handleOltData()"
                      v-model="selected_olt"
                      class="form-control invoice dark-filter"
                      name="filter"
                      id="filter"
                    >
                      <option value="" selected disabled hidden>
                        Sw Connect
                      </option>
                      <option value="">All</option>
                      <option
                        v-for="item in filter_port_olt"
                        :key="item.sw_connect"
                        :value="item.sw_connect"
                      >
                        {{ item.sw_connect }}
                      </option>
                    </select>
                    <select
                      @change="handlePortData(selected_port)"
                      v-model="selected_port"
                      v-if="port_olt[0]"
                      class="form-control invoice dark-filter"
                      name="filter"
                      id="filter"
                    >
                      <option value="" selected disabled hidden>PORT</option>
                      <option value="">All</option>
                      <option
                        v-for="item in port_olt[0]?.ports"
                        :key="item"
                        :value="item.port_name"
                      >
                        {{ item.port_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <input
                    type="text"
                    v-model="searchQuery"
                    class="form-control invoice"
                    placeholder="Search..."
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="px-0 pb-0 card-body invoice rounded-0">
            <div class="table-responsive" v-if="data && data.length > 0">
              <table id="users-list" class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('sw_connect')"
                      :class="{
                        asc: sortKey === 'sw_connect' && sortOrder === 'asc',
                        desc: sortKey === 'sw_connect' && sortOrder === 'desc',
                      }"
                    >
                      OLT<span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('port_name')"
                      :class="{
                        asc: sortKey === 'port_name' && sortOrder === 'asc',
                        desc: sortKey === 'port_name' && sortOrder === 'desc',
                      }"
                    >
                      Port Name <span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('status')"
                      :class="{
                        asc: sortKey === 'status' && sortOrder === 'asc',
                        desc: sortKey === 'status' && sortOrder === 'desc',
                      }"
                    >
                      STATUS <span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('TXpowerdBm')"
                      :class="{
                        asc: sortKey === 'TXpowerdBm' && sortOrder === 'asc',
                        desc: sortKey === 'TXpowerdBm' && sortOrder === 'desc',
                      }"
                    >
                      TX Power dBm <span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('RXpowerdBm')"
                      :class="{
                        asc: sortKey === 'RXpowerdBm' && sortOrder === 'asc',
                        desc: sortKey === 'RXpowerdBm' && sortOrder === 'desc',
                      }"
                    >
                      RX Power dBm <span class="sort-icon"> </span>
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      @click="sort('interface_dec')"
                      :class="{
                        asc: sortKey === 'interface_dec' && sortOrder === 'asc',
                        desc:
                          sortKey === 'interface_dec' && sortOrder === 'desc',
                      }"
                    >
                      Details <span class="sort-icon"> </span>
                    </th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <!-- Use paginatedData instead of filteredData -->
                  <tr v-for="(item, index) in paginatedData" :key="index">
                    <td class="align-middle text-center">
                      {{ item.sw_connect_name }}
                    </td>
                    <td class="align-middle text-center">
                      {{ item.port_name }}
                    </td>
                    <td class="align-middle text-center">
                      <div
                        class="badge"
                        :style="{
                          backgroundColor:
                            item.status == 'online' ? '#27c127' : '#dd2424',
                        }"
                      >
                        {{ item.status }}
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      {{ item.TXpowerdBm }}
                    </td>

                    <td class="align-middle text-center">
                      <span
                        class="position-relative badge"
                        :style="`background-color: #DFF7E9 !important;height: 30px;`"
                      >
                        <p style="color: #28c76f !important">
                          {{ item.RXpowerdBm }}
                        </p>
                        <span
                          class="position-absolute top-0 start-100 translate-middle badge rounded-pill"
                          :style="`background-color: ${item.color} !important;`"
                        >
                          {{ item.power_status }}
                        </span>
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <router-link
                        class="dark-filter"
                        :to="{
                          name: 'SwitchGraph',
                          params: {
                            id: item.sw_connect_id,
                            olt: item.interface_dec,
                          },
                        }"
                        >{{ item.interface_dec }}</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <Skalaton />
            </div>
            <div class="pagination-container">
              <button
                class="btn btn-sm btn-success mx-3 mt-3"
                @click="previousPage"
                :disabled="currentPage === 1"
              >
                Previous
              </button>
              <span class="mx-2"
                >Page {{ currentPage }} of {{ totalPages }}</span
              >
              <button
                class="btn btn-sm btn-success mx-3 mt-3"
                @click="nextPage"
                :disabled="currentPage === totalPages"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import oltPortList from "../services/port-list.service";
import getData from "../services/get-data.service";
import rebotData from "../services/rebotData.service";
import Skalaton from "@/components/Skalaton.vue";
import showSwal from "/src/mixins/showSwal.js";
export default {
  name: "Users",
  components: {
    Skalaton,
  },
  data() {
    return {
      splitData: [],
      sortKey: "",
      sortOrder: "asc",
      data: [],
      filteredData: [],
      filter_port_olt: [],
      port_olt: [],
      selected_olt: "",
      selected_port: "",
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 20,
      filter: [],
      reset_load: false,
      data_load: false,
      load_index: "",
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    formatdata(data) {
      return (this.splitData = data.split(","));
    },

    async Reset(port, id, index) {
      this.reset_load = true;
      this.load_index = index;
      const response = await rebotData.rebotData(port, id);
      if (response.status == "200") {
        showSwal.methods.showSwal({
          type: "success",
          message: "Reboot Successfully",
        });
      }
      this.reset_load = false;
    },

    async getResetData(port, id, index) {
      this.data_load = true;
      this.load_index = index;

      try {
        const response = await getData.getData(port, id);

        Swal.fire({
          icon: "success",
          title: "dBm " + response.dBm,
          timer: 9000,
          timerProgressBar: true,
          showConfirmButton: false,
          background: "#17ad37",
          color: "#fff",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error fetching data",
          timer: 5000,
          timerProgressBar: true,
          showConfirmButton: false,
          confirmButtonText: "Close",
          background: "#17ad37",
          color: "#fff",
        });
      } finally {
        this.data_load = false;
      }
    },

    handleOltData() {
      if (this.selected_olt == "") {
        this.filteredData = this.data;
        this.port_olt = [];
      } else {
        this.filteredData = this.data.filter(
          (item) => item.sw_connect_name === this.selected_olt
        );
        this.filter = this.filteredData;
        this.currentPage = 1;

        this.port_olt = this.filter_port_olt.filter(
          (item) => item.sw_connect === this.selected_olt
        );
      }
    },
    handlePortData(port) {
      if (port === "") {
        this.handleOltData();
      } else {
        this.filteredData = this.data.filter(
          (item) =>
            item.port_name === port &&
            item.sw_connect_name === this.selected_olt
        );
      }
      this.currentPage = 1;
    },

    async getData() {
      const response = await oltPortList.getOltPort();
      this.data = response.sw_all_data;
      this.filter = response.sw_all_data;
      this.filter_port_olt = response.filter_port_olt;
      this.filteredData = response.sw_all_data;
    },
    sort(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortKey = key;
        this.sortOrder = "asc";
      }

      this.filteredData.sort((a, b) => {
        const valueA = a[key] ? a[key].toString().toLowerCase() : "";
        const valueB = b[key] ? b[key].toString().toLowerCase() : "";
        const comparison = valueA.localeCompare(valueB);

        // Apply sort order based on the current state
        return this.sortOrder === "asc" ? comparison : -comparison;
      });
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      return this.filteredData.slice(
        startIndex,
        startIndex + this.itemsPerPage
      );
    },
  },
  watch: {
    searchQuery(newValue) {
      if (newValue == "") {
        return (this.filteredData = this.data);
      }

      let old_data = [];
      if (this.selected_port || this.selected_olt) {
        old_data = this.filteredData;
      } else {
        old_data = this.data;
      }

      this.filteredData = old_data.filter((item) => {
        for (let key in item) {
          if (
            String(item[key]).toLowerCase().includes(newValue.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
      // Reset currentPage when searchQuery changes
      this.currentPage = 1;
    },
  },
};
</script>

<style>
td {
  padding: 12px 24px !important;
}
.sort-icon {
  margin-left: 5px;
}

.asc::after {
  content: "▲";
}

.desc::after {
  content: "▼";
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  background-color: #40c133; /* or any other color you prefer */
  color: #fff; /* text color */
}

/* Customize scrollbar */
.invoice-card::-webkit-scrollbar {
  width: 6px; /* Set the width for the scrollbar */
}

.invoice-card::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3); /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.invoice-card::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); /* Color of the scrollbar track */
}
</style>
