import axios from "axios";
const API_URL = process.env.VUE_APP_API_BASE_URL;
export function useRtlApi() {
  async function checkUiLogic(id) {
    try {
      const response = await axios.get(API_URL + `/rtl/api/olt/${id}/`);
      return response.data;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }
  async function getBdcomData(id,port) {
    try {
      const response = await axios.get(API_URL + `/rtl/data-get-bdcom-gpon/?id=${id}&int_port=${port}`);
      return response.data;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }
  async function getBdEponData(id,port) {
    try {
      const response = await axios.get(API_URL + `/rtl/data-get-avies-epon/?oltid=${id}&port_id=${port.split(":")[0]}&onu_id=${port.split(":")[0]}`);
      return response.data;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }
  async function getMultiplePPPOE(id) {
    let userPromise = new Promise((resolve, reject) => {
      let user= JSON.parse(localStorage.getItem("user_data"));
      if (user) {
        resolve(user);
      } else {
        reject("User not found in localStorage");
      }
    });
    try {
      const user = await userPromise;
      const response = await axios.get(API_URL + `/api/get-port-data-list/${id}/`, {
        headers: {
          'X-Custom-Header': user.id
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting data:", error);
      throw error;
    }
  }
 
  
  return {
    checkUiLogic,
    getBdcomData,
    getBdEponData,
    getMultiplePPPOE,
  };
}