<template>
  <div>
    <SwitchList />
  </div>
</template>

<script>
import SwitchList from "@/components/SwitchList.vue";
export default {
  name: "Switch List",
  components: {
          SwitchList
        },
}
</script>
