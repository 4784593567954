<template>
  <div class="mb-4 col-lg-12 mb-lg-3">
    <div class="card z-index-2">
      <div class="p-3 card-body invoice-card" style="min-height: 400px">
        <p class="font-weight-bold">DB List</p>

        <div class="mt-3">
          <div class="row justify-content-center">
            <div
              class="col-md-6 col-lg-3 px-6 mb-3 text-center cursor-pointer"
              v-for="(item, index) in dashoard_data?.db_list"
              :key="index"
            >
              <div @click="popup_open(item?.db_list, item?.dbname)">
                <div class="d-flex text-center m-3">
                  <div
                    class="text-center shadow icon icon-shape icon-xxs border-radius-sm me-2"
                    :class="`bg-gradient-${item?.color_code}`"
                  ></div>
                  <p class="text-uppercase">{{ item?.dbname }}</p>
                </div>
                <p class="mb-0 text-xs">
                  <span
                    class="d-flex mx-auto justify-content-center align-items-center"
                    :class="`bg-gradient-${item?.color_code}`"
                    style="
                      padding: 10px;
                      width: 50px;
                      height: 50px;
                      border-radius: 100%;
                      color: white !important;
                    "
                  >
                    <b class="text-lg">{{ item?.db_count ?? 0 }}</b></span
                  >
                </p>
                <div class="progress w-100 bg-dark mt-3">
                  <div
                    class="progress-bar"
                    style="background-color: #70d930"
                    :class="`w-${Math.min(
                      Math.ceil(item?.db_count / 5) * 5,
                      100
                    )}`"
                    role="progressbar"
                    :aria-valuenow="item?.db_count"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="popup" class="col-6 text-end">
            <div class="popup_bg"></div>
            <div id="myModal" class="modal_class">
              <div class="modal-content invoice">
                <div class="d-flex justify-content-between">
                  <span>Name: {{ db_name }}</span>
                  <span @click="popup_close" class="close">&times;</span>
                </div>

                <div
                  class="hover-popup my-3"
                  style="height: 500px; overflow-y: auto; scrollbar-width: thin"
                >
                  <div class="popup-content">
                    <table class="table">
                      <tbody>
                        <tr v-for="item in db_data" :key="item.PPPOE_ID">
                          <td class="text-left">
                            <strong>Olt:</strong>
                            {{ item.olt_connect__olt_name }}
                          </td>
                          <td class="text-left">
                            <strong>Port:</strong> {{ item.port_number }}
                          </td>
                          <td class="text-left">
                            <strong>PPPOE:</strong> {{ item.PPPOE_ID }}
                            <router-link
                              class="dark-filter text-info"
                              :to="{
                                name: 'Graph',
                                params: { pppoe: item.PPPOE_ID },
                              }"
                            >
                              <span
                                style="
                                  text-decoration: underline;
                                  font-size: 13px;
                                  color: #70d930 !important;
                                "
                                >View</span
                              >
                            </router-link>
                          </td>
                          <td class="text-left">
                            <strong>DB:</strong> {{ item.description }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";

// Props (assumed)
const props = defineProps({
  dashoard_data: {
    type: Object,
    required: true,
  },
});
const popup = ref(false);
const db_data = ref([]);
const db_name = ref("");

const popup_open = (data, name) => {
  popup.value = true;
  db_data.value = data;
  db_name.value = name;
};
const popup_close = () => {
  popup.value = false;
  this.db_data = [];
  db_name.value = "";
};

// Reference props in the template to prevent unused variable errors
const dashoard_data = props.dashoard_data;
</script>
