import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async updatesData(form_data,edit_id) {
      try {
        const response = await axios.put(API_URL + `/api-put-onu-olt-data-port/${edit_id}/`, form_data);
        return response.data;
      } catch (error) {
        console.error("Error retrieving user:", error);
        throw error; 
      }
    },
  };
