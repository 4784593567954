import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async getBrands() {
      try {
        const response = await axios.get(API_URL + `/api/sg-5/sw_brands/`);
        return response.data;
      } catch (err) {
        console.error("Error:", err);
        throw err.response.data.error; 
      }
    },
  };
