<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <!-- Payment Method -->
      <div class="col-6 text-end">
        <div v-if="showPaymentMethod" id="myModal" class="modal_class">
          <div class="modal-content invoice">
            <span @click="popup_close" class="close">&times;</span>

            <h5 class="text-center">Please Select Payment Method</h5>

            <div class="my-3 mx-12 text-center">
              <p v-if="pay_loading">Loading ..</p>
              <span
                v-if="pay_loading"
                class="spinner-border spinner-border-sm mx-3"
              ></span>
              <span v-else @click="getPay()" class="cursor-pointer"
                ><img src="../assets/img/logos/bkash.svg" style="width: 200px"
              /></span>
            </div>
            <div class="flex text-center"></div>
          </div>
        </div>
      </div>
      <!--Month List Popup -->
      <div v-if="showPopup" id="myModal" class="modal_class">
        <div class="modal-content dark-modeon">
          <span @click="popup_close" class="close text-end">&times;</span>

          <div class="my-3 mx-12 mx-auto">
            <default-info-card
              class="cursor-pointer"
              icon="text-white fas fa-shopping-cart"
              :title="pact_data.sw_packge_list[0].name"
              :port_count="pact_data.sw_packge_list[0].port_count"
              :sw_modes="pact_data.sw_packge_list[0].sw_modes_id"
              :value="
                'Tk ' +
                (firstIntegerValue
                  ? firstIntegerValue * pact_data.sw_packge_list[0].price
                  : pact_data.sw_packge_list[0].price)
              "
              :expeire_at="
                firstIntegerValue
                  ? formatDate(
                      new Date(
                        new Date().setMonth(
                          new Date().getMonth() + firstIntegerValue
                        )
                      )
                    )
                  : null
              "
            />
            <select
              v-model="selected_month"
              name=""
              id=""
              class="form-control mt-3 text-black font-bold text-center bg-white"
            >
              <option value="" disabled selected hidden>Select Month</option>
              <option
                v-for="(item, index) in month_data"
                :key="index"
                :value="index"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="flex text-center">
            <div class="grid grid-cols-2 gap-2">
              <button class="btn btn-secondary mx-3" @click="popup_close">
                Cancel
              </button>
              <button class="btn btn-success" @click="Pay(selected_month)">
                Pay Now
                <span
                  v-if="loading_pay_now"
                  class="spinner-border spinner-border-sm mx-3"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Add New Popup -->
      <div v-if="addPopup" id="myModal" class="modal_class">
        <div class="modal-content dark-modeon">
          <span @click="popup_close" class="close text-end">&times;</span>

          <div class="row mb-3 p-3">
            <div class="col-12 col-sm-6 mb-3">
              <label class="form-label">Sw IP</label>
              <div
                :class="{
                  'has-error': formData.sw_port && isValidPort == false,
                  'has-success': formData.sw_port && isValidPort == true,
                }"
              >
                <input
                  v-model="formData.sw_ip"
                  type="text"
                  placeholder="Sw IP"
                  class="form-control invoice-card"
                />
              </div>
            </div>
            <div class="col-12 col-sm-6 mb-3">
              <label class="form-label">SNMP Community</label>
              <input
                v-model="formData.sw_username"
                type="text"
                placeholder="SNMP Community"
                class="form-control invoice-card"
              />
            </div>
            <!-- <div class="col-12 col-sm-6 mb-3">
                                <label class="form-label">Sw Password</label>
                                <input
                                    v-model="formData.sw_password"
                                    type="text"
                                    placeholder="Sw Password"
                                    class="form-control invoice-card"
                                />
                                </div> -->
            <div class="col-12 col-sm-6 mb-3">
              <label class="form-label">SNMP Port</label>
              <div
                :class="{
                  'has-error': formData.sw_port && isValidPort == false,
                  'has-success': formData.sw_port && isValidPort == true,
                }"
              >
                <input
                  @change="PortCheck(formData.sw_ip, formData.sw_port)"
                  v-model="formData.sw_port"
                  type="text"
                  placeholder="SNMP Port"
                  class="form-control invoice-card"
                />
              </div>
            </div>
            <div class="col-12 col-sm-6 mb-3">
              <label class="form-label">Description</label>
              <input
                v-model="formData.description"
                type="text"
                placeholder="Description"
                class="form-control invoice-card"
              />
            </div>
            <div class="col-12 col-sm-6 mb-3">
              <label class="form-label">Switch Name</label>
              <input
                v-model="formData.sw_name"
                type="text"
                placeholder="Switch Name"
                class="form-control invoice-card"
              />
            </div>
            <!-- <div class="col-12 col-sm-6 mb-3">
                                <label class="form-label">Sw Billing</label>
                                <input
                                    v-model="formData.sw_billing"
                                    type="number"
                                    placeholder=""
                                    class="form-control invoice-card"
                                />
                                </div>
                                <div class="col-12 col-sm-6 mb-3">
                                <label class="form-label">Sw Brands</label>
                                <input
                                    v-model="formData.sw_brands"
                                    type="number"
                                    placeholder=""
                                    class="form-control invoice-card"
                                />
                                </div> -->

            <div class="col-12 col-sm-3 mb-3">
              <label class="form-label">Whatsapp Id</label>
              <input
                v-model="formData.wav_id"
                type="text"
                placeholder="Wav Id"
                class="form-control invoice-card"
              />
            </div>
            <div class="col-12 col-sm-3 mb-3">
              <label class="form-label">Telegram Id</label>
              <input
                v-model="formData.teleg_id"
                type="text"
                placeholder="Teleg Id"
                class="form-control invoice-card"
              />
            </div>
            <div class="col-12 col-sm-3 mb-3">
              <label class="form-label">Data Collection Ratio</label>
              <input
                v-model="formData.data_collcetratio"
                type="text"
                placeholder="Data Collcetratio"
                class="form-control invoice-card"
              />
            </div>
            <div class="col-12 col-sm-3 mb-3">
              <label class="form-label">Db Alert Range</label>
              <input
                v-model="formData.db_allert_range"
                type="text"
                placeholder="Db Alert Range"
                class="form-control invoice-card"
              />
            </div>
            <div class="col-12 col-sm-6 mb-3">
              <label class="form-label">Brands</label>
              <select
                v-model="formData.sw_brands"
                name=""
                id=""
                class="form-control invoice-card"
              >
                <option value="" selected disabled hidden>Select</option>
                <option
                  v-for="(data, index) in brands"
                  :key="index"
                  :value="data.id"
                >
                  {{ data.sw_brand_name }}
                </option>
              </select>
            </div>
            <div class="col-12 col-sm-6 mb-3">
              <label class="form-label">Billing</label>
              <select
                v-model="formData.sw_billing"
                class="form-control invoice-card required"
                name=""
                id=""
              >
                <option value="" selected disabled hidden>Select</option>
                <option
                  v-for="(item, index) in olt_data"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.olt_pack }}
                </option>
              </select>
            </div>
          </div>
          <div class="flex text-center">
            <div class="grid grid-cols-2 gap-2">
              <button class="btn btn-secondary mx-3" @click="popup_close">
                Cancel
              </button>
              <button
                v-if="Object.keys(single_data).length"
                class="btn btn-success"
                @click="submitForm"
              >
                Update
                <span
                  v-if="loading_pay_now"
                  class="spinner-border spinner-border-sm mx-3"
                ></span>
              </button>
              <button v-else class="btn btn-success" @click="submitForm">
                Submit
                <span
                  v-if="loading_pay_now"
                  class="spinner-border spinner-border-sm mx-3"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card invoice">
          <!-- Card header -->
          <div class="pb-0 card-header invoice">
            <div
              class="d-lg-flex justify-content-between align-items-center mx-3"
            >
              <div>
                <h5>
                  Switch List
                  <span class="badge">{{ filteredData.length }}</span>
                </h5>
              </div>
              <div class="d-flex gap-3">
                <div class="">
                  <button
                    @click="addPopup = true"
                    class="bg-success btn text-white rounded py-0"
                    :style="{
                      height: '35px',
                    }"
                  >
                    Add New
                  </button>
                </div>
                <div class="flex-grow-1">
                  <input
                    type="text"
                    v-model="searchQuery"
                    class="form-control invoice"
                    placeholder="Search..."
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="px-0 pb-0 card-body">
            <div class="table-responsive" v-if="data && data.length > 0">
              <div class="card mb-4">
                <div class="card-body px-0 pt-0 pb-2 invoice">
                  <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Action
                            <span class="sort-icon"> </span>
                          </th>
                          <th
                            @click="sort('show')"
                            :class="{
                              asc: sortKey === 'show' && sortOrder === 'asc',
                              desc: sortKey === 'show' && sortOrder === 'desc',
                            }"
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Expire Status
                            <span class="sort-icon"> </span>
                          </th>
                          <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Status
                            <span class="sort-icon"> </span>
                          </th>
                          <th
                            @click="sort('id')"
                            :class="{
                              asc: sortKey === 'id' && sortOrder === 'asc',
                              desc: sortKey === 'id' && sortOrder === 'desc',
                            }"
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            OLT ID
                            <span class="sort-icon"> </span>
                          </th>
                          <th
                            @click="sort('sw_ip')"
                            :class="{
                              asc: sortKey === 'sw_ip' && sortOrder === 'asc',
                              desc: sortKey === 'sw_ip' && sortOrder === 'desc',
                            }"
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Sw IP
                            <span class="sort-icon"> </span>
                          </th>
                          <th
                            @click="sort('sw_username')"
                            :class="{
                              asc:
                                sortKey === 'sw_username' &&
                                sortOrder === 'asc',
                              desc:
                                sortKey === 'sw_username' &&
                                sortOrder === 'desc',
                            }"
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Sw User Name
                            <span class="sort-icon"> </span>
                          </th>

                          <th
                            @click="sort('sw_port')"
                            :class="{
                              asc: sortKey === 'sw_port' && sortOrder === 'asc',
                              desc:
                                sortKey === 'sw_port' && sortOrder === 'desc',
                            }"
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Sw Port
                            <span class="sort-icon"> </span>
                          </th>
                          <th
                            @click="sort('description')"
                            :class="{
                              asc:
                                sortKey === 'description' &&
                                sortOrder === 'asc',
                              desc:
                                sortKey === 'description' &&
                                sortOrder === 'desc',
                            }"
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Description
                            <span class="sort-icon"> </span>
                          </th>
                          <th
                            @click="sort('sw_name')"
                            :class="{
                              asc: sortKey === 'sw_name' && sortOrder === 'asc',
                              desc:
                                sortKey === 'sw_name' && sortOrder === 'desc',
                            }"
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Sw Name
                            <span class="sort-icon"> </span>
                          </th>
                          <th
                            @click="sort('sw_brands')"
                            :class="{
                              asc:
                                sortKey === 'sw_brands' && sortOrder === 'asc',
                              desc:
                                sortKey === 'sw_brands' && sortOrder === 'desc',
                            }"
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Sw Brands
                            <span class="sort-icon"> </span>
                          </th>
                          <th class="text-secondary opacity-7"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in filteredData" :key="index">
                          <td
                            class="align-middle text-center"
                            @click="edit(data)"
                          >
                            <soft-badge
                              class="bg-danger p-1 rounded-1 text-white cursor-pointer text-sm mx-3"
                              variant="gradient"
                              size="sm"
                              >Edit
                            </soft-badge>
                          </td>
                          <td>
                            <soft-badge
                              v-if="data.show == false"
                              class="bg-danger p-1 rounded-1 text-white cursor-pointer text-sm"
                              variant="gradient"
                              size="sm"
                              >Renew Now
                            </soft-badge>

                            <soft-badge
                              v-if="data.show == true"
                              class="btn-success p-1 rounded-1 text-white cursor-pointer text-sm"
                              variant="gradient"
                              size="sm"
                              >Active
                            </soft-badge>
                          </td>
                          <td class="align-middle text-center">
                            <soft-badge
                              v-if="data.new_status == 'closed'"
                              class="bg-danger p-1 rounded-1 text-white cursor-pointer text-sm"
                              variant="gradient"
                              size="sm"
                              >Offline
                            </soft-badge>

                            <soft-badge
                              v-if="data.new_status == 'open'"
                              class="btn-success p-1 rounded-1 text-white cursor-pointer text-sm"
                              variant="gradient"
                              size="sm"
                              >Online
                            </soft-badge>
                          </td>
                          <td :class="{ 'text-danger': !data.show }">
                            <div class="d-flex px-2 py-1">
                              {{ data.oltid }}
                            </div>
                          </td>
                          <td
                            :class="{ 'text-danger': !data.show }"
                            class="align-middle text-center text-sm"
                          >
                            <p class="text-xs font-weight-bold mb-0">
                              {{ data.sw_ip }}
                            </p>
                          </td>
                          <td
                            :class="{ 'text-danger': !data.show }"
                            class="align-middle text-center text-sm"
                          >
                            <p class="text-xs font-weight-bold mb-0">
                              {{ data.sw_username }}
                            </p>
                          </td>
                          <td :class="{ 'text-danger': !data.show }">
                            <span class="text-xs font-weight-bold">{{
                              data.sw_port
                            }}</span>
                          </td>
                          <td :class="{ 'text-danger': !data.show }">
                            <span class="text-xs font-weight-bold">{{
                              data.description
                            }}</span>
                          </td>
                          <td :class="{ 'text-danger': !data.show }">
                            <span class="text-xs font-weight-bold">{{
                              data.sw_name
                            }}</span>
                          </td>
                          <td :class="{ 'text-danger': !data.show }">
                            <span class="text-xs font-weight-bold">{{
                              data.sw_brands
                            }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="pagination-container">
                    <button
                      class="btn btn-sm btn-success mx-3 mt-3"
                      @click="previousPage"
                      :disabled="currentPage === 1"
                    >
                      Previous
                    </button>
                    <span class="mx-2"
                      >Page {{ currentPage }} of {{ totalPages }}</span
                    >
                    <button
                      class="btn btn-sm btn-success mx-3 mt-3"
                      @click="nextPage"
                      :disabled="currentPage === totalPages"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <Skalaton />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
import dataList from "../services/get-sw.service";
import MonthList from "../services/get-month-list.service";
import FasterPayBill from "../services/faster-pay-bill.service";
import PayBillByPacID from "../services/get-pack-name-with-olt-id.service";
import showSwal from "../mixins/showSwal.js";
import Skalaton from "@/components/Skalaton.vue";
import addSwitch from "../services/add-switch.service";
import oltCatListData from "../services/olt-cat-list.service";
import getBrands from "../services/get-brands.service";
import NewOltDataFormListData from "../services/get-newolt-get-data-form.service";
import getPortCheck from "../services/check-ports.service";
import updateswitch from "../services/update-switch.service";

export default {
  name: "Olt List",
  components: {
    Skalaton,
    DefaultInfoCard,
  },
  data() {
    return {
      sortKey: "",
      sortOrder: "asc",
      data: [],
      pact_data: [],
      filteredData: [],
      month_data: [],
      selected_month: "",
      selected_id: null,
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 20,
      showPopup: false,
      addPopup: false,
      loading: false,
      bkashURL: "",
      pay_loading: false,
      loading_pay_now: false,
      isValidPort: null,
      showPaymentMethod: false,
      cat_data: [],
      olt_data: [],
      brands: [],
      single_data: {},

      formData: {
        sw_ip: "",
        sw_username: "",
        sw_password: "",
        sw_port: "",
        description: "",
        sw_name: "",
        login_user: "",
        sw_billing: "",
        sw_brands: "",
        wav_id: "",
        teleg_id: "",
        data_collcetratio: "",
        db_allert_range: "",
      },
    };
  },
  async mounted() {
    await this.getData();
    await this.getcatData();
    await this.getNewOltDataForm();
    await this.getMonthData();
    await this.brand_data();
    let user = JSON.parse(localStorage.getItem("user_data"));
    this.formData.login_user = user.id;
  },
  methods: {
    async edit(data) {
      this.single_data = data;
      this.addPopup = true;

      this.formData.sw_ip = data.sw_ip;
      this.formData.sw_username = data.sw_username;
      this.formData.sw_password = data.sw_password;
      this.formData.sw_port = data.sw_port;
      this.formData.description = data.description;
      this.formData.sw_name = data.sw_name;
      this.formData.login_user = data.login_user;
      this.formData.sw_billing = data.sw_billing;
      this.formData.sw_brands = data.sw_brands;
      this.formData.wav_id = data.wav_id;
      this.formData.teleg_id = data.teleg_id;
      this.formData.data_collcetratio = data.data_collcetratio;
      this.formData.db_allert_range = data.db_allert_range;
    },
    async getcatData() {
      const response = await oltCatListData.getCatListData();
      this.cat_data = response;
    },
    async brand_data() {
      const response = await getBrands.getBrands();
      this.brands = response;
    },
    async getNewOltDataForm() {
      const response = await NewOltDataFormListData.getNewOltDataFormListData();
      this.olt_data = response;
    },

    async submitForm() {
      this.loading = true;
      console.log("Form submitted with data:", this.formData);
      try {
        if (this.single_data?.id) {
          await updateswitch.updateswitch(this.formData, this.single_data?.id);
        } else {
          await addSwitch.addData(this.formData);
        }

        await this.getData();
        this.popup_close();

        showSwal.methods.showSwal({
          type: "success",
          message: "Submited Successfully!",
        });
      } catch (error) {
        this.loading = false;
        console.error("Error adding switch:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Failed to Create switch!",
        });
      }
    },

    async PortCheck(ip, port) {
      const response = await getPortCheck.getPortCheck(ip, port);
      if (response.status == "open") {
        this.isValidPort = true;
      } else if (response.status == "closed") {
        this.isValidPort = false;
      } else {
        this.isValidPort = null;
      }
    },

    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    async Pay(selected_month) {
      this.loading_pay_now = true;
      if (selected_month == "") {
        showSwal.methods.showSwal({
          type: "error",
          message: "Please Select a Month",
        });
      }
      const response = await FasterPayBill.PayBill(
        selected_month,
        this.selected_id
      );
      this.loading_pay_now = false;
      if (response.bkashURL) {
        this.bkashURL = response.bkashURL;
        this.showPaymentMethod = true;
        this.showPopup = false;
      } else {
        showSwal.methods.showSwal({
          type: "error",
          message: "Something is wrong",
        });
      }
    },

    async getPay() {
      this.pay_loading = true;
      if (this.bkashURL) {
        window.open(this.bkashURL);
      }
      this.showPaymentMethod = false;
      this.pay_loading = false;
    },

    popup_close() {
      this.addPopup = false;
      this.showPopup = false;
      this.showPaymentMethod = false;
      (this.selected_item = []),
        (this.selected_id = null),
        (this.selected_month = ""),
        (this.pact_data = []);

      (this.formData.sw_ip = ""),
        (this.formData.sw_username = ""),
        (this.formData.sw_password = ""),
        (this.formData.sw_port = ""),
        (this.formData.description = ""),
        (this.formData.sw_name = ""),
        (this.formData.login_user = ""),
        (this.formData.sw_billing = ""),
        (this.formData.sw_brands = "");
      (this.formData.wav_id = ""),
        (this.formData.teleg_id = ""),
        (this.formData.data_collcetratio = ""),
        (this.formData.db_allert_range = "");
    },

    async open_popup(id) {
      this.pact_data = await PayBillByPacID.PayBillByPacID(id);
      this.showPopup = true;
      this.selected_id = id;
    },
    async getMonthData() {
      const response = await MonthList.getMonthListData();
      this.month_data = response;
    },
    async getData() {
      const response = await dataList.getDataList();
      this.data = response;

      for (let item of this.data) {
        const sw_ip = item.sw_ip || "";
        const sw_port = item.sw_port || "";
        let response = "closed";
        if (sw_ip && sw_port) {
          response = await getPortCheck.getPortCheck(sw_ip, sw_port);
        }

        item.new_status = response.status || response;
      }

      this.filteredData = this.data;
    },
    sort(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortKey = key;
        this.sortOrder = "asc";
      }

      this.filteredData.sort((a, b) => {
        const valueA = a[key] ? a[key].toString().toLowerCase() : "";
        const valueB = b[key] ? b[key].toString().toLowerCase() : "";
        const comparison = valueA.localeCompare(valueB);
        return this.sortOrder === "asc" ? comparison : -comparison;
      });
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      return this.filteredData.slice(
        startIndex,
        startIndex + this.itemsPerPage
      );
    },
    firstIntegerValue() {
      const match = this.selected_month.match(/\d+/);
      return match ? parseInt(match[0]) : null;
    },
  },
  watch: {
    searchQuery(newValue) {
      this.filteredData = this.data.filter((item) => {
        for (let key in item) {
          if (
            String(item[key]).toLowerCase().includes(newValue.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
      // Reset currentPage when searchQuery changes
      this.currentPage = 1;
    },
  },
};
</script>

<style>
/* The Modal (background) */
.modal_class {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #aaaaaa;
}

/* Modal Content/Box */
.modal-content {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 50%;
  padding: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

td {
  padding: 12px 24px !important;
}

.sort-icon {
  margin-left: 5px;
}

.asc::after {
  content: "▲";
}

.desc::after {
  content: "▼";
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  background-color: #40c133;
  /* or any other color you prefer */
  color: #fff;
  /* text color */
}

.has-error .form-control {
  border: 1px solid red;
}
.has-success .form-control {
  border: 1px solid green;
}
.check-icon {
  color: green !important;
  position: absolute;
  right: 20px;
  top: 37px;
}
.cross-icon {
  color: red !important;
  position: absolute;
  right: 20px;
  top: 37px;
}
</style>
